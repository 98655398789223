/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as CopyLinkButtonIcon,
} from "@epam/assets/icons/common/content-link-18.svg";
import {
	ReactComponent as CopiedNotificationIcon,
} from "@epam/assets/icons/common/notification-check-fill-18.svg";
import {
	Button,
	FlexRow,
	IconContainer,
	Text,
} from "@epam/loveship";
import classNames from "classnames";
import {
	type FC,
	useState,
} from "react";

import {
	type OvertimeRequestId,
} from "models/overtime/types";
import {
	generateOvertimeRequestDetailsPageUrl,
} from "pages/utils/generate-overtime-request-details-page-url";
import {
	type WithClassName,
} from "types/common";

import styles from "./copy-link-button.module.css";

// In milliseconds.
const COPIED_NOTIFICATION_DURATION = 1_000;

interface CopyLinkButtonProps extends WithClassName {
	overtimeRequestId: OvertimeRequestId;
}

const CopyLinkButton: FC<CopyLinkButtonProps> = ({
	overtimeRequestId,
	className,
}) => {
	const [
		shouldDisplayCopiedNotification,
		setShouldDisplayCopiedNotification,
	] = useState<boolean>(false);

	const copyOvertimeRequestDetailsPageLinkToClipboard = async (): Promise<void> => {
		try {
			const {
				origin,
			} = window.location;

			const overtimeRequestDetailsPageRoute = generateOvertimeRequestDetailsPageUrl({
				overtimeRequestId: String(overtimeRequestId),
			});

			const overtimeRequestDetailsPageUrl = `${origin}${overtimeRequestDetailsPageRoute}`;

			await navigator.clipboard.writeText(overtimeRequestDetailsPageUrl);

			setShouldDisplayCopiedNotification(true);

			setTimeout(
				() => {
					setShouldDisplayCopiedNotification(false);
				},
				COPIED_NOTIFICATION_DURATION,
			);
		// User can forbid writing to the clipboard.
		} catch (error) {
			const typedError = error as Error;

			console.error(typedError.message);
		}
	};

	return (
		<FlexRow
			spacing={null}
			cx={
				classNames(
					styles.row,
					className,
				)
			}
		>
			{
				shouldDisplayCopiedNotification
					? (
						<FlexRow
							spacing={null}
							columnGap="6"
							cx={styles.copiedNotification}
						>
							<IconContainer
								icon={CopiedNotificationIcon}
								cx={styles.copiedIcon}
							/>
							<Text
								color="night800"
								fontSize="14"
								lineHeight="18"
								size="18"
								fontWeight="600"
							>
								Copied
							</Text>
						</FlexRow>
					)
					: (
						<Button
							icon={CopyLinkButtonIcon}
							caption="Copy link"
							color="sky"
							fill="light"
							onClick={copyOvertimeRequestDetailsPageLinkToClipboard}
						/>
					)
			}
		</FlexRow>
	);
};

export {
	CopyLinkButton,
};

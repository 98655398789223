/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	toFancyDate,
} from "models/dates-and-time/utils/to-fancy-date";
import {
	toFullWeekDayName,
} from "models/dates-and-time/utils/to-full-week-day-name";

const getHeaderDateTooltipContent = (date: Date): string => {
	const fullDate = toFancyDate(date);
	const weekDay = toFullWeekDayName(date);

	return `${fullDate} (${weekDay})`;
};

export {
	getHeaderDateTooltipContent,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import {
	format,
	isSunday,
} from "date-fns";
import {
	type DetailedHTMLProps,
	type FC,
	type HTMLAttributes,
} from "react";

import {
	DAY_WITH_LEADING_ZERO,
} from "models/dates-and-time/constants";
import {
	toFullWeekDayName,
} from "models/dates-and-time/utils/to-full-week-day-name";

import tableComponentsStyles from "../../../table-components.module.css";
import cellStyles from "../../cell.module.css";
import headerCellStyles from "../header-cell.module.css";
import styles from "./date-cell.module.css";

const WEEKDAY_NAME_LETTERS = 3;

interface DateHeaderCellProps extends DetailedHTMLProps<
	HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
> {
	date: Date;
	isLastCell: boolean;
}

const DateHeaderCell: FC<DateHeaderCellProps> = ({
	date,
	isLastCell,
	className,
	...otherProps
}) => {
	const weekdayName = toFullWeekDayName(date);
	const weekdayNameLetters = weekdayName.slice(
		0,
		WEEKDAY_NAME_LETTERS,
	);

	const dayString = format(date, DAY_WITH_LEADING_ZERO);

	return (
		<div
			className={
				classNames(
					cellStyles.cell,
					headerCellStyles.headerCell,
					styles.dateHeaderCell,
					{
						[cellStyles.withTransparentRightBorder]: isLastCell,
						[cellStyles.lastDayOfWeek]: isSunday(date),
					},
					className,
				)
			}
			{...otherProps}
		>
			<span
				className={
					classNames(
						tableComponentsStyles.text,
						styles.text,
						styles.weekdayName,
					)
				}
			>
				{weekdayNameLetters}
			</span>
			<span
				className={
					classNames(
						tableComponentsStyles.text,
						styles.text,
						styles.day,
					)
				}
			>
				{dayString}
			</span>
		</div>
	);
};

export {
	DateHeaderCell,
};

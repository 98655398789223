/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type FC,
} from "react";

import {
	ReactComponent as TimeJournalIcon,
} from "icons/external-link-time-journal-24.svg";
import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type EmployeeId,
} from "models/employees/types";
import {
	generateTimeJournalPageUrl,
} from "pages/utils/generate-time-journal-page-url";

import {
	ExternalLinkHint,
} from "../external-link-hint/external-link-hint";
import {
	ExternalLink,
} from "../external-link/external-link";

interface ExternalLinkTimeJournalProps extends Partial<WithPeriodDates> {
	employeeId: EmployeeId;
}

const ExternalLinkTimeJournal: FC<ExternalLinkTimeJournalProps> = ({
	employeeId,
	periodStartDate,
	periodEndDate,
}) => {
	return (
		<ExternalLinkHint content="Time Journal">
			<ExternalLink
				icon={TimeJournalIcon}
				href={
					generateTimeJournalPageUrl({
						employeeId,
						periodStartDate,
						periodEndDate,
					})
				}
				target="_blank"
			/>
		</ExternalLinkHint>
	);
};

export {
	ExternalLinkTimeJournal,
};

/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ControlGroup,
} from "@epam/loveship";
import classNames from "classnames";
import {
	type ComponentProps,
	type FC,
} from "react";

import styles from "./action-buttons-group.module.css";

type ControlGroupProps = ComponentProps<typeof ControlGroup>;

const ActionButtonsGroup: FC<ControlGroupProps> = ({
	cx,
	...otherProps
}) => {
	return (
		<ControlGroup
			cx={
				classNames(
					styles.actionButtonsGroup,
					cx,
				)
			}
			{...otherProps}
		/>
	);
};

export {
	ActionButtonsGroup,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import {
	type DetailedHTMLProps,
	type HTMLAttributes,
	forwardRef,
} from "react";

import tableComponentsStyles from "../../../table-components.module.css";
import styles from "./worklogs-total-duration.module.css";

type WorklogsTotalDurationProps = DetailedHTMLProps<
	HTMLAttributes<HTMLSpanElement>,
	HTMLSpanElement
>;

const WorklogsTotalDuration = forwardRef<HTMLSpanElement, WorklogsTotalDurationProps>((
	{
		children,
		className,
		...otherProps
	},
	ref,
) => {
	return (
		<span
			ref={ref}
			className={
				classNames(
					tableComponentsStyles.text,
					styles.worklogsTotalDuration,
					className,
				)
			}
			{...otherProps}
		>
			{children}
		</span>
	);
});

WorklogsTotalDuration.displayName = "WorklogsTotalDuration";

export {
	WorklogsTotalDuration,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as RejectedIcon,
} from "@epam/assets/icons/common/navigation-close_bold-18.svg";
import {
	ReactComponent as ApprovedIcon,
} from "@epam/assets/icons/common/notification-done_bold-18.svg";
import {
	ReactComponent as DefaultIcon,
} from "@epam/assets/icons/common/radio-point-10.svg";
import {
	Badge,
} from "@epam/loveship";
import {
	type FC,
} from "react";

import {
	PresenceStatus,
} from "models/presences/constants";
import {
	PresenceStatusIcon,
	type PresenceStatusIconMapType,
	type PresenceStatusIconParams,
} from "pages/components/presence-status-icon/presence-status-icon";
import {
	getFormattedPresenceStatus,
} from "pages/employees-dashboard/utils/get-formatted-presence-status";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import styles from "./presence-status-badge.module.css";

const PRESENCE_STATUS_ICON_DEFAULT: PresenceStatusIconParams = {
	icon: DefaultIcon,
	className: styles.default,
	"data-name": toDataAttribute("Presence status icon default"),
};

const PRESENCE_STATUS_ICON_MAP: PresenceStatusIconMapType = {
	[PresenceStatus.APPROVED]: {
		icon: ApprovedIcon,
		className: styles.approved,
		"data-name": toDataAttribute("Presence status icon approved"),
	},
	[PresenceStatus.REJECTED]: {
		icon: RejectedIcon,
		className: styles.rejected,
		"data-name": toDataAttribute("Presence status icon rejected"),
	},
	[PresenceStatus.SUBMITTED]: PRESENCE_STATUS_ICON_DEFAULT,
	[PresenceStatus.PREFILLED]: PRESENCE_STATUS_ICON_DEFAULT,
	DEFAULT: PRESENCE_STATUS_ICON_DEFAULT,
};

interface PresenceStatusBadgeProps {
	status: PresenceStatus;
}

const PresenceStatusBadge: FC<PresenceStatusBadgeProps> = ({
	status,
}) => {
	return (
		<Badge
			caption={getFormattedPresenceStatus(status)}
			color="white"
			shape="round"
			fill="outline"
			size="24"
			icon={() => {
				return (
					<PresenceStatusIcon
						presenceStatus={status}
						iconMap={PRESENCE_STATUS_ICON_MAP}
					/>
				);
			}}
		/>
	);
};

export {
	PresenceStatusBadge,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type FC,
} from "react";

import {
	type OvertimeRequest,
} from "models/overtime/types";
import {
	OvertimeRequestEmployeeExternalLinks,
} from "pages/components/overtime-request-employee-external-links/overtime-request-employee-external-links";
import {
	generateEmailFromFullName,
} from "utils/email";

import {
	DataCellValueText,
} from "../../data-cell-value-text/data-cell-value-text";
import {
	DataRow,
} from "../../data-row/data-row";

interface ApproverDataRowProps {
	overtimeRequest: OvertimeRequest;
}

const ApproverDataRow: FC<ApproverDataRowProps> = ({
	overtimeRequest,
}) => {
	const {
		approverUid,
		approverName,
	} = overtimeRequest;

	return (
		<DataRow
			title="Approver"
			dataNamePrefix="Approver"
		>
			<OvertimeRequestEmployeeExternalLinks
				employee={{
					id: approverUid,
					name: approverName,
					email: generateEmailFromFullName(approverName),
				}}
				placement="bottom-start"
			>
				<DataCellValueText>
					{approverName}
				</DataCellValueText>
			</OvertimeRequestEmployeeExternalLinks>
		</DataRow>
	);
};

export {
	ApproverDataRow,
};

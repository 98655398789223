/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import values from "lodash/values";

enum PagePeriodPresetLocalStorageKey {
	EMPLOYEES_DASHBOARD = "employees-dashboard-page-period-preset",
	OVERTIME_DASHBOARD = "overtime-dashboard-page-period-preset",
	PROJECT_DETAILS = "project-details-page-period-preset",
	PROJECTS_DASHBOARD = "projects-dashboard-page-period-preset",
	TIME_JOURNAL = "time-journal-page-period-preset",
}

enum Preset {
	PREVIOUS_MONTH = "PREVIOUS_MONTH",
	PREVIOUS_WEEK = "PREVIOUS_WEEK",
	PREVIOUS_WEEK_AND_CURRENT_WEEK = "PREVIOUS_WEEK_AND_CURRENT_WEEK",
	CURRENT_WEEK = "CURRENT_WEEK",
	CURRENT_WEEK_AND_NEXT_WEEK = "CURRENT_WEEK_AND_NEXT_WEEK",
	CURRENT_MONTH = "CURRENT_MONTH",
	NEXT_WEEK = "NEXT_WEEK",
	NEXT_MONTH = "NEXT_MONTH",
}

const ALL_PRESET_VALUES = values(Preset);

const DEFAULT_PAGE_PERIOD_NAVIGATOR_PRESET = Preset.CURRENT_MONTH;

export {
	PagePeriodPresetLocalStorageKey,
	Preset,
	ALL_PRESET_VALUES,
	DEFAULT_PAGE_PERIOD_NAVIGATOR_PRESET,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type PayloadAction,
	createSlice,
} from "@reduxjs/toolkit";

import {
	type Duration,
} from "models/dates-and-time/types";
import {
	type OvertimeType,
} from "models/overtime/types";

interface OvertimeRequestDetailsState {
	isUserOvertimeRequestOwner: boolean;
	overtimeDuration: Duration | null;
	overtimeType: OvertimeType | undefined;
}

const initialState: OvertimeRequestDetailsState = {
	isUserOvertimeRequestOwner: false,
	overtimeDuration: null,
	overtimeType: undefined,
};

const overtimeRequestDetailsSlice = createSlice({
	name: "overtimeRequestDetails",
	initialState,
	reducers: {
		setIsUserOvertimeRequestOwner: (
			state,
			action: PayloadAction<boolean>,
		) => {
			// eslint-disable-next-line no-param-reassign
			state.isUserOvertimeRequestOwner = action.payload;
		},
		setOvertimeDuration: (
			state,
			action: PayloadAction<Duration | null>,
		) => {
			// eslint-disable-next-line no-param-reassign
			state.overtimeDuration = action.payload;
		},
		setOvertimeType: (
			state,
			action: PayloadAction<OvertimeType>,
		) => {
			// eslint-disable-next-line no-param-reassign
			state.overtimeType = action.payload;
		},
		resetState: () => {
			return initialState;
		},
	},
});

const overtimeRequestDetailsReducer = overtimeRequestDetailsSlice.reducer;

const {
	setIsUserOvertimeRequestOwner,
	setOvertimeDuration,
	setOvertimeType,
	resetState,
} = overtimeRequestDetailsSlice.actions;

export {
	overtimeRequestDetailsReducer,
	setIsUserOvertimeRequestOwner,
	setOvertimeDuration,
	setOvertimeType,
	resetState as resetOvertimeRequestDetailsState,
};

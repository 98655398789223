/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type FC,
} from "react";

import {
	ReactComponent as MailIcon,
} from "icons/external-link-email-24.svg";
import {
	type EmployeeEmail,
} from "models/employees/types";
import {
	createEmailAndOpenNativeEmailClient,
} from "utils/email";

import {
	ExternalLinkHint,
} from "../external-link-hint/external-link-hint";
import {
	ExternalLink,
} from "../external-link/external-link";

interface ExternalLinkEmailProps {
	employeeEmail: EmployeeEmail;
}

const ExternalLinkEmail: FC<ExternalLinkEmailProps> = ({
	employeeEmail,
}) => {
	return (
		<ExternalLinkHint content="Create email">
			<ExternalLink
				color="night600"
				icon={MailIcon}
				onClick={() => {
					createEmailAndOpenNativeEmailClient({
						to: employeeEmail,
					});
				}}
			/>
		</ExternalLinkHint>
	);
};

export {
	ExternalLinkEmail,
};

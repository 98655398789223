/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Button,
	Checkbox,
	ErrorAlert,
	FlexRow,
	FlexSpacer,
	ModalBlocker,
	ModalFooter,
	ModalHeader,
	ModalWindow,
	Panel,
	Text,
} from "@epam/loveship";
import {
	type IModal,
} from "@epam/uui-core";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import pickBy from "lodash/pickBy";
import {
	type FC,
	useState,
} from "react";

import {
	API_PREFIX,
} from "constants/api";
import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type EmployeeIds,
	type EngagementPackageIds,
	type FilterValuesForServer,
	type LocationIds,
} from "models/employees/types";
import {
	type EmployeeTipTypes,
} from "models/tips/types";
import {
	PeriodPicker,
	type PeriodPickerProps,
} from "pages/components/period-picker/period-picker";
import {
	getPeriodPickerDefaultPlaceholder,
} from "pages/utils/get-period-picker-default-placeholder";
import {
	getArrayForQuery,
} from "utils/api";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import {
	DEFAULT_REPORTING_TYPES_STATE,
	REPORTING_TYPES_NAMES,
	ReportingType,
} from "./constants";
import {
	type ReportingTypeValue,
	type ReportingTypesState,
} from "./types";
import {
	getReportPeriodErrors,
} from "./utils/get-report-period-errors";

import styles from "./excel-raw-modal-window.module.css";

type PeriodToReport = PeriodPickerProps["value"];

interface ExcelRawModalWindowProps extends
	IModal<undefined>,
	WithPeriodDates {
	employeeIds?: EmployeeIds;
	employeeRMIds?: EmployeeIds;
	locationIds?: LocationIds;
	engagementPackageIds?: EngagementPackageIds;
	search?: string;
	filterValues?: FilterValuesForServer;
	selectedCallToActionTipTypes?: EmployeeTipTypes;
}

const ExcelRawModalWindow: FC<ExcelRawModalWindowProps> = ({
	periodStartDate,
	periodEndDate,
	employeeIds,
	employeeRMIds,
	locationIds,
	engagementPackageIds,
	search,
	filterValues,
	selectedCallToActionTipTypes,
	...modalProps
}) => {
	const [
		periodToReport,
		setPeriodToReport,
	] = useState<PeriodToReport>({
		from: periodStartDate,
		to: periodEndDate,
	});

	const [
		reportingTypesState,
		setReportingTypesState,
	] = useState<ReportingTypesState>(DEFAULT_REPORTING_TYPES_STATE);

	const changeReportingTypes = (reportingType: ReportingType) => {
		return (value: ReportingTypeValue) => {
			setReportingTypesState((currentReportingTypes) => {
				return {
					...currentReportingTypes,
					[reportingType]: value,
				};
			});
		};
	};

	const reportPeriodErrors = getReportPeriodErrors(periodToReport);

	const hasReportPeriodError = !isEmpty(reportPeriodErrors);

	const reportingTypeValues = Object.values(reportingTypesState);

	const hasSelectedReportingTypes = reportingTypeValues.some((reportingTypeValue) => {
		return reportingTypeValue;
	});

	const hasReportingTypeError = !hasSelectedReportingTypes;

	const isDownloadButtonDisabled = hasReportPeriodError || hasReportingTypeError;

	// TODO: remove the disable comment when the code for the old Employees Dashboard page is removed.
	// eslint-disable-next-line complexity
	const sendReportingRawData = (): void => {
		const {
			from,
			to,
		} = periodToReport;

		if (isNull(from) || isNull(to)) {
			return;
		}

		// eslint-disable-next-line lodash/identity-shorthand
		const filteredReportingTypeState = pickBy(reportingTypesState, (reportingTypeValue) => {
			return reportingTypeValue;
		});

		const selectedReportingTypes = Object.keys(filteredReportingTypeState);

		let url: URL;

		if (
			!isUndefined(filterValues)
			&& !isUndefined(selectedCallToActionTipTypes)
		) {
			url = new URL(
				`${API_PREFIX}employees/reports/raw`,
				window.location.origin,
			);

			url.searchParams.append("startDate", from);

			url.searchParams.append("endDate", to);

			url.searchParams.append("reportingTypes", getArrayForQuery(selectedReportingTypes));

			const {
				employeeIds: employeeIdsFromFilter,
				locationIds: locationIdsFromFilter,
				engagementPackageIds: engagementPackageIdsFromFilter,
				presenceAvailability,
				resourceManagerIds,
				shouldIncludeOnlyDirectSubordinates,
			} = filterValues;

			if (!isEmpty(employeeIdsFromFilter)) {
				url.searchParams.append("employeeIds", getArrayForQuery(employeeIdsFromFilter));
			}

			if (!isEmpty(locationIdsFromFilter)) {
				url.searchParams.append("locationIds", getArrayForQuery(locationIdsFromFilter));
			}

			if (!isEmpty(engagementPackageIdsFromFilter)) {
				url.searchParams.append("engagementPackageIds", getArrayForQuery(engagementPackageIdsFromFilter));
			}

			if (!isEmpty(presenceAvailability)) {
				url.searchParams.append("presenceAvailability", getArrayForQuery(presenceAvailability));
			}

			if (!isEmpty(selectedCallToActionTipTypes)) {
				url.searchParams.append("callToActionTipTypes", getArrayForQuery(selectedCallToActionTipTypes));
			}

			if (!isEmpty(resourceManagerIds)) {
				url.searchParams.append("resourceManagerIds", getArrayForQuery(resourceManagerIds));
			}

			url.searchParams.append("onlyDirectSubordinates", String(shouldIncludeOnlyDirectSubordinates));
		} else {
			url = new URL(
				`${API_PREFIX}employees/accessible/excel-report`,
				window.location.origin,
			);

			url.searchParams.append("startDate", from);

			url.searchParams.append("endDate", to);

			url.searchParams.append("reportingTypes", getArrayForQuery(selectedReportingTypes));

			if (
				!isUndefined(employeeIds)
				&& !isEmpty(employeeIds)
			) {
				url.searchParams.append("employeeIds", getArrayForQuery(employeeIds));
			}

			if (
				!isUndefined(employeeRMIds)
				&& !isEmpty(employeeRMIds)
			) {
				url.searchParams.append("employeeRMIds", getArrayForQuery(employeeRMIds));
			}

			if (
				!isUndefined(locationIds)
				&& !isEmpty(locationIds)
			) {
				url.searchParams.append("locationIds", getArrayForQuery(locationIds));
			}

			if (
				!isUndefined(engagementPackageIds)
				&& !isEmpty(engagementPackageIds)
			) {
				url.searchParams.append("engagementPackageIds", getArrayForQuery(engagementPackageIds));
			}

			if (
				!isUndefined(search)
				&& !isEmpty(search)
			) {
				url.searchParams.append("search", search);
			}
		}

		// TODO: need to create an utility for this snippet
		const link = document.createElement("a");

		const urlString = url.toString();

		link.href = urlString;

		link.setAttribute("target", "_blank");

		document.body.appendChild(link);

		link.click();

		link.remove();

		modalProps.success(undefined);
	};

	return (
		<ModalBlocker
			{...modalProps}
		>
			<ModalWindow width="600">
				<ModalHeader
					rawProps={{
						"data-name": toDataAttribute("Modal window header"),
					}}
					onClose={() => {
						modalProps.abort();
					}}
				>
					<Text
						fontSize="18"
						lineHeight="24"
						color="night800"
						cx={styles.noPadding}
						fontWeight="600"
					>
						Download raw data
					</Text>
				</ModalHeader>

				<Panel
					cx={styles.modalWindowBody}
				>
					<FlexRow
						spacing={null}
						cx={
							classNames(
								styles.infoTextRow,
								styles.noMinHeight,
							)
						}
					>
						<Text
							color="night800"
							fontSize="14"
							lineHeight="24"
							cx={styles.noPadding}
						>
							Select period and parameters
						</Text>
					</FlexRow>

					<div className={styles.periodPicker}>
						<PeriodPicker
							value={periodToReport}
							onValueChange={setPeriodToReport}
							getPlaceholder={getPeriodPickerDefaultPlaceholder}
							errors={reportPeriodErrors}
						/>
					</div>

					<FlexRow
						spacing={null}
						cx={
							classNames(
								styles.checkboxGroups,
								styles.noMinHeight,
							)
						}
					>
						<div className={styles.checkboxGroup}>
							<Text
								color="night800"
								fontSize="14"
								lineHeight="24"
								cx={
									classNames(
										styles.checkboxGroupHeader,
										styles.noPadding,
									)
								}
							>
								Reporting types
							</Text>
							<Checkbox
								value={reportingTypesState[ReportingType.PROJECT]}
								label={REPORTING_TYPES_NAMES[ReportingType.PROJECT]}
								onValueChange={changeReportingTypes(ReportingType.PROJECT)}
							/>
							<Checkbox
								value={reportingTypesState[ReportingType.NON_PROJECT_HOURS]}
								label={REPORTING_TYPES_NAMES[ReportingType.NON_PROJECT_HOURS]}
								onValueChange={changeReportingTypes(ReportingType.NON_PROJECT_HOURS)}
							/>
							<Checkbox
								value={reportingTypesState[ReportingType.LEAVE]}
								label={REPORTING_TYPES_NAMES[ReportingType.LEAVE]}
								onValueChange={changeReportingTypes(ReportingType.LEAVE)}
							/>
							<Checkbox
								value={reportingTypesState[ReportingType.PRESENCE]}
								label={REPORTING_TYPES_NAMES[ReportingType.PRESENCE]}
								onValueChange={changeReportingTypes(ReportingType.PRESENCE)}
							/>
							<Checkbox
								value={reportingTypesState[ReportingType.CALENDAR_NORM]}
								label={REPORTING_TYPES_NAMES[ReportingType.CALENDAR_NORM]}
								onValueChange={changeReportingTypes(ReportingType.CALENDAR_NORM)}
							/>
						</div>
					</FlexRow>

					{
						hasReportingTypeError
							? (
								<ErrorAlert
									cx={styles.errorNotification}
									icon={undefined}
								>
									<Text
										fontSize="14"
										lineHeight="24"
										size="24"
										color="night800"
									>
										Select at least one reporting type
									</Text>
								</ErrorAlert>
							)
							: null
					}
				</Panel>
				<ModalFooter
					columnGap="12"
					borderTop={true}
					rawProps={{
						"data-name": toDataAttribute("Modal window footer"),
					}}
				>
					<FlexSpacer/>
					<Button
						color="gray"
						fill="white"
						caption="Cancel"
						onClick={() => {
							modalProps.abort();
						}}
					/>
					<Button
						color="grass"
						caption="Download"
						isDisabled={isDownloadButtonDisabled}
						onClick={sendReportingRawData}
					/>
				</ModalFooter>
			</ModalWindow>
		</ModalBlocker>
	);
};

export {
	ExcelRawModalWindow,
};

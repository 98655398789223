/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import orderBy from "lodash/orderBy";

import {
	type PresenceActivities,
} from "models/presences/types";

const sortPresenceActivities = (
	presenceActivities: PresenceActivities,
): PresenceActivities => {
	return orderBy(
		presenceActivities,
		[
			({
				startTime,
			}) => {
				return startTime;
			},
			({
				endTime,
			}) => {
				return endTime;
			},
		],
		[
			"asc",
			"desc",
		],
	);
};

export {
	sortPresenceActivities,
};

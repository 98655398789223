/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Text,
} from "@epam/loveship";
import {
	type ComponentProps,
	type FC,
} from "react";

const DataCellValueText: FC<ComponentProps<typeof Text>> = ({
	children,
	...otherProps
}) => {
	return (
		<Text
			color="night800"
			size="18"
			fontSize="14"
			lineHeight="18"
			{...otherProps}
		>
			{children}
		</Text>
	);
};

export {
	DataCellValueText,
};

/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	FlexRow,
} from "@epam/loveship";
import classNames from "classnames";
import {
	type ComponentProps,
	type FC,
} from "react";

import styles from "./container.module.css";

type ContainerProps = ComponentProps<typeof FlexRow>;

const Container: FC<ContainerProps> = ({
	children,
	cx,
	...otherProps
}) => {
	return (
		<FlexRow
			alignItems="center"
			cx={
				classNames(
					styles.container,
					cx,
				)
			}
			{...otherProps}
		>
			{children}
		</FlexRow>
	);
};

export {
	Container as ExternalLinksTooltipContainer,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Tooltip,
} from "@epam/loveship";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import {
	type ComponentProps,
	type FC,
	type ReactNode,
} from "react";

import {
	type WithChildren,
} from "types/common";

import styles from "./tooltip-with-header.module.css";

type TooltipProps = ComponentProps<typeof Tooltip>;

interface TooltipWithHeaderProps extends
	WithChildren,
	Pick<
		TooltipProps,
		| "placement"
		| "color"
		| "cx"
	> {
	header?: ReactNode;
	text?: ReactNode;
}

const TooltipWithHeader: FC<TooltipWithHeaderProps> = ({
	header,
	text,
	placement,
	color = "white",
	cx,
	children,
}) => {
	const isEmptyHeader = (
		isNil(header)
		|| isEmpty(header)
	);

	const isEmptyText = (
		isNil(text)
		|| isEmpty(text)
	);

	return (
		<Tooltip
			color={color}
			placement={placement}
			cx={cx}
			content={
				(
					!isEmptyHeader
					|| !isEmptyText
				)
					? (
						<div>
							{
								!isEmptyHeader
									? (
										<div className={styles.header}>
											{header}
										</div>
									)
									: null
							}
							{
								!isEmptyText
									? (
										<div className={styles.text}>
											{text}
										</div>
									)
									: null
							}
						</div>
					)
					: null
			}
		>
			{children}
		</Tooltip>
	);
};

export {
	TooltipWithHeader,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Badge,
} from "@epam/loveship";
import classNames from "classnames";
import {
	type ComponentProps,
	type FC,
} from "react";

import {
	OvertimeStatus,
} from "models/overtime/constants";
import {
	getOvertimeStatusName,
} from "models/overtime/utils/get-overtime-status-name/get-overtime-status-name";

import styles from "./overtime-request-status-badge.module.css";

interface OvertimeRequestStatusBadgeProps extends ComponentProps<typeof Badge> {
	status: OvertimeStatus;
}

const OvertimeRequestStatusBadge: FC<OvertimeRequestStatusBadgeProps> = ({
	status,
	...otherProps
}) => {
	return (
		<Badge
			caption={getOvertimeStatusName(status)}
			color="white"
			fill="outline"
			shape="round"
			size="24"
			icon={() => {
				return (
					<div
						className={
							classNames(
								styles.icon,
								{
									[styles.approved]: status === OvertimeStatus.APPROVED,
									[styles.submitted]: status === OvertimeStatus.SUBMITTED,
									[styles.rejected]: status === OvertimeStatus.REJECTED,
									[styles.cancelled]: status === OvertimeStatus.CANCELLED,
								},
							)
						}
					/>
				);
			}}
			{...otherProps}
		/>
	);
};

export {
	OvertimeRequestStatusBadge,
};

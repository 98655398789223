/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	LinkButton,
} from "@epam/loveship";
import {
	type ComponentProps,
	type FC,
	forwardRef,
} from "react";

type ExternalLinkProps = ComponentProps<typeof LinkButton>;

// TODO: migrate to `IconButton` when it supports `isDropdown` property.
const ExternalLink: FC<ExternalLinkProps> = forwardRef(
	(props, ref) => {
		return (
			<LinkButton
				size="24"
				ref={ref}
				{...props}
			/>
		);
	},
);

ExternalLink.displayName = "EmployeeExternalLink";

export {
	ExternalLink,
};

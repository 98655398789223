/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	OvertimeRequestAttachmentStatus,
} from "models/overtime/constants";

const getStatusTooltipText = (
	status: OvertimeRequestAttachmentStatus,
): string | undefined => {
	if (status === OvertimeRequestAttachmentStatus.LOCAL) {
		return "Sorry, but this file has not been scanned yet. You can’t download it.";
	}

	if (status === OvertimeRequestAttachmentStatus.BLOCKED) {
		return "Sorry, but this file has infected status. You can’t download it.";
	}

	return undefined;
};

export {
	getStatusTooltipText,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import {
	type DetailedHTMLProps,
	type FC,
	type HTMLAttributes,
} from "react";

import headerCellStyles from "../header-cell.module.css";
import styles from "./info-cell.module.css";

const InfoHeaderCell: FC<
	DetailedHTMLProps<
		HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	>
> = ({
	children,
	className,
	...otherProps
}) => {
	return (
		<div
			className={
				classNames(
					headerCellStyles.headerCell,
					styles.infoHeaderCell,
					className,
				)
			}
			{...otherProps}
		>
			{children}
		</div>
	);
};

export {
	InfoHeaderCell,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type PresenceStatus,
} from "models/presences/constants";
import {
	type Presence,
} from "models/presences/types";

interface NewPresence extends Pick<Presence, "details"> {
	// `undefined` status is used for for new presences.
	status: PresenceStatus | undefined;
}

const PRESENCE_DEFAULT_VALUE: NewPresence = {
	status: undefined,
	details: [],
};

enum ValidationErrorLevel {
	START_TIME = "START_TIME",
	END_TIME = "END_TIME",
}

export {
	PRESENCE_DEFAULT_VALUE,
	ValidationErrorLevel,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type ComponentProps,
	type FC,
} from "react";

import {
	Column,
} from "../column/column";

type ColumnLinksProps = ComponentProps<typeof Column>;

const ColumnLinks: FC<ColumnLinksProps> = ({
	children,
	...otherProps
}) => {
	return (
		<Column
			{...otherProps}
		>
			{children}
		</Column>
	);
};

export {
	ColumnLinks as ExternalLinksTooltipColumnLinks,
};

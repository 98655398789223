/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isUndefined from "lodash/isUndefined";

import {
	type Duration,
} from "models/dates-and-time/types";

import {
	getOvertimeDurationValidationErrorMessage,
} from "../get-overtime-duration-validation-error-message/get-overtime-duration-validation-error-message";

const getIsOvertimeDurationValid = (
	overtimeDuration: Duration | null,
): boolean => {
	const overtimeDurationValidationErrorMessage = getOvertimeDurationValidationErrorMessage(
		overtimeDuration,
	);

	return isUndefined(overtimeDurationValidationErrorMessage);
};

export {
	getIsOvertimeDurationValid,
};

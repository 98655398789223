/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Text,
} from "@epam/loveship";
import classNames from "classnames";
import {
	type ComponentProps,
	type FC,
} from "react";

import styles from "./secondary-info.module.css";

const SecondaryInfo: FC<ComponentProps<typeof Text>> = ({
	children,
	cx,
	...otherProps
}) => {
	return (
		<Text
			color="night600"
			fontSize="12"
			cx={
				classNames(
					styles.text,
					cx,
				)
			}
			{...otherProps}
		>
			{children}
		</Text>
	);
};

export {
	SecondaryInfo,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type FC,
} from "react";

import {
	type WithChildren,
} from "types/common";

import styles from "./data-row-with-notification-container.module.css";

const DataRowWithNotificationContainer: FC<WithChildren> = ({
	children,
}) => {
	return (
		<div
			className={styles.dataRowWithNotificationContainer}
		>
			{children}
		</div>
	);
};

export {
	DataRowWithNotificationContainer,
};

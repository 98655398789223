/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type DateString,
} from "models/dates-and-time/types";

import {
	type Preset,
} from "../constants";
import {
	type PresetPeriodShift,
} from "../types";
import {
	getPresetsForPeriodPicker,
} from "./get-presets-for-period-picker";

interface GetPeriodDatesByPresetParams {
	preset: Preset;
	presetPeriodShift?: PresetPeriodShift;
}

interface GetDatesByPresetResult {
	periodStartDate: DateString;
	periodEndDate: DateString;
}

const getPeriodDatesByPreset = ({
	preset,
	presetPeriodShift,
}: GetPeriodDatesByPresetParams): GetDatesByPresetResult => {
	const presetsForPeriodPicker = getPresetsForPeriodPicker({
		presetPeriodShift,
	});
	const {
		getRange,
	} = presetsForPeriodPicker[preset];

	const {
		from: periodStartDate,
		to: periodEndDate,
	} = getRange();

	return {
		periodStartDate,
		periodEndDate,
	};
};

export {
	getPeriodDatesByPreset,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	FlexRow,
	Text,
} from "@epam/loveship";
import {
	type FC,
} from "react";

import {
	type WithChildren,
	type WithClassName,
} from "types/common";

import styles from "./activities-list-header.module.css";

interface ActivitiesListHeaderTitleProps extends
	WithChildren,
	WithClassName {}

const ActivitiesListHeaderTitle: FC<ActivitiesListHeaderTitleProps> = ({
	children,
	className,
}) => {
	return (
		<Text
			color="night600"
			fontSize="14"
			lineHeight="24"
			size="24"
			cx={className}
		>
			{children}
		</Text>
	);
};

const ActivitiesListHeader: FC = () => {
	return (
		<FlexRow
			spacing={null}
			cx={styles.header}
		>
			<ActivitiesListHeaderTitle
				className={styles.activityName}
			>
				Activity name
			</ActivitiesListHeaderTitle>
			<ActivitiesListHeaderTitle
				className={styles.startTime}
			>
				Start time
			</ActivitiesListHeaderTitle>
			<ActivitiesListHeaderTitle
				className={styles.endTime}
			>
				End time
			</ActivitiesListHeaderTitle>
		</FlexRow>
	);
};

export {
	ActivitiesListHeader,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isUndefined from "lodash/isUndefined";

import {
	SUPPORTED_FILES_INFO,
} from "pages/components/overtime-request-details/constants";

const getHasUnsupportedFiles = (files: File[]): boolean => {
	return files.some((file) => {
		const {
			name,
			type,
		} = file;

		const fileInfoByMimeType = SUPPORTED_FILES_INFO.find((supportedFileInfo) => {
			return supportedFileInfo.mimeType === type;
		});

		if (!isUndefined(fileInfoByMimeType)) {
			return false;
		}

		const fileExtension = name.split(".").pop();

		const fileInfoByExtension = SUPPORTED_FILES_INFO.find((supportedFileInfo) => {
			return supportedFileInfo.extension === fileExtension;
		});

		return isUndefined(fileInfoByExtension);
	});
};

export {
	getHasUnsupportedFiles,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import {
	type ComponentProps,
	type FC,
} from "react";

import {
	Column,
} from "../column/column";

import styles from "./column-info.module.css";

type ColumnInfoProps = ComponentProps<typeof Column>;

const ColumnInfo: FC<ColumnInfoProps> = ({
	cx,
	children,
	...otherProps
}) => {
	return (
		<Column
			cx={
				classNames(
					styles.column,
					cx,
				)
			}
			{...otherProps}
		>
			{children}
		</Column>
	);
};

export {
	ColumnInfo as ExternalLinksTooltipColumnInfo,
};

/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import {
	type DetailedHTMLProps,
	type FC,
	type HTMLAttributes,
} from "react";

import {
	ReactComponent as NoResultsIcon,
} from "images/table-no-results-placeholder.svg";
import {
	type WithChildren,
} from "types/common";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import styles from "../content-placeholder-components.module.css";

const DATA_NAME_ATTRIBUTE = toDataAttribute("No results placeholder");

interface NoResultsPlaceholderProps extends
	WithChildren,
	DetailedHTMLProps<
		HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	> {}

const NoResultsPlaceholder: FC<NoResultsPlaceholderProps> = ({
	children,
	className,
	...otherProps
}) => {
	return (
		<div
			className={
				classNames(
					styles.placeholderContainer,
					className,
				)
			}
			data-name={DATA_NAME_ATTRIBUTE}
			{...otherProps}
		>
			<NoResultsIcon className={styles.icon}/>
			<p className={styles.description}>
				{children}
			</p>
		</div>
	);
};

export {
	NoResultsPlaceholder,
};

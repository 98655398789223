/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import includes from "lodash/includes";
import isNull from "lodash/isNull";

import {
	ALL_PRESET_VALUES,
	DEFAULT_PAGE_PERIOD_NAVIGATOR_PRESET,
	type PagePeriodPresetLocalStorageKey,
	type Preset,
} from "../constants";

const getPagePeriodNavigatorPreset = (
	localStorageKey: PagePeriodPresetLocalStorageKey,
): Preset => {
	try {
		const presetFromLocalStorage = localStorage.getItem(localStorageKey);

		if (!isNull(presetFromLocalStorage)) {
			const typedPresetFromLocalStorage = presetFromLocalStorage as Preset;

			if (includes(ALL_PRESET_VALUES, typedPresetFromLocalStorage)) {
				return typedPresetFromLocalStorage;
			}
		}
	} catch {}

	return DEFAULT_PAGE_PERIOD_NAVIGATOR_PRESET;
};

export {
	getPagePeriodNavigatorPreset,
};

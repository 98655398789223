/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	PickerInput,
} from "@epam/loveship";
import {
	useArrayDataSource,
} from "@epam/uui-core";
import classNames from "classnames";
import {
	type FC,
	useState,
} from "react";

import {
	type WithClassName,
} from "types/common";
import {
	type PageSize,
	type PageSizes,
} from "types/pagination";

import styles from "./page-size-picker.module.css";

interface PageSizePickerProps extends WithClassName {
	values: PageSizes;
	defaultValue: PageSize;
	onChange: (pageSize: PageSize) => void;
}

const PageSizePicker: FC<PageSizePickerProps> = ({
	values,
	defaultValue,
	onChange,
	className,
}) => {
	const [
		value,
		setValue,
	] = useState(defaultValue);

	const dataSource = useArrayDataSource(
		{
			items: values,
			getId: (pageSize) => {
				return pageSize;
			},
		},
		[],
	);

	const handlePageSizeChange = (pageSize: PageSize): void => {
		setValue(pageSize);

		onChange(pageSize);
	};

	return (
		<div
			className={
				classNames(
					styles.container,
					className,
				)
			}
		>
			<PickerInput
				dataSource={dataSource}
				value={value}
				onValueChange={handlePageSizeChange}
				selectionMode="single"
				valueType="entity"
				searchPosition="none"
				minBodyWidth={72}
				getName={(pageSize) => {
					return String(pageSize);
				}}
				size="30"
				disableClear={true}
			/>
		</div>
	);
};

export {
	PageSizePicker,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	createSlice,
} from "@reduxjs/toolkit";

interface SpinnerState {
	isSpinnerVisible: boolean;
}

const initialState: SpinnerState = {
	isSpinnerVisible: true,
};

const spinnerSlice = createSlice({
	name: "spinner",
	initialState,
	reducers: {
		hideSpinner(state) {
			// eslint-disable-next-line no-param-reassign
			state.isSpinnerVisible = false;
		},
		showSpinner(state) {
			// eslint-disable-next-line no-param-reassign
			state.isSpinnerVisible = true;
		},
	},
});

const spinnerReducer = spinnerSlice.reducer;

const {
	hideSpinner,
	showSpinner,
} = spinnerSlice.actions;

export {
	spinnerReducer,
	hideSpinner,
	showSpinner,
};

/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Dropdown,
	DropdownMenuBody,
} from "@epam/loveship";
import {
	type DropdownBodyProps,
} from "@epam/uui-core";
import classNames from "classnames";
import {
	type ComponentProps,
	type FC,
	type MouseEventHandler,
	type ReactNode,
} from "react";

import {
	DropdownOpenTrigger,
} from "./constants";

import styles from "./menu.module.css";

type DropdownProps = ComponentProps<typeof Dropdown>;

type AllowedDropdownProps = Pick<
	DropdownProps,
	| "placement"
	| "value"
	| "onValueChange"
	| "openDelay"
	| "closeDelay"
>;

interface MenuProps extends
	AllowedDropdownProps,
	Omit<ComponentProps<typeof DropdownMenuBody>, "children"> {
	renderOptions: (props: DropdownBodyProps) => ReactNode;
	children: DropdownProps["renderTarget"];
	openTrigger?: DropdownOpenTrigger;
}

type HoverTriggerProps = Pick<
	DropdownProps,
	"openOnHover" | "closeOnMouseLeave"
>;

const HOVER_TRIGGER_PROPS: HoverTriggerProps = {
	openOnHover: true,
	closeOnMouseLeave: "boundary",
};

const Menu: FC<MenuProps> = ({
	value,
	onValueChange,
	renderOptions,
	children: renderTarget,
	openTrigger = DropdownOpenTrigger.CLICK,
	placement = "right",
	openDelay,
	closeDelay,
	...menuProps
}) => {
	const triggerProps: HoverTriggerProps | undefined = (
		openTrigger === DropdownOpenTrigger.HOVER
			? HOVER_TRIGGER_PROPS
			: undefined
	);

	const onDropdownMenuClick: MouseEventHandler<HTMLDivElement> = (event) => {
		event.stopPropagation();
	};

	return (
		<Dropdown
			{...triggerProps}
			openDelay={openDelay}
			closeDelay={closeDelay}
			value={value}
			onValueChange={onValueChange}
			placement={placement}
			renderTarget={renderTarget}
			renderBody={(props) => {
				const {
					cx: className,
					...otherMenuProps
				} = menuProps;

				return (
					/*
						For some reason, event of clicking inside `DropdownContainer` is
						propagated to the outer scope (table, in our case) and expands/collapses
						the rows, which is incorrect. The component doesn't provide `onClick`
						hander, so we need to wrap it in a `div` element to listen to the event
						and stop its propagation.
					*/
					<div onClick={onDropdownMenuClick}>
						<DropdownMenuBody
							/*
								Focus lock is disabled because the first option is selected
								automatically when opening the menu, and this first option has
								incorrect hover styles when hovering other options.
							*/
							focusLock={false}
							cx={
								classNames(
									styles.menu,
									className,
								)
							}
							{...otherMenuProps}
							{...props}
						>
							{renderOptions(props)}
						</DropdownMenuBody>
					</div>
				);
			}}
		/>
	);
};

export {
	Menu,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Fragment,
} from "react";

import {
	type ProblemsNotificationInfo,
} from "models/problems-notification/types";

const ONLY_SPACE_CHARACTERS_OR_EMPTY_STRING_REGEXP = /^\s*$/;

const formatMessage = (
	message: ProblemsNotificationInfo["message"],
): JSX.Element[] => {
	const messageParts = message.split("\n");
	const nonEmptyMessageParts = messageParts.filter((messagePart) => {
		return !ONLY_SPACE_CHARACTERS_OR_EMPTY_STRING_REGEXP.test(messagePart);
	});

	const lastIndex = nonEmptyMessageParts.length - 1;

	return nonEmptyMessageParts.map((messagePart, index) => {
		return (
			<Fragment key={messagePart}>
				{messagePart.trim()}
				{
					index < lastIndex
						? <br/>
						: null
				}
			</Fragment>
		);
	});
};

export {
	formatMessage,
};

/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	FlexRow,
	Panel,
	Text,
	Tooltip,
} from "@epam/loveship";
import classNames from "classnames";
import isUndefined from "lodash/isUndefined";
import map from "lodash/map";
import toString from "lodash/toString";
import {
	type FC,
} from "react";

import {
	type ComparisonType,
} from "models/comparisons/constants";
import {
	type ComparisonSetting,
	type ComparisonSettingReportedType,
} from "models/comparisons/types";
import {
	type WithChildren,
	type WithClassName,
} from "types/common";

import {
	Breadcrumb,
} from "../breadcrumbs/breadcrumb";
import {
	type BreadcrumbsLevelNames,
} from "../breadcrumbs/types";

import styles from "./comparison-info-hint.module.css";

interface ColumnTitleProps extends
	WithChildren,
	WithClassName {}

const ColumnTitle: FC<ColumnTitleProps> = ({
	children,
	className,
}) => {
	return (
		<Text
			color="night800"
			cx={
				classNames(
					styles.title,
					className,
				)
			}
			fontWeight="600"
		>
			{children}
		</Text>
	);
};

interface ComparisonTypesProps {
	comparisonTypes: BreadcrumbsLevelNames[];
}

const ComparisonTypes: FC<ComparisonTypesProps> = ({
	comparisonTypes,
}) => {
	return (
		<ul className={styles.comparisonTypesContainer}>
			{
				map(
					comparisonTypes,
					(levelNames) => {
						return (
							<li
								key={toString(levelNames)}
								className={styles.comparisonType}
							>
								<Breadcrumb levelNames={levelNames}/>
							</li>
						);
					},
				)
			}
		</ul>
	);
};

interface ComparisonInfoHintProps extends WithChildren {
	comparisonSetting: ComparisonSetting | undefined;
	getReportedHoursLevelNames: (reportedType: ComparisonSettingReportedType) => BreadcrumbsLevelNames;
	getComparedHoursLevelNames: (comparisonType: ComparisonType) => BreadcrumbsLevelNames;
}

const ComparisonInfoHint: FC<ComparisonInfoHintProps> = ({
	comparisonSetting,
	getComparedHoursLevelNames,
	getReportedHoursLevelNames,
	children,
}) => {
	if (isUndefined(comparisonSetting)) {
		return (
			<>
				{children}
			</>
		);
	}

	const reportedHoursLevelNames = map(
		comparisonSetting.reportedTypes,
		getReportedHoursLevelNames,
	);

	const comparedHoursLevelNames = map(
		[
			comparisonSetting.comparisonType,
		],
		getComparedHoursLevelNames,
	);

	return (
		<Tooltip
			color="white"
			placement="top-end"
			cx={styles.tooltip}
			content={(
				<FlexRow
					alignItems="top"
					spacing="18"
				>
					<Panel>
						<ColumnTitle>
							Reported types to compare
						</ColumnTitle>

						<ComparisonTypes comparisonTypes={reportedHoursLevelNames}/>
					</Panel>

					<Panel>
						<ColumnTitle className={styles.titleVersus}>
							VS.
						</ColumnTitle>
					</Panel>

					<Panel>
						<ColumnTitle>
							Expected norm
						</ColumnTitle>

						<ComparisonTypes comparisonTypes={comparedHoursLevelNames}/>
					</Panel>
				</FlexRow>
			)}
		>
			{children}
		</Tooltip>
	);
};

export {
	ComparisonInfoHint,
};

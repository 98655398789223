/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ErrorAlert,
	Text,
} from "@epam/loveship";
import {
	HttpStatusCode,
} from "axios";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import {
	type FC,
	useState,
} from "react";
import {
	useSelector,
} from "react-redux";

import {
	displayErrorNotification,
} from "components/common-components/notification/Notifications";
import {
	BYTES_PER_KIB,
	KIB_PER_MIB,
} from "constants/common";
import {
	overtimeApi,
} from "models/overtime/api";
import {
	OvertimeStatus,
} from "models/overtime/constants";
import {
	type OvertimeRequest,
	type OvertimeRequestAttachmentId,
} from "models/overtime/types";
import {
	type GAEventLocation,
} from "pages/components/overtime-request-details/types";
import {
	hideSpinner,
	showSpinner,
} from "pages/components/spinner/store/reducer";
import {
	type RootState,
	useDispatch,
} from "store";
import {
	useUserId,
} from "store/slices/application-info/selectors";
import {
	type ApiError,
} from "types/api";
import {
	downloadFile,
} from "utilities/download-file";

import {
	Attachment,
} from "../../attachment/attachment";
import {
	AttachmentsUploader,
} from "../../attachments-uploader/attachments-uploader";
import {
	DataRow,
} from "../../data-row/data-row";
import {
	getHasUnsupportedFiles,
} from "./utils/get-has-unsupported-files/get-has-unsupported-files";

import styles from "./attachments-data-row.module.css";

const MAX_ATTACHMENTS_COUNT = 5;

const MAX_ATTACHMENT_FILE_SIZE_MIB = 5;

const MAX_ATTACHMENT_FILE_SIZE = (
	MAX_ATTACHMENT_FILE_SIZE_MIB * KIB_PER_MIB * BYTES_PER_KIB
);

interface AttachmentsDataRowProps {
	overtimeRequest: OvertimeRequest;
	updateOvertimeRequest: () => void;
	eventLocation: GAEventLocation;
}

const AttachmentsDataRow: FC<AttachmentsDataRowProps> = ({
	overtimeRequest,
	updateOvertimeRequest,
	eventLocation,
}) => {
	const {
		overtimeId,
		status,
		attachments,
		employeeUid,
		canEdit,
	} = overtimeRequest;

	const [
		attachmentErrorMessage,
		setAttachmentErrorMessage,
	] = useState<string | undefined>();

	const isNewPermissionSchemeEnabled = useSelector((state: RootState) => {
		return state.applicationInfo.cpeBoard.overtime;
	});

	const userId = useUserId();

	const dispatch = useDispatch();

	const uploadAttachments = async (files: File[]): Promise<void> => {
		try {
			dispatch(showSpinner());

			const uploadOvertimeAttachmentRequest = (
				isNewPermissionSchemeEnabled
					? overtimeApi.endpoints.uploadOvertimeAttachmentV3.initiate
					: overtimeApi.endpoints.uploadOvertimeAttachment.initiate
			);

			await Promise.all(
				files.map(async (file) => {
					return await dispatch(
						uploadOvertimeAttachmentRequest({
							overtimeRequestId: overtimeId,
							attachmentFile: file,
						}),
					)
						.unwrap();
				}),
			);

			window.dataLayer.push({
				event_name: eventLocation,
				event_action: "attach_added",
				event: "autoevent",
			});

			updateOvertimeRequest();

			dispatch(hideSpinner());
		} catch (error) {
			const apiError = error as ApiError;

			let message = "Something went wrong. Try again later.";

			const responseFromServer = apiError.data.response;

			if (responseFromServer?.status === HttpStatusCode.Forbidden) {
				if (
					status === OvertimeStatus.APPROVED
					&& employeeUid === userId
				) {
					message = "You don't have permission to upload attachment for Approved OVT request";
				} else {
					message = "You are not authorized to perform this action";
				}
			}

			dispatch(hideSpinner());

			displayErrorNotification(message);
		}
	};

	const removeAttachment = async (
		attachmentId: OvertimeRequestAttachmentId,
	): Promise<void> => {
		try {
			dispatch(showSpinner());

			const removeOvertimeAttachmentRequest = (
				isNewPermissionSchemeEnabled
					? overtimeApi.endpoints.removeOvertimeAttachmentV3.initiate
					: overtimeApi.endpoints.removeOvertimeAttachment.initiate
			);

			await dispatch(
				removeOvertimeAttachmentRequest({
					overtimeRequestId: overtimeId,
					attachmentId,
				}),
			)
				.unwrap();

			window.dataLayer.push({
				event_name: eventLocation,
				event_action: "attach_deleted",
				event: "autoevent",
			});

			updateOvertimeRequest();

			dispatch(hideSpinner());
		} catch (error) {
			const typedError = error as ApiError;

			let message = "Something went wrong. Try again later.";

			const responseFromServer = typedError.data.response;

			if (responseFromServer?.status === HttpStatusCode.Forbidden) {
				message = "Security restriction prevent to perform the request";
			}

			dispatch(hideSpinner());

			displayErrorNotification(message);
		}
	};

	const downloadAttachment = async (
		attachmentId: OvertimeRequestAttachmentId,
	): Promise<void> => {
		try {
			const getOvertimeAttachmentFileRequest = (
				isNewPermissionSchemeEnabled
					? overtimeApi.endpoints.getOvertimeAttachmentFileV3.initiate
					: overtimeApi.endpoints.getOvertimeAttachmentFile.initiate
			);

			const getOvertimeAttachmentFileRequestPromise = dispatch(
				getOvertimeAttachmentFileRequest({
					overtimeRequestId: overtimeId,
					attachmentId,
				}),
			);

			const {
				attachmentFile,
				responseStatus,
				responseHeaders,
			} = await getOvertimeAttachmentFileRequestPromise.unwrap();

			getOvertimeAttachmentFileRequestPromise.unsubscribe();

			window.dataLayer.push({
				event_name: eventLocation,
				event_action: "attach_loaded",
				event: "autoevent",
			});

			if (responseStatus === HttpStatusCode.Ok) {
				downloadFile({
					file: attachmentFile,
					headers: responseHeaders,
				});
			}
		} catch (error) {
			const typedError = error as ApiError;

			let message = "Something went wrong. Try again later.";

			const responseFromServer = typedError.data.response;

			if (responseFromServer?.status === HttpStatusCode.Forbidden) {
				message = "Security restriction prevent to perform the request";
			}

			displayErrorNotification(message);
		}
	};

	const processAddedAttachments = async (files: File[]): Promise<void> => {
		const hasUnsupportedFiles = getHasUnsupportedFiles(files);

		if (hasUnsupportedFiles) {
			setAttachmentErrorMessage(
				"Only files with following extensions can be uploaded"
				+ " "
				+ "\"jpeg, jpg, jpe, png, bmp, tiff, tif, doc, docx, xls, xlsx, txt, msg, pdf, eml\"",
			);

			return;
		}

		const areAllFilesMeetSizeRequirement = files.every((file) => {
			return file.size < MAX_ATTACHMENT_FILE_SIZE;
		});

		if (!areAllFilesMeetSizeRequirement) {
			setAttachmentErrorMessage(
				`Max size of file you can upload is ${MAX_ATTACHMENT_FILE_SIZE_MIB}MB.`,
			);

			return;
		}

		if (files.length + attachments.length > MAX_ATTACHMENTS_COUNT) {
			setAttachmentErrorMessage(
				`Up to ${MAX_ATTACHMENTS_COUNT} files can be uploaded.`,
			);

			return;
		}

		await uploadAttachments(files);
	};

	return (
		<>
			<DataRow
				title="Attachments"
				dataNamePrefix="Attachments"
			/>

			<div
				className={styles.attachmentsManagementSection}
			>
				{
					!isEmpty(attachments)
						? (
							<div
								className={styles.attachmentsSection}
							>
								{
									attachments.map((attachment) => {
										return (
											<Attachment
												key={attachment.attachmentUid}
												attachment={attachment}
												canRemoveAttachment={canEdit}
												removeAttachment={removeAttachment}
												downloadAttachment={downloadAttachment}
											/>
										);
									})
								}
							</div>
						)
						: null
				}

				{
					(
						canEdit
						&& (
							status === OvertimeStatus.SUBMITTED
							|| status === OvertimeStatus.APPROVED
						)
						&& attachments.length < MAX_ATTACHMENTS_COUNT
					)
						? (
							<>
								{
									!isUndefined(attachmentErrorMessage)
										? (
											<ErrorAlert
												onClose={() => {
													setAttachmentErrorMessage(undefined);
												}}
											>
												<Text
													color="night800"
													fontSize="14"
													lineHeight="18"
													size="30"
												>
													{attachmentErrorMessage}
												</Text>
											</ErrorAlert>
										)
										: null
								}

								<AttachmentsUploader
									maxAttachmentsCount={MAX_ATTACHMENTS_COUNT}
									processAddedAttachments={processAddedAttachments}
								/>
							</>
						)
						: null
				}
			</div>
		</>
	);
};

export {
	AttachmentsDataRow,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Dropdown,
} from "@epam/loveship";
import {
	type DropdownPlacement,
} from "@epam/uui-core";
import classNames from "classnames";
import {
	type FC,
	type MouseEventHandler,
} from "react";

import {
	type WithChildren,
} from "types/common";

import styles from "./tooltip.module.css";

interface TooltipProps extends WithChildren {
	content: JSX.Element;
	placement?: DropdownPlacement;
	targetClassName?: string;
}

const Tooltip: FC<TooltipProps> = ({
	content,
	placement = "top-start",
	targetClassName,
	children,
}) => {
	const onDropdownBodyClick: MouseEventHandler<HTMLDivElement> = (event) => {
		event.stopPropagation();
	};

	return (
		<Dropdown
			openOnHover={true}
			closeOnTargetClick={true}
			closeOnMouseLeave="boundary"
			placement={placement}
			openDelay={1000}
			closeDelay={100}
			renderTarget={(props) => {
				const {
					ref,
				} = props;

				return (
					<div
						ref={ref}
						className={
							classNames(
								styles.target,
								targetClassName,
							)
						}
					>
						{children}
					</div>
				);
			}}
			renderBody={() => {
				return (
					<div onClick={onDropdownBodyClick}>
						{content}
					</div>
				);
			}}
		/>
	);
};

export {
	Tooltip as ExternalLinksTooltip,
};

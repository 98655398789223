/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type FC,
} from "react";

import {
	ReactComponent as TelescopeIcon,
} from "icons/external-link-telescope-24.svg";
import {
	type EmployeeId,
} from "models/employees/types";

import {
	ExternalLinkHint,
} from "../external-link-hint/external-link-hint";
import {
	ExternalLink,
} from "../external-link/external-link";

interface ExternalLinkTelescopeEmployeeProps {
	employeeId: EmployeeId;
}

const ExternalLinkTelescopeEmployee: FC<ExternalLinkTelescopeEmployeeProps> = ({
	employeeId,
}) => {
	return (
		<ExternalLinkHint content="Telescope">
			<ExternalLink
				icon={TelescopeIcon}
				href={`https://telescope.epam.com/who/key/upsaId/${employeeId}`}
				target="_blank"
			/>
		</ExternalLinkHint>
	);
};

export {
	ExternalLinkTelescopeEmployee,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	minutesInHour,
} from "date-fns/constants";
import isNull from "lodash/isNull";

import {
	type Duration,
} from "models/dates-and-time/types";

import {
	type NullableTimePickerValue,
} from "../../types";

const getTimePickerDuration = (
	timePickerValue: NullableTimePickerValue,
): Duration => {
	if (isNull(timePickerValue)) {
		return 0;
	}

	return (
		(timePickerValue.hours * minutesInHour)
		+ timePickerValue.minutes
	);
};

export {
	getTimePickerDuration,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type Duration,
} from "models/dates-and-time/types";
import {
	PresenceActivityType,
	PresenceType,
} from "models/presences/constants";

import {
	type EditablePresenceActivities,
} from "../../types";
import {
	getActivitiesDuration,
} from "../get-activities-duration/get-activities-duration";

interface GetTotalPresenceDurationParams {
	activities: EditablePresenceActivities;
	presenceType: PresenceType;
}

const getWorkingActivitiesTotalDuration = ({
	activities,
	presenceType,
}: GetTotalPresenceDurationParams): Duration => {
	let totalPresenceDuration: Duration = 0;

	const workActivities = activities.filter(({
		activityType,
	}) => {
		return activityType === PresenceActivityType.ACTIVITY;
	});
	const workActivitiesDuration = getActivitiesDuration(workActivities);

	if (presenceType === PresenceType.MULTI) {
		totalPresenceDuration = workActivitiesDuration;
	} else {
		const restActivities = activities.filter(({
			activityType,
		}) => {
			return activityType === PresenceActivityType.REST;
		});
		const restActivitiesDuration = getActivitiesDuration(restActivities);

		totalPresenceDuration = workActivitiesDuration - restActivitiesDuration;
	}

	if (totalPresenceDuration < 0) {
		return 0;
	}

	return totalPresenceDuration;
};

export {
	getWorkingActivitiesTotalDuration,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import {
	matchPath,
} from "react-router-dom";

import {
	ProblemsNotificationPage,
} from "models/problems-notification/constants";
import {
	type ProblemsNotificationPages,
} from "models/problems-notification/types";
import {
	CLONE_PACKAGE_ROUTE_PATH,
	EDIT_PACKAGE_ROUTE_PATH,
	EMPLOYEES_DASHBOARD_NEW_VIEW_ROUTE_PATH,
	EMPLOYEES_DASHBOARD_OLD_VIEW_ROUTE_PATH,
	NEW_PACKAGE_ROUTE_PATH,
	OVERTIME_DASHBOARD_ROUTE_PATH,
	OVERTIME_DETAILS_ROUTE_PATH,
	PACKAGES_DASHBOARD_ROUTE_PATH,
	PROJECTS_DASHBOARD_ROUTE_PATH,
	PROJECT_DETAILS_NEW_VIEW_ROUTE_PATH,
	PROJECT_DETAILS_OLD_VIEWS_ROUTE_PATH,
	TIME_JOURNAL_ROUTE_PATH,
} from "pages/constants/routes";

type RoutePaths = string[];

const PAGE_TO_ROUTE_PATH_MAP: Record<ProblemsNotificationPage, RoutePaths> = {
	[ProblemsNotificationPage.TIME_JOURNAL]: [
		TIME_JOURNAL_ROUTE_PATH,
	],
	[ProblemsNotificationPage.OVERTIME]: [
		OVERTIME_DASHBOARD_ROUTE_PATH,
		OVERTIME_DETAILS_ROUTE_PATH,
	],
	[ProblemsNotificationPage.PROJECTS]: [
		PROJECTS_DASHBOARD_ROUTE_PATH,
		PROJECT_DETAILS_OLD_VIEWS_ROUTE_PATH,
		PROJECT_DETAILS_NEW_VIEW_ROUTE_PATH,
		PACKAGES_DASHBOARD_ROUTE_PATH,
		CLONE_PACKAGE_ROUTE_PATH,
		EDIT_PACKAGE_ROUTE_PATH,
		NEW_PACKAGE_ROUTE_PATH,
	],
	[ProblemsNotificationPage.EMPLOYEES]: [
		EMPLOYEES_DASHBOARD_OLD_VIEW_ROUTE_PATH,
		EMPLOYEES_DASHBOARD_NEW_VIEW_ROUTE_PATH,
	],
};

const getShouldProblemsNotificationBeDisplayedOnPage = (
	pages: ProblemsNotificationPages,
): boolean => {
	const {
		pathname,
	} = window.location;

	for (const page of pages) {
		const routePaths = PAGE_TO_ROUTE_PATH_MAP[page];

		if (!isUndefined(routePaths)) {
			const matchParams = matchPath(
				pathname,
				{
					path: routePaths,
					exact: true,
					strict: false,
				},
			);

			if (!isNull(matchParams)) {
				return true;
			}
		}
	}

	return false;
};

export {
	getShouldProblemsNotificationBeDisplayedOnPage,
};

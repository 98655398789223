/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import {
	type DetailedHTMLProps,
	type FC,
	type HTMLAttributes,
} from "react";

import {
	ReactComponent as DataLoadingErrorIcon,
} from "images/table-data-loading-error-placeholder.svg";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import styles from "../content-placeholder-components.module.css";

const DATA_NAME_ATTRIBUTE = toDataAttribute("Data loading error placeholder");

const ErrorPlaceholder: FC<
	DetailedHTMLProps<
		HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	>
> = ({
	children,
	className,
	...otherProps
}) => {
	return (
		<div
			className={
				classNames(
					styles.placeholderContainer,
					className,
				)
			}
			data-name={DATA_NAME_ATTRIBUTE}
			{...otherProps}
		>
			<DataLoadingErrorIcon className={styles.icon}/>
			<p className={styles.description}>
				Unable to load data. Try refreshing the page.
			</p>
		</div>
	);
};

export {
	ErrorPlaceholder,
};

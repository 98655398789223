/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	BYTES_PER_KIB,
	KIB_PER_MIB,
} from "constants/common";
import {
	round,
} from "utilities/number";

const getFileSizeString = (fileSize: number): string => {
	if (fileSize < BYTES_PER_KIB) {
		return `${fileSize} bytes`;
	}

	const KBs = fileSize / BYTES_PER_KIB;

	if (KBs < KIB_PER_MIB) {
		return `${round(KBs)} KB`;
	}

	return `${round(fileSize / (BYTES_PER_KIB * KIB_PER_MIB))} MB`;
};

export {
	getFileSizeString,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type FC,
} from "react";

import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type OvertimeRequest,
} from "models/overtime/types";
import {
	OvertimeRequestEmployeeExternalLinks,
} from "pages/components/overtime-request-employee-external-links/overtime-request-employee-external-links";
import {
	generateEmailFromFullName,
} from "utils/email";

import {
	DataCellValueText,
} from "../../data-cell-value-text/data-cell-value-text";
import {
	DataRow,
} from "../../data-row/data-row";

interface OwnerDataRowProps extends Partial<WithPeriodDates> {
	overtimeRequest: OvertimeRequest;
}

const OwnerDataRow: FC<OwnerDataRowProps> = ({
	overtimeRequest,
	periodStartDate,
	periodEndDate,
}) => {
	const {
		employeeUid: employeeId,
		employeeName,
	} = overtimeRequest;

	return (
		<DataRow
			title="Employee"
			dataNamePrefix="Owner"
		>
			<OvertimeRequestEmployeeExternalLinks
				employee={{
					id: employeeId,
					name: employeeName,
					email: generateEmailFromFullName(employeeName),
				}}
				periodStartDate={periodStartDate}
				periodEndDate={periodEndDate}
				placement="bottom-start"
			>
				<DataCellValueText>
					{employeeName}
				</DataCellValueText>
			</OvertimeRequestEmployeeExternalLinks>
		</DataRow>
	);
};

export {
	OwnerDataRow,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	minutesInHour,
} from "date-fns/constants";

import {
	type Duration,
} from "models/dates-and-time/types";

import {
	type NullableTimePickerValue,
} from "../../types";
import {
	getTimePickerDuration,
} from "../get-time-picker-duration/get-time-picker-duration";

interface GetActivityDurationParams {
	startTime: NullableTimePickerValue;
	endTime: NullableTimePickerValue;
}

const getActivityDuration = ({
	startTime,
	endTime,
}: GetActivityDurationParams): Duration => {
	const startTimeMinutes = getTimePickerDuration(startTime);
	const endTimeMinutes = getTimePickerDuration(endTime);
	const activityDurationInMinutes = endTimeMinutes - startTimeMinutes;
	const activityDuration = activityDurationInMinutes / minutesInHour;

	return activityDuration;
};

export {
	getActivityDuration,
};

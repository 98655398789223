/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type FC,
} from "react";

import {
	ReactComponent as PackagesIcon,
} from "icons/external-link-packages-24.svg";
import {
	type ProjectId,
} from "models/projects/types";
import {
	generatePackagesPageUrl,
} from "pages/utils/generate-packages-page-url";

import {
	ExternalLinkHint,
} from "../external-link-hint/external-link-hint";
import {
	ExternalLink,
} from "../external-link/external-link";

interface ExternalLinkPackagesProps {
	projectId: ProjectId;
}

const ExternalLinkPackages: FC<ExternalLinkPackagesProps> = ({
	projectId,
}) => {
	return (
		<ExternalLinkHint content="Packages">
			<ExternalLink
				color="night600"
				icon={PackagesIcon}
				href={
					generatePackagesPageUrl({
						projectId,
					})
				}
				target="_blank"
			/>
		</ExternalLinkHint>
	);
};

export {
	ExternalLinkPackages,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as NavigationChevronDown,
} from "@epam/assets/icons/common/navigation-chevron-down-18.svg";
import {
	ReactComponent as NavigationChevronRight,
} from "@epam/assets/icons/common/navigation-chevron-right-18.svg";
import {
	type FC,
	type SVGProps,
} from "react";

import {
	toDataAttribute,
} from "utils/to-data-attribute";

const DATA_NAME_ATTRIBUTE = toDataAttribute("Folding icon");

interface NavigationChevronIconProps extends SVGProps<SVGSVGElement> {
	isFolded: boolean;
}

const NavigationChevronIcon: FC<NavigationChevronIconProps> = ({
	isFolded,
	...otherProps
}) => {
	const IconComponent = isFolded
		? NavigationChevronRight
		: NavigationChevronDown;

	return (
		<IconComponent
			data-name={DATA_NAME_ATTRIBUTE}
			data-folded={String(isFolded)}
			{...otherProps}
		/>
	);
};

export {
	NavigationChevronIcon,
};

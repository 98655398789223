/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	DropSpot,
	Text,
} from "@epam/loveship";
import {
	type FC,
} from "react";

import {
	SUPPORTED_FILES_STRING,
} from "../../constants";

import styles from "./attachments-uploader.module.css";

interface AttachmentsUploaderProps {
	maxAttachmentsCount: number;
	processAddedAttachments: (files: File[]) => void;
}

const AttachmentsUploader: FC<AttachmentsUploaderProps> = ({
	maxAttachmentsCount,
	processAddedAttachments,
}) => {
	return (
		<DropSpot
			onUploadFiles={processAddedAttachments}
			accept={SUPPORTED_FILES_STRING}
			infoText={(
				<>
					<Text
						color="night500"
						fontSize="14"
						lineHeight="24"
						size="24"
					>
						You can attach maximum {maxAttachmentsCount} files.
					</Text>
					<Text
						color="night500"
						fontSize="12"
						lineHeight="18"
						size="18"
						cx={styles.fileScanningInfoText}
					>
						All files are scanned for security reasons.
						<br/>
						Upload can take up to 10 minutes.
					</Text>
				</>
			)}
		/>
	);
};

export {
	AttachmentsUploader,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type ComponentProps,
	type FC,
} from "react";

import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type EmployeeEmail,
	type EmployeeFullName,
	type EmployeeId,
} from "models/employees/types";
import {
	type WithChildren,
} from "types/common";

import {
	ExternalLinksTooltipColumnInfo,
} from "../external-links-tooltip/column-info/column-info";
import {
	ExternalLinksTooltipColumnLinks,
} from "../external-links-tooltip/column-links/column-links";
import {
	ExternalLinksTooltipContainer,
} from "../external-links-tooltip/container/container";
import {
	ExternalLinkEmail,
} from "../external-links-tooltip/external-link-email/external-link-email";
import {
	ExternalLinkPeople,
} from "../external-links-tooltip/external-link-people/external-link-people";
import {
	ExternalLinkTeams,
} from "../external-links-tooltip/external-link-teams/external-link-teams";
import {
	ExternalLinkTelescopeEmployee,
} from "../external-links-tooltip/external-link-telescope-employee/external-link-telescope-employee";
import {
	ExternalLinkTimeJournal,
} from "../external-links-tooltip/external-link-time-journal/external-link-time-journal";
import {
	ExternalLinksTooltipInfoTextPrimary,
} from "../external-links-tooltip/info-text-primary/info-text-primary";
import {
	ExternalLinksTooltipRowDivider,
} from "../external-links-tooltip/row-divider/row-divider";
import {
	ExternalLinksTooltipRow,
} from "../external-links-tooltip/row/row";
import {
	ExternalLinksTooltip,
} from "../external-links-tooltip/tooltip/tooltip";

import styles from "./overtime-request-employee-external-links.module.css";

interface Employee {
	id: EmployeeId;
	name: EmployeeFullName;
	email: EmployeeEmail;
}

interface OvertimeRequestEmployeeExternalLinksProps extends
	WithChildren,
	Partial<WithPeriodDates> {
	employee: Employee;
	placement?: ComponentProps<typeof ExternalLinksTooltip>["placement"];
}

const OvertimeRequestEmployeeExternalLinks: FC<
	OvertimeRequestEmployeeExternalLinksProps
> = ({
	employee,
	periodStartDate,
	periodEndDate,
	placement,
	children,
}) => {
	const {
		id: employeeId,
		name: employeeFullName,
		email: employeeEmail,
	} = employee;

	return (
		<ExternalLinksTooltip
			content={(
				<ExternalLinksTooltipContainer>
					<ExternalLinksTooltipColumnInfo>
						<ExternalLinksTooltipInfoTextPrimary>
							{employeeFullName}
						</ExternalLinksTooltipInfoTextPrimary>
					</ExternalLinksTooltipColumnInfo>

					<ExternalLinksTooltipColumnLinks>
						<ExternalLinksTooltipRow>
							<ExternalLinkTelescopeEmployee
								employeeId={employeeId}
							/>
							<ExternalLinkPeople
								employeeId={employeeId}
							/>
							<ExternalLinkTimeJournal
								employeeId={employeeId}
								periodStartDate={periodStartDate}
								periodEndDate={periodEndDate}
							/>
						</ExternalLinksTooltipRow>

						<ExternalLinksTooltipRowDivider/>

						<ExternalLinksTooltipRow>
							<ExternalLinkEmail
								employeeEmail={employeeEmail}
							/>
							<ExternalLinkTeams
								employeeEmail={employeeEmail}
							/>
						</ExternalLinksTooltipRow>
					</ExternalLinksTooltipColumnLinks>
				</ExternalLinksTooltipContainer>
			)}
			placement={placement}
			targetClassName={styles.externalLinksTarget}
		>
			{children}
		</ExternalLinksTooltip>
	);
};

export {
	OvertimeRequestEmployeeExternalLinks,
};

/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import map from "lodash/map";
import {
	type FC,
	Fragment,
	type HTMLAttributes,
} from "react";

import {
	LevelName,
} from "./level-name";
import {
	LevelNameSeparator,
} from "./level-name-separator";
import {
	type BreadcrumbsLevelNames,
} from "./types";

import styles from "./breadcrumb.module.css";

interface BreadcrumbProps extends HTMLAttributes<HTMLDivElement> {
	levelNames: BreadcrumbsLevelNames;
}

const Breadcrumb: FC<BreadcrumbProps> = ({
	levelNames,
	className,
	...otherProps
}) => {
	const levelNamesLength = levelNames.length;

	return (
		<div
			className={
				classNames(
					styles.breadcrumb,
					className,
				)
			}
			{...otherProps}
		>
			{
				map(
					levelNames,
					(levelName, index) => {
						const isLastLevelName = index === levelNamesLength - 1;

						return (
							<Fragment key={levelName}>
								<LevelName>
									{levelName}
								</LevelName>
								{
									!isLastLevelName
										? <LevelNameSeparator/>
										: null
								}
							</Fragment>
						);
					},
				)
			}
		</div>
	);
};

export {
	Breadcrumb,
};

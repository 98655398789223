/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as CommonFileIcon,
} from "@epam/assets/icons/common/file-file-24.svg";
import {
	ReactComponent as ExcelFileIcon,
} from "@epam/assets/icons/common/file-file_excel-24.svg";
import {
	ReactComponent as ImageFileIcon,
} from "@epam/assets/icons/common/file-file_image-24.svg";
import {
	ReactComponent as PdfFileIcon,
} from "@epam/assets/icons/common/file-file_pdf-24.svg";
import {
	ReactComponent as TextFileIcon,
} from "@epam/assets/icons/common/file-file_text-24.svg";
import {
	ReactComponent as WordFileIcon,
} from "@epam/assets/icons/common/file-file_word-24.svg";
import classNames from "classnames";
import isUndefined from "lodash/isUndefined";

import {
	SUPPORTED_FILES_INFO,
} from "pages/components/overtime-request-details/constants";

import styles from "../../attachment.module.css";

interface AttachmentInfo {
	icon: JSX.Element;
	extension: string;
}

interface GetAttachmentInfoParams {
	mimeType: string;
	isIconDimmed: boolean;
}

const getAttachmentInfo = ({
	mimeType,
	isIconDimmed,
}: GetAttachmentInfoParams): AttachmentInfo => {
	const fileInfo = SUPPORTED_FILES_INFO.find((supportedFileInfo) => {
		return supportedFileInfo.mimeType === mimeType;
	});

	if (isUndefined(fileInfo)) {
		return {
			icon: (
				<CommonFileIcon
					className={
						classNames(
							styles.icon,
							styles.common,
							{
								[styles.dimmed]: isIconDimmed,
							},
						)
					}
				/>
			),
			extension: "msg",
		};
	}

	const {
		type,
		extension,
	} = fileInfo;

	switch (type) {
		case "image": {
			return {
				icon: (
					<ImageFileIcon
						className={
							classNames(
								styles.icon,
								styles.image,
								{
									[styles.dimmed]: isIconDimmed,
								},
							)
						}
					/>
				),
				extension,
			};
		}

		case "word": {
			return {
				icon: (
					<WordFileIcon
						className={
							classNames(
								styles.icon,
								styles.word,
								{
									[styles.dimmed]: isIconDimmed,
								},
							)
						}
					/>
				),
				extension,
			};
		}

		case "excel": {
			return {
				icon: (
					<ExcelFileIcon
						className={
							classNames(
								styles.icon,
								styles.excel,
								{
									[styles.dimmed]: isIconDimmed,
								},
							)
						}
					/>
				),
				extension,
			};
		}

		case "text": {
			return {
				icon: (
					<TextFileIcon
						className={
							classNames(
								styles.icon,
								styles.text,
								{
									[styles.dimmed]: isIconDimmed,
								},
							)
						}
					/>
				),
				extension,
			};
		}

		case "pdf": {
			return {
				icon: (
					<PdfFileIcon
						className={
							classNames(
								styles.icon,
								styles.pdf,
								{
									[styles.dimmed]: isIconDimmed,
								},
							)
						}
					/>
				),
				extension,
			};
		}

		default: {
			return {
				icon: (
					<CommonFileIcon
						className={
							classNames(
								styles.icon,
								styles.common,
								{
									[styles.dimmed]: isIconDimmed,
								},
							)
						}
					/>
				),
				extension,
			};
		}
	}
};

export {
	getAttachmentInfo,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isUndefined from "lodash/isUndefined";
import {
	type FC,
} from "react";

import {
	type EmployeeId,
} from "models/employees/types";
import {
	type ProjectPositionBillingTypeId,
	type ProjectPositionBillingTypeName,
	type ProjectPositionId,
	type ProjectPositionPrimaryRoleName,
} from "models/project-positions/types";
import {
	getBillingTypeById,
} from "models/project-positions/utils/get-billing-type-by-id";

import {
	ExternalLinksTooltipInfoTextSecondary as InfoTextSecondary,
} from "../info-text-secondary/info-text-secondary";

interface Position {
	id: ProjectPositionId;
	primaryRoleName: ProjectPositionPrimaryRoleName;
	employeeUid?: EmployeeId;
	billingTypeName?: ProjectPositionBillingTypeName;
	billingTypeId?: ProjectPositionBillingTypeId;
}

interface PositionInfoProps {
	position: Position;
	isOnlyPosition: boolean;
}

const PositionInfo: FC<PositionInfoProps> = ({
	position,
	isOnlyPosition,
}) => {
	const {
		id,
		employeeUid = "",
		primaryRoleName,
		billingTypeName,
		billingTypeId,
	} = position;

	if (id === `NO_POSITION_${employeeUid}`) {
		if (isOnlyPosition) {
			return (
				<InfoTextSecondary>
					No position
				</InfoTextSecondary>
			);
		}

		return null;
	}

	let billingTypeNameActual = billingTypeName;

	const billingType = getBillingTypeById(billingTypeId);

	if (!isUndefined(billingType)) {
		billingTypeNameActual = billingType.name;
	}

	return (
		<InfoTextSecondary>
			{primaryRoleName}
			<br/>
			{billingTypeNameActual ?? null}
		</InfoTextSecondary>
	);
};

export {
	PositionInfo as ExternalLinksTooltipPositionInfo,
};

/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type FC,
} from "react";

import styles from "./menu-separator.module.css";

const MenuSeparator: FC = () => {
	return (
		<div className={styles.separator}/>
	);
};

export {
	MenuSeparator,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as DeleteIcon,
} from "@epam/assets/icons/common/action-delete-18.svg";
import {
	ControlGroup,
	FlexRow,
	IconButton,
	Text,
	TextInput,
	TimePicker,
} from "@epam/loveship";
import {
	InputAddon,
} from "@epam/uui";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import {
	type FC,
	useMemo,
	useState,
} from "react";

import {
	ReactComponent as RestIcon,
} from "icons/presence-activity-rest-18.svg";
import {
	ReactComponent as ActivityIcon,
} from "icons/presence-activity-work-18.svg";
import {
	formatDuration,
} from "models/dates-and-time/utils/format-duration";
import {
	PresenceActivityType,
} from "models/presences/constants";
import {
	type PresenceActivityName,
	type PresenceSettingForDate,
} from "models/presences/types";
import {
	type WithClassName,
} from "types/common";

import {
	ValidationErrorLevel,
} from "../../constants";
import {
	type EditablePresenceActivity,
	type EditablePresenceActivityId,
	type EditablePresenceActivityToChange,
	type NullableTimePickerValue,
	type ValidationError,
	type ValidationErrors,
} from "../../types";
import {
	getActivityDuration,
} from "../../utils/get-activity-duration/get-activity-duration";

import styles from "./presence-activity-row.module.css";

interface ErrorMessageProps {
	validationError: ValidationError | undefined;
}

const ErrorMessage: FC<ErrorMessageProps> = ({
	validationError,
}) => {
	if (isUndefined(validationError)) {
		return null;
	}

	const {
		level,
		message,
	} = validationError;

	if (isUndefined(message)) {
		return null;
	}

	return (
		<Text
			key={`${level}-${message}`}
			color="fire"
			fontSize="14"
			lineHeight="18"
			size="18"
			cx={
				classNames(
					styles.validationError,
					{
						[styles.startTime]: level === ValidationErrorLevel.START_TIME,
						[styles.endTime]: level === ValidationErrorLevel.END_TIME,
					},
				)
			}
		>
			{message}
		</Text>
	);
};

interface PresenceActivityRowProps extends WithClassName {
	activity: EditablePresenceActivity;
	presenceSetting: PresenceSettingForDate;
	onChange: (activity: EditablePresenceActivityToChange) => void;
	onDelete: (activityId: EditablePresenceActivityId) => void;
	isEditDisabled: boolean;
	validationErrors: ValidationErrors;
}

const PresenceActivityRow: FC<PresenceActivityRowProps> = ({
	activity,
	presenceSetting,
	onChange,
	onDelete,
	isEditDisabled: isActivityEditDisabled,
	validationErrors,
	className,
}) => {
	const {
		id: activityId,
		initialName,
		startTime: initialStartTime,
		endTime: initialEndTime,
		activityType,
		presenceActivityConfigId,
	} = activity;
	const {
		availableActivities,
	} = presenceSetting;

	const startTimeValidationErrors = validationErrors.filter(({
		level,
	}) => {
		return level === ValidationErrorLevel.START_TIME;
	});

	const endTimeValidationErrors = validationErrors.filter(({
		level,
	}) => {
		return level === ValidationErrorLevel.END_TIME;
	});

	const isNameEditDisabled = useMemo(
		() => {
			const activityConfig = availableActivities.find(({
				id,
			}) => {
				return id === presenceActivityConfigId;
			});

			const isNameEditEnabled = activityConfig?.editable ?? false;

			return !isNameEditEnabled;
		},
		[
			presenceActivityConfigId,
			availableActivities,
		],
	);

	const [
		name,
		setName,
	] = useState<PresenceActivityName>(initialName);

	const [
		startTime,
		setStartTime,
	] = useState<NullableTimePickerValue>(initialStartTime);
	const [
		endTime,
		setEndTime,
	] = useState<NullableTimePickerValue>(initialEndTime);

	const activityDuration = getActivityDuration({
		startTime,
		endTime,
	});

	const updateActivity = (
		updatedActivityValues: Partial<EditablePresenceActivityToChange>,
	): void => {
		onChange({
			id: activityId,
			name,
			startTime,
			endTime,
			...updatedActivityValues,
		});
	};

	return (
		<>
			<FlexRow
				spacing={null}
				cx={className}
			>
				<ControlGroup
					cx={styles.nameControl}
				>
					<TextInput
						size="36"
						placeholder={initialName}
						icon={() => {
							if (activityType === PresenceActivityType.REST) {
								return (
									<RestIcon
										className={styles.activityTypeIcon}
									/>
								);
							}

							return (
								<ActivityIcon
									className={styles.activityTypeIcon}
								/>
							);
						}}
						isDisabled={
							isActivityEditDisabled
							|| isNameEditDisabled
						}
						value={name}
						onValueChange={(nextName) => {
							if (!isUndefined(nextName)) {
								setName(nextName);

								updateActivity({
									name: nextName,
								});
							}
						}}
						onBlur={() => {
							/*
								The name can't be empty according to the requirements,
								so we restore the initial value.
							*/
							if (isEmpty(name.trim())) {
								setName(initialName);

								updateActivity({
									name: initialName,
								});
							}
						}}
					/>
					{
						activityDuration > 0
							? (
								<InputAddon
									content={`${formatDuration(activityDuration)}h`}
									cx={styles.activityDuration}
								/>
							)
							: null
					}
				</ControlGroup>

				{/*
					Since `TimePicker` doesn't support styles customization,
					we create a container and select the picker (as a descendant element)
					by a class (see the styles file).
				*/}
				<div
					className={styles.timeInputContainer}
				>
					<TimePicker
						format={24}
						placeholder="From"
						isDisabled={isActivityEditDisabled}
						isInvalid={!isEmpty(startTimeValidationErrors)}
						value={startTime}
						onValueChange={(nextStartTime) => {
							setStartTime(nextStartTime);

							updateActivity({
								startTime: nextStartTime,
							});
						}}
					/>
				</div>

				<div
					className={styles.timeInputsSeparator}
				/>

				{/*
					Since `TimePicker` doesn't support styles customization,
					we create a container and select the picker (as a descendant element)
					by a class (see the styles file).
				*/}
				<div
					className={styles.timeInputContainer}
				>
					<TimePicker
						format={24}
						placeholder="To"
						isDisabled={isActivityEditDisabled}
						isInvalid={!isEmpty(endTimeValidationErrors)}
						value={endTime}
						onValueChange={(nextEndTime) => {
							setEndTime(nextEndTime);

							updateActivity({
								endTime: nextEndTime,
							});
						}}
					/>
				</div>

				<IconButton
					color="night500"
					icon={DeleteIcon}
					onClick={() => {
						onDelete(activity.id);
					}}
					isDisabled={isActivityEditDisabled}
					cx={
						classNames(
							styles.deleteButton,
							{
								[styles.disabled]: isActivityEditDisabled,
							},
						)
					}
				/>
			</FlexRow>

			<ErrorMessage
				validationError={startTimeValidationErrors.at(0)}
			/>
			<ErrorMessage
				validationError={endTimeValidationErrors.at(0)}
			/>
		</>
	);
};

export {
	PresenceActivityRow,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	useUuiContext,
} from "@epam/uui-core";
import {
	type FC,
	useEffect,
	useRef,
} from "react";
import {
	useSelector,
} from "react-redux";

import {
	type RootState,
} from "store";

import styles from "./spinner.module.css";

const SpinnerBody: FC = () => {
	const {
		uuiLayout,
	} = useUuiContext();
	const layer = useRef(uuiLayout.getLayer());

	useEffect(
		() => {
			const layerValue = layer.current;

			return () => {
				uuiLayout.releaseLayer(layerValue);
			};
		},
		[
			uuiLayout,
		],
	);

	return (
		<div
			className={styles.spinner}
			style={{
				zIndex: layer.current.zIndex,
			}}
		>
			<div className={styles.clock}>
				<div className={styles.hours}>
					<div className={styles.hourArrow}/>
				</div>
				<div className={styles.minutes}>
					<div className={styles.minuteArrow}/>
				</div>
			</div>
		</div>
	);
};

const Spinner: FC = () => {
	const isSpinnerVisible = useSelector((state: RootState) => {
		return state.spinner.isSpinnerVisible;
	});

	if (isSpinnerVisible) {
		return <SpinnerBody/>;
	}

	return null;
};

export {
	Spinner,
	SpinnerBody,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Button,
	FlexRow,
	FlexSpacer,
	LabeledInput,
	ModalBlocker,
	ModalFooter,
	ModalHeader,
	ModalWindow,
	Text,
	TextInput,
} from "@epam/loveship";
import {
	type IModal,
} from "@epam/uui-core";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import {
	type FC,
	useState,
} from "react";

import {
	toDataAttribute,
} from "utils/to-data-attribute";

type CancelRejectApprovedOvertimeReason = string;

interface CancelRejectApprovedOvertimeReasonModalWindowProps extends IModal<
	CancelRejectApprovedOvertimeReason | undefined
> {
	isRejectAction: boolean;
}

const CancelRejectApprovedOvertimeReasonModalWindow: FC<
	CancelRejectApprovedOvertimeReasonModalWindowProps
> = ({
	isRejectAction,
	...modalWindowProps
}) => {
	const [
		reason,
		setReason,
	] = useState<CancelRejectApprovedOvertimeReason>("");

	const [
		error,
		setError,
	] = useState("");

	const closeModalWindow = (): void => {
		modalWindowProps.success(undefined);
	};

	const submitReason = (): void => {
		if (isEmpty(reason)) {
			setError(
				`Please indicate the reason of ${
					isRejectAction
						? "rejecting"
						: "cancelling"
				} overtime`,
			);

			return;
		}

		modalWindowProps.success(reason);
	};

	return (
		<ModalBlocker
			{...modalWindowProps}
		>
			<ModalWindow width="600">
				<ModalHeader
					title={
						`${
							isRejectAction
								? "Reject"
								: "Cancel"
						} approved overtime`
					}
					borderBottom={true}
					rawProps={{
						"data-name": toDataAttribute("Modal window header"),
					}}
					onClose={closeModalWindow}
				/>
				<FlexRow
					vPadding="24"
					padding="24"
					size="24"
				>
					<LabeledInput
						label={
							`${
								isRejectAction
									? "Reject"
									: "Cancel"
							} reason:`
						}
						labelPosition="top"
					>
						<TextInput
							value={reason}
							onValueChange={(reasonNext) => {
								if (!isUndefined(reasonNext)) {
									setReason(reasonNext);
								}
							}}
						/>
					</LabeledInput>
				</FlexRow>
				<FlexRow
					padding="24"
					size="24"
				>
					{
						!isEmpty(error)
							? (
								<Text color="fire">
									{error}
								</Text>
							)
							: null
					}
				</FlexRow>
				<ModalFooter
					borderTop={true}
					rawProps={{
						"data-name": toDataAttribute("Modal window footer"),
					}}
				>
					<FlexSpacer/>
					<Button
						caption="Back"
						color="gray"
						fill="white"
						onClick={closeModalWindow}
					/>
					<Button
						caption={
							`${
								isRejectAction
									? "Reject"
									: "Cancel"
							} approved overtime`
						}
						color="grass"
						onClick={submitReason}
					/>
				</ModalFooter>
			</ModalWindow>
		</ModalBlocker>
	);
};

export {
	CancelRejectApprovedOvertimeReasonModalWindow,
	type CancelRejectApprovedOvertimeReason,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";

import {
	toDateString,
} from "models/dates-and-time/utils/to-date-string";
import {
	type EmployeeId,
} from "models/employees/types";
import {
	PresenceStatus,
} from "models/presences/constants";
import {
	type PresenceActivityToUpdate,
	type PresenceToUpdate,
} from "models/presences/types";

import {
	type EditablePresenceActivities,
} from "../../types";
import {
	getPresenceTimeString,
} from "../get-presence-time-string/get-presence-time-string";

const getPresenceStatus = (
	currentStatus: PresenceStatus | undefined,
	newStatus: PresenceStatus | undefined,
): PresenceToUpdate["status"] => {
	if (!isUndefined(newStatus)) {
		return newStatus;
	}

	if (!isUndefined(currentStatus)) {
		if (currentStatus === PresenceStatus.PREFILLED) {
			return PresenceStatus.SUBMITTED;
		}

		return currentStatus;
	}

	return null;
};

interface PreparePresenceToUpdateParams {
	employeeId: EmployeeId;
	currentPresenceStatus: PresenceStatus | undefined;
	newPresenceStatus: PresenceStatus | undefined;
	presenceDate: Date;
	activities: EditablePresenceActivities;
}

const getPresenceToUpdate = ({
	employeeId,
	currentPresenceStatus,
	newPresenceStatus,
	presenceDate,
	activities: editableActivities,
}: PreparePresenceToUpdateParams): PresenceToUpdate => {
	const activities = editableActivities.map<PresenceActivityToUpdate>(
		(editableActivity) => {
			const {
				activityId,
				name,
				startTime,
				endTime,
				presenceActivityConfigId,
			} = editableActivity;

			return {
				id: activityId,
				// @ts-expect-error Validation ensures that `startTime` is always selected.
				startTime: getPresenceTimeString(startTime),
				endTime: !isNull(endTime)
					? getPresenceTimeString(endTime)
					: null,
				name,
				presenceActivityConfigId,
			};
		},
	);

	return {
		employeeId,
		date: toDateString(presenceDate),
		status: getPresenceStatus(
			currentPresenceStatus,
			newPresenceStatus,
		),
		activities,
	};
};

export {
	getPresenceToUpdate,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	FlexCell,
	FlexRow,
	Text,
} from "@epam/loveship";
import classNames from "classnames";
import {
	type FC,
} from "react";

import {
	type WithChildren,
} from "types/common";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import styles from "./data-row.module.css";

interface DataRowProps extends WithChildren {
	title: string;
	dataNamePrefix: string;
	valueCellClassName?: string;
}

const DataRow: FC<DataRowProps> = ({
	title,
	valueCellClassName,
	children,
	dataNamePrefix = "",
}) => {
	return (
		<FlexRow
			columnGap={9}
			spacing={null}
			alignItems="top"
			rawProps={{
				"data-name": toDataAttribute(`${dataNamePrefix} row`),
			}}
		>
			<FlexCell
				width={171}
				shrink={0}
				cx={styles.cell}
				rawProps={{
					"data-name": toDataAttribute(`${dataNamePrefix} field name`),
				}}
			>
				<Text
					color="night800"
					fontSize="14"
					lineHeight="18"
					size="18"
					fontWeight="600"
				>
					{title}
				</Text>
			</FlexCell>
			<FlexCell
				width="100%"
				cx={
					classNames(
						styles.cell,
						valueCellClassName,
					)
				}
				rawProps={{
					"data-name": toDataAttribute(`${dataNamePrefix} value`),
				}}
			>
				{children}
			</FlexCell>
		</FlexRow>
	);
};

export {
	DataRow,
};

/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import find from "lodash/find";
import isUndefined from "lodash/isUndefined";
import toPairs from "lodash/toPairs";

import {
	type DateString,
} from "models/dates-and-time/types";

import {
	type Preset,
} from "../constants";
import {
	type PresetPeriodShift,
} from "../types";
import {
	getPresetsForPeriodPicker,
} from "./get-presets-for-period-picker";

interface GetPresetByDatesParams {
	periodStartDate: DateString;
	periodEndDate: DateString;
	presetPeriodShift?: PresetPeriodShift;
}

const getPresetByPeriodDates = ({
	periodStartDate,
	periodEndDate,
	presetPeriodShift,
}: GetPresetByDatesParams): Preset | undefined => {
	const presetsForPeriodPicker = getPresetsForPeriodPicker({
		presetPeriodShift,
	});
	const presetsForPickerEntries = toPairs(presetsForPeriodPicker);

	const presetEntry = find(
		presetsForPickerEntries,
		([
			,
			{
				getRange,
			},
		]) => {
			const {
				from,
				to,
			} = getRange();

			return (
				periodStartDate === from
				&& periodEndDate === to
			);
		},
	);

	if (isUndefined(presetEntry)) {
		return undefined;
	}

	const [
		preset,
	] = presetEntry;

	return preset as Preset;
};

export {
	getPresetByPeriodDates,
};

/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import {
	FlexCell,
	Text,
} from "@epam/loveship";
import {
	type FC,
} from "react";

import styles from "./data-picker-not-found-placeholder.module.css";

/*
	The same as in `DataPickerBody` UUI component's styles.
	https://github.com/epam/UUI/blob/main/uui/components/pickers/DataPickerBody.tsx#L29-L33
*/
const DataPickerNotFoundPlaceholder: FC = () => {
	return (
		<FlexCell
			grow={1}
			textAlign="center"
			cx={styles.notFound}
		>
			<Text>No results found</Text>
		</FlexCell>
	);
};

export {
	DataPickerNotFoundPlaceholder,
};

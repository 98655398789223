/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	DatePicker,
	FlexCell,
	FlexRow,
	Text,
} from "@epam/loveship";
import {
	type DatePickerProps,
} from "@epam/uui";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import {
	type FC,
} from "react";

import {
	UUI_FULL_MONTH_NAME_DAY_FULL_YEAR_FORMAT,
} from "models/dates-and-time/constants";

import styles from "./date-picker.module.css";

type DatePickerValidationError = string;

type DatePickerValidationErrors = DatePickerValidationError[];

const getFieldValidationErrorMessageElements = (
	errors: DatePickerValidationErrors,
): JSX.Element[] => {
	return errors.map((errorMessage) => {
		return (
			<Text
				key={errorMessage}
				fontSize="12"
				lineHeight="18"
				cx={styles.errorMessage}
			>
				{errorMessage}
			</Text>
		);
	});
};

interface DatePickerCustomProps extends
	Pick<
		DatePickerProps,
		| "value"
		| "onValueChange"
	> {
	placeholder?: string;
	filterDates?: DatePickerProps["filter"];
	errors?: DatePickerValidationErrors;
}

const ERRORS_FALLBACK: DatePickerCustomProps["errors"] = [];

const DatePickerCustom: FC<DatePickerCustomProps> = ({
	value,
	onValueChange,
	placeholder,
	filterDates,
	errors = ERRORS_FALLBACK,
}) => {
	const hasErrors = !isEmpty(errors);

	return (
		<>
			<FlexRow
				cx={
					classNames(
						styles.datePickerRow,
						{
							[styles.hasErrors]: hasErrors,
						},
					)
				}
			>
				<DatePicker
					value={value}
					onValueChange={onValueChange}
					isInvalid={hasErrors}
					format={UUI_FULL_MONTH_NAME_DAY_FULL_YEAR_FORMAT}
					placeholder={placeholder}
					filter={filterDates}
				/>
			</FlexRow>

			{
				hasErrors
					? (
						<FlexRow
							alignItems="top"
							cx={styles.errorMessagesSection}
						>
							<FlexCell grow={1}>
								{getFieldValidationErrorMessageElements(errors)}
							</FlexCell>
						</FlexRow>
					)
					: null
			}
		</>
	);
};

export {
	DatePickerCustom as DatePicker,
	type DatePickerCustomProps as DatePickerProps,
	type DatePickerValidationErrors,
};

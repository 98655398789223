/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type FC,
} from "react";

import {
	toFancyDate,
} from "models/dates-and-time/utils/to-fancy-date";
import {
	type OvertimeRequest,
} from "models/overtime/types";

import {
	DataCellValueText,
} from "../../data-cell-value-text/data-cell-value-text";
import {
	DataRow,
} from "../../data-row/data-row";

interface DateDataRowProps {
	overtimeRequest: OvertimeRequest;
}

const DateDataRow: FC<DateDataRowProps> = ({
	overtimeRequest,
}) => {
	const {
		date,
	} = overtimeRequest;

	return (
		<DataRow
			title="Date"
			dataNamePrefix="Date"
		>
			<DataCellValueText>
				{toFancyDate(date)}
			</DataCellValueText>
		</DataRow>
	);
};

export {
	DateDataRow,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as ThreeDotsIcon,
} from "@epam/assets/icons/common/navigation-more_vert-24.svg";
import {
	Button,
} from "@epam/loveship";
import {
	type ComponentProps,
	forwardRef,
} from "react";

type PageActionsButtonProps = ComponentProps<typeof Button>;

const PageActionsButton = forwardRef<HTMLButtonElement, PageActionsButtonProps>(
	(props, ref) => {
		return (
			<Button
				ref={ref}
				icon={ThreeDotsIcon}
				color="gray"
				fill="white"
				{...props}
				isDropdown={false}
			/>
		);
	},
);

PageActionsButton.displayName = "PageActionsButton";

export {
	PageActionsButton,
};

/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as NavigationChevronRight,
} from "@epam/assets/icons/common/navigation-chevron-right-18.svg";
import {
	DropdownMenuButton,
} from "@epam/loveship";
import {
	type IHasRawProps,
} from "@epam/uui-core";
import classNames from "classnames";
import {
	type ButtonHTMLAttributes,
	type ComponentProps,
	forwardRef,
} from "react";

import styles from "./option.module.css";

type DropdownMenuButtonProps = ComponentProps<typeof DropdownMenuButton>;

const NavigationChevron: typeof NavigationChevronRight = (props) => {
	const {
		className,
	} = props;

	return (
		<NavigationChevronRight
			{...props}
			className={
				classNames(
					className,
					styles.navigationChevron,
				)
			}
		/>
	);
};

type IconProps = Pick<DropdownMenuButtonProps, "icon" | "iconPosition">;

const SUB_MENU_BUTTON_ICON_PROPS: IconProps = {
	icon: NavigationChevron,
	iconPosition: "right",
};

interface MenuOptionProps extends
	Omit<
		DropdownMenuButtonProps,
		/*
			UUI library is going to drop this property, so we have to avoid using it.
			Excluding it from the available props would help with not increasing the
			technical debt.
		*/
		"captionCX"
	>,
	IHasRawProps<ButtonHTMLAttributes<HTMLButtonElement>> {
	isSubMenuButton?: boolean;
}

const MenuOption = forwardRef<unknown, MenuOptionProps>(
	(props, ref) => {
		const {
			isDropdown,
			cx: className,
			isSubMenuButton = false,
			rawProps,
			...otherProps
		} = props;
		const {
			isDisabled,
		} = otherProps;

		const iconProps: IconProps | undefined = (
			isSubMenuButton
				? SUB_MENU_BUTTON_ICON_PROPS
				: undefined
		);

		return (
			<DropdownMenuButton
				cx={
					classNames(
						styles.menuOption,
						{
							[styles.subMenuOption]: isSubMenuButton,
							[styles.disabled]: isDisabled,
						},
						className,
					)
				}
				ref={ref}
				{...iconProps}
				{...rawProps}
				{...otherProps}
			/>
		);
	},
);

MenuOption.displayName = "MenuOption";

export {
	MenuOption,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	FlexRow,
	FlexSpacer,
	TabButton,
} from "@epam/loveship";
import {
	memo,
} from "react";

import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type OvertimeRequest,
} from "models/overtime/types";

import {
	CopyLinkButton,
} from "./components/copy-link-button/copy-link-button";
import {
	ApproverDataRow,
} from "./components/data-rows/approver/approver-data-row";
import {
	AttachmentsDataRow,
} from "./components/data-rows/attachments/attachments-data-row";
import {
	DateDataRow,
} from "./components/data-rows/date/date-data-row";
import {
	DescriptionDataRow,
} from "./components/data-rows/description/description-data-row";
import {
	DurationDataRow,
} from "./components/data-rows/duration/duration-data-row";
import {
	OvertimeTypeDataRow,
} from "./components/data-rows/overtime-type/overtime-type-data-row";
import {
	OwnerDataRow,
} from "./components/data-rows/owner/owner-data-row";
import {
	PackageDataRow,
} from "./components/data-rows/package/package-data-row";
import {
	ProjectLineDataRow,
} from "./components/data-rows/project-line/project-line-data-row";
import {
	type GAEventLocation,
} from "./types";

import styles from "./overtime-request-details.module.css";

interface OvertimeRequestDetailsProps extends Partial<WithPeriodDates> {
	overtimeRequest: OvertimeRequest;
	updateOvertimeRequest: () => void;
	eventLocation: GAEventLocation;
}

const OvertimeRequestDetails = memo<OvertimeRequestDetailsProps>(
	({
		overtimeRequest,
		periodStartDate,
		periodEndDate,
		updateOvertimeRequest,
		eventLocation,
	}) => {
		const {
			overtimeId,
			viewSingle: canViewDetails,
		} = overtimeRequest;

		return (
			<div
				className={styles.container}
			>
				<FlexRow
					spacing={null}
					borderBottom={true}
				>
					<TabButton
						caption="General"
						isLinkActive={true}
					/>
					<FlexSpacer/>
					<CopyLinkButton
						overtimeRequestId={overtimeId}
						className={styles.copyLinkButton}
					/>
				</FlexRow>
				<OwnerDataRow
					overtimeRequest={overtimeRequest}
					periodStartDate={periodStartDate}
					periodEndDate={periodEndDate}
				/>

				<ProjectLineDataRow
					overtimeRequest={overtimeRequest}
				/>

				{
					canViewDetails
						? (
							<PackageDataRow
								overtimeRequest={overtimeRequest}
							/>
						)
						: null
				}

				{
					canViewDetails
						? (
							<DescriptionDataRow
								overtimeRequest={overtimeRequest}
							/>
						)
						: null
				}

				<OvertimeTypeDataRow
					overtimeRequest={overtimeRequest}
					eventLocation={eventLocation}
				/>

				<DateDataRow
					overtimeRequest={overtimeRequest}
				/>

				<DurationDataRow
					overtimeRequest={overtimeRequest}
					eventLocation={eventLocation}
				/>

				<ApproverDataRow
					overtimeRequest={overtimeRequest}
				/>

				{
					canViewDetails
						? (
							<AttachmentsDataRow
								overtimeRequest={overtimeRequest}
								updateOvertimeRequest={updateOvertimeRequest}
								eventLocation={eventLocation}
							/>
						)
						: null
				}
			</div>
		);
	},
);

OvertimeRequestDetails.displayName = "OvertimeRequestDetails";

export {
	OvertimeRequestDetails,
};

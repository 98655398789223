/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import {
	type DetailedHTMLProps,
	type FC,
	type HTMLAttributes,
} from "react";

import dataCellStyles from "../data-cell.module.css";
import styles from "./info-cell.module.css";

interface InfoDataCellProps extends DetailedHTMLProps<
	HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
> {
	contentContainerClassName?: string;
}

const InfoDataCell: FC<InfoDataCellProps> = ({
	children,
	className,
	contentContainerClassName,
	...otherProps
}) => {
	return (
		<div
			className={
				classNames(
					styles.infoDataCell,
					className,
				)
			}
			{...otherProps}
		>
			<div
				className={styles.cellPadding}
			/>
			<div
				className={
					classNames(
						dataCellStyles.dataCell,
						styles.contentContainer,
						contentContainerClassName,
					)
				}
			>
				{children}
			</div>
		</div>
	);
};

export {
	InfoDataCell,
};

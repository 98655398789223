/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as ScanningStatusIcon,
} from "@epam/assets/icons/common/action-schedule-outline-12.svg";
import {
	ReactComponent as BlockedStatusIcon,
} from "@epam/assets/icons/common/notification-warning-fill-12.svg";
import {
	IconContainer,
} from "@epam/loveship";
import classNames from "classnames";
import {
	type FC,
} from "react";

import {
	OvertimeRequestAttachmentStatus,
} from "models/overtime/constants";
import {
	type OvertimeRequestAttachment,
} from "models/overtime/types";

import {
	getFileSizeString,
} from "../../utils/get-file-size-string/get-file-size-string";
import {
	SecondaryInfo,
} from "../secondary-info/secondary-info";

import styles from "./secondary-info-for-status.module.css";

interface SecondaryInfoForStatusProps {
	attachment: OvertimeRequestAttachment;
	fileExtension: string;
}

const SecondaryInfoForStatus: FC<SecondaryInfoForStatusProps> = ({
	attachment,
	fileExtension,
}) => {
	const {
		fileSize,
		status,
	} = attachment;

	if (status === OvertimeRequestAttachmentStatus.LOCAL) {
		return (
			<SecondaryInfo>
				<IconContainer
					icon={ScanningStatusIcon}
				/>
				Scanning...
			</SecondaryInfo>
		);
	}

	if (status === OvertimeRequestAttachmentStatus.BLOCKED) {
		return (
			<SecondaryInfo
				cx={
					classNames(
						styles.text,
						styles.warning,
					)
				}
			>
				<IconContainer
					icon={BlockedStatusIcon}
					cx={
						classNames(
							styles.icon,
							styles.warning,
						)
					}
				/>
				Viruses found
			</SecondaryInfo>
		);
	}

	return (
		<SecondaryInfo>
			{fileExtension.toUpperCase()}
			{", "}
			{getFileSizeString(fileSize)}
		</SecondaryInfo>
	);
};

export {
	SecondaryInfoForStatus,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Text as UUIText,
} from "@epam/loveship";
import classNames from "classnames";
import {
	type ComponentProps,
	type FC,
} from "react";

import styles from "./info-text.module.css";

type InfoTextProps = ComponentProps<typeof UUIText>;

const InfoText: FC<InfoTextProps> = ({
	children,
	cx,
	...otherProps
}) => {
	return (
		<UUIText
			cx={
				classNames(
					styles.text,
					cx,
				)
			}
			fontWeight="600"
			{...otherProps}
		>
			{children}
		</UUIText>
	);
};

export {
	InfoText,
};

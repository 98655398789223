/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isUndefined from "lodash/isUndefined";
import {
	type FC,
	type SVGProps,
} from "react";

import {
	type PresenceStatus,
} from "models/presences/constants";
import {
	type WithClassName,
} from "types/common";

interface PresenceStatusIconParams extends WithClassName {
	icon: FC<SVGProps<SVGSVGElement>>;
	"data-name"?: string;
}

type PresenceStatusIconMap = Record<
	| PresenceStatus
	| "DEFAULT",
	PresenceStatusIconParams | undefined
>;

interface PresenceStatusIconProps {
	presenceStatus: PresenceStatus;
	iconMap: PresenceStatusIconMap;
}

const PresenceStatusIcon: FC<PresenceStatusIconProps> = ({
	presenceStatus,
	iconMap,
}) => {
	const iconParams = (
		iconMap[presenceStatus]
		?? iconMap.DEFAULT
	);

	if (isUndefined(iconParams)) {
		return null;
	}

	const {
		icon: Icon,
		...iconProps
	} = iconParams;

	return (
		<Icon
			{...iconProps}
		/>
	);
};

export {
	PresenceStatusIcon,
	type PresenceStatusIconParams,
	type PresenceStatusIconMap as PresenceStatusIconMapType,
};

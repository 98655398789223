/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as NotificationInfoIcon,
} from "@epam/assets/icons/common/notification-info-fill-24.svg";
import {
	Alert,
	PickerInput,
	Text,
} from "@epam/loveship";
import {
	useArrayDataSource,
} from "@epam/uui-core";
import classNames from "classnames";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import {
	type FC,
	useState,
} from "react";
import {
	useSelector,
} from "react-redux";

import {
	overtimeApi,
} from "models/overtime/api";
import {
	OvertimeStatus,
} from "models/overtime/constants";
import {
	type OvertimeRequest,
	type OvertimeType,
	type OvertimeTypeOption,
	type OvertimeTypeOptions,
} from "models/overtime/types";
import {
	DataPickerNotFoundPlaceholder,
} from "pages/components/data-picker-not-found-placeholder/data-picker-not-found-placeholder";
import {
	setOvertimeType,
} from "pages/components/overtime-request-details/store/reducer";
import {
	type GAEventLocation,
} from "pages/components/overtime-request-details/types";
import {
	type RootState,
	useDispatch,
} from "store";

import {
	DataCellValueText,
} from "../../data-cell-value-text/data-cell-value-text";
import {
	DataRowWithNotificationContainer,
} from "../../data-row-with-notification-container/data-row-with-notification-container";
import {
	DataRow,
} from "../../data-row/data-row";

import styles from "./overtime-type-data-row.module.css";

interface OvertimeTypeDataRowProps {
	overtimeRequest: OvertimeRequest;
	eventLocation: GAEventLocation;
}

const OvertimeTypeDataRow: FC<OvertimeTypeDataRowProps> = ({
	overtimeRequest,
	eventLocation,
}) => {
	const {
		employeeUid: employeeId,
		overtimeType: overtimeTypeInitial,
		overtimeTitle,
		date,
		status,
		packageId: timePackageId,
		canEdit,
	} = overtimeRequest;

	const isEditable = (
		canEdit
		&& status === OvertimeStatus.SUBMITTED
		&& !isNull(timePackageId)
	);

	const isUserOvertimeRequestOwner = useSelector((state: RootState) => {
		return state.overtimeRequestDetails.isUserOvertimeRequestOwner;
	});

	const overtimeType = useSelector((state: RootState) => {
		return state.overtimeRequestDetails.overtimeType;
	});

	const dispatch = useDispatch();

	const isNewPermissionSchemeEnabled = useSelector((state: RootState) => {
		return state.applicationInfo.cpeBoard.overtime;
	});

	const [
		isOvertimeTypeChangeNotificationClosed,
		setIsOvertimeTypeChangeNotificationClosed,
	] = useState<boolean>(false);

	const useGetAvailableOvertimeTypeOptions = (
		isNewPermissionSchemeEnabled
			? overtimeApi.useGetAvailableOvertimeTypeOptionsV3Query
			: overtimeApi.useGetAvailableOvertimeTypeOptionsQuery
	);

	const {
		data: getAvailableOvertimeTypeOptionsResponse,
	} = useGetAvailableOvertimeTypeOptions(
		{
			employeeId,
			// Is non-nullable when value for `skip` field is `false`.
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			timePackageId: timePackageId!,
			date,
		},
		{
			skip: !isEditable,
		},
	);

	const availableOvertimeTypeOptions: OvertimeTypeOptions = (
		getAvailableOvertimeTypeOptionsResponse?.availableOvertimes
		?? []
	);

	const availableOvertimeTypesDataSource = useArrayDataSource<
		OvertimeTypeOption,
		OvertimeType,
		unknown
	>(
		{
			items: availableOvertimeTypeOptions,
			getId: (availableOvertimeOption) => {
				return availableOvertimeOption.type;
			},
		},
		[],
	);

	const shouldDisplayOvertimeTypeInput = (
		isEditable
		&& availableOvertimeTypeOptions.length > 1
	);

	const isOvertimeTypeChanged = overtimeType !== overtimeTypeInitial;

	return (
		<DataRowWithNotificationContainer>
			<DataRow
				title="Overtime type"
				dataNamePrefix="Overtime type"
				valueCellClassName={
					classNames({
						[styles.dataRowWithInput]: shouldDisplayOvertimeTypeInput,
						[styles.noVerticalPadding]: shouldDisplayOvertimeTypeInput,
					})
				}
			>
				{
					shouldDisplayOvertimeTypeInput
						? (
							<PickerInput
								dataSource={availableOvertimeTypesDataSource}
								value={overtimeType}
								onValueChange={(overtimeTypeNext) => {
									if (!isUndefined(overtimeTypeNext)) {
										dispatch(setOvertimeType(overtimeTypeNext));

										window.dataLayer.push({
											event_name: eventLocation,
											event_action: "ovttype_changed",
											event: "autoevent",
										});
									}
								}}
								selectionMode="single"
								getName={(availableOvertimeOption) => {
									return availableOvertimeOption?.title ?? "";
								}}
								disableClear={true}
								/*
									Default width goes beyond the input's width in the sidebar.
									That's why it was adjusted.
								*/
								minBodyWidth={200}
								renderNotFound={DataPickerNotFoundPlaceholder}
							/>
						)
						: (
							<DataCellValueText>
								{overtimeTitle}
							</DataCellValueText>
						)
				}
			</DataRow>

			{
				(
					isOvertimeTypeChanged
					&& !isOvertimeTypeChangeNotificationClosed
					&& !isUserOvertimeRequestOwner
				)
					? (
						<Alert
							icon={NotificationInfoIcon}
							color="sky"
							cx={styles.notification}
							onClose={() => {
								setIsOvertimeTypeChangeNotificationClosed(true);
							}}
						>
							<Text
								color="night800"
								fontSize="14"
								lineHeight="18"
								size="30"
							>
								Please inform the employee about the changed overtime request type.
							</Text>
						</Alert>
					)
					: null
			}
		</DataRowWithNotificationContainer>
	);
};

export {
	OvertimeTypeDataRow,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type TimePickerValue,
} from "@epam/uui";
import {
	getHours,
	getMinutes,
	parse,
} from "date-fns";

import {
	DEFAULT_TIME_DATE_FORMAT,
} from "models/dates-and-time/constants";
import {
	type PresenceTime,
} from "models/presences/types";

const getTimePickerValue = (time: PresenceTime): TimePickerValue => {
	const date = parse(
		time,
		DEFAULT_TIME_DATE_FORMAT,
		new Date(),
	);
	const hours = getHours(date);
	const minutes = getMinutes(date);

	return {
		hours,
		minutes,
	};
};

export {
	getTimePickerValue,
};

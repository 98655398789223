/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as PreviousPeriodIcon,
} from "@epam/assets/icons/common/navigation-chevron-left-24.svg";
import {
	ReactComponent as NextPeriodIcon,
} from "@epam/assets/icons/common/navigation-chevron-right-24.svg";
import {
	Button,
	FlexRow,
	RangeDatePicker,
} from "@epam/loveship";
import {
	add,
	differenceInDays,
	isEqual,
	isWithinInterval,
	parseISO,
	sub,
} from "date-fns";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import {
	nanoid,
} from "nanoid";
import {
	type FC,
	useEffect,
	useState,
} from "react";
import {
	useParams,
} from "react-router-dom";

import {
	ReactComponent as TodayIcon,
} from "icons/today-date-18.svg";
import {
	UUI_FULL_MONTH_NAME_DAY_FULL_YEAR_FORMAT,
} from "models/dates-and-time/constants";
import {
	type DateString,
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	toDateString,
} from "models/dates-and-time/utils/to-date-string";
import {
	type PagePeriodDatesParams,
} from "pages/types/common";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import {
	type PagePeriodPresetLocalStorageKey,
	Preset,
} from "./constants";
import {
	type PresetPeriodShift,
} from "./types";
import {
	getPeriodDatesByPreset,
} from "./utils/get-period-dates-by-preset";
import {
	getPresetByPeriodDates,
} from "./utils/get-preset-by-period-dates";
import {
	getPresetsForPeriodPicker,
} from "./utils/get-presets-for-period-picker";
import {
	setPagePeriodNavigatorPreset,
} from "./utils/set-page-period-navigator-preset";

import styles from "./page-period-navigator.module.css";

/*
	Maximum amount of days, that can be displayed in the period picker's dropdown
	(on both left and right sides).
*/
const MAX_AMOUNT_OF_DAYS_IN_TWO_MONTHS = 62;

const PRESETS_FOR_PERIOD_PICKER = getPresetsForPeriodPicker();

enum NavigationButtonType {
	PREVIOUS = "PREVIOUS",
	NEXT = "NEXT",
}

const DEFAULT_PRESET_PERIOD_SHIFT: PresetPeriodShift = 0;

interface PeriodDatesValue {
	from: DateString | null;
	to: DateString | null;
}

interface PagePeriodNavigatorProps {
	localStorageKey: PagePeriodPresetLocalStorageKey;
	onDatesChange: (periodDates: WithPeriodDates) => void;
}

const PagePeriodNavigator: FC<PagePeriodNavigatorProps> = ({
	localStorageKey,
	onDatesChange,
}) => {
	const {
		fromDate: periodStartDateFromUrl,
		toDate: periodEndDateFromUrl,
	} = useParams<PagePeriodDatesParams>();

	const [
		periodDates,
		setPeriodDates,
	] = useState<PeriodDatesValue>({
		from: periodStartDateFromUrl,
		to: periodEndDateFromUrl,
	});

	useEffect(
		() => {
			setPeriodDates({
				from: periodStartDateFromUrl,
				to: periodEndDateFromUrl,
			});
		},
		[
			periodStartDateFromUrl,
			periodEndDateFromUrl,
		],
	);

	const [
		presetPeriodShift,
		setPresetPeriodShift,
	] = useState<PresetPeriodShift>(
		DEFAULT_PRESET_PERIOD_SHIFT,
	);

	const [
		key,
		setKey,
	] = useState(nanoid());

	const handleTodayButtonClick = (): void => {
		const {
			from: periodStartDateString,
			to: periodEndDateString,
		} = periodDates;

		if (
			!isNull(periodStartDateString)
			&& !isNull(periodEndDateString)
		) {
			let nextPeriodStartDateString: DateString = periodStartDateString;
			let nextPeriodEndDateString: DateString = periodEndDateString;

			const preset = getPresetByPeriodDates({
				periodStartDate: periodStartDateString,
				periodEndDate: periodEndDateString,
				presetPeriodShift,
			});

			if (!isUndefined(preset)) {
				const isTodayInSelectedDates = isWithinInterval(
					new Date(),
					{
						start: parseISO(periodStartDateString),
						end: parseISO(periodEndDateString),
					},
				);

				if (!isTodayInSelectedDates) {
					let presetForNextStartEndDates: Preset = preset;

					// Presets not listed below already include the current date.
					// TODO: refactor this logic to reduce complexity and nesting depth.
					// eslint-disable-next-line max-depth
					if (
						preset === Preset.PREVIOUS_MONTH
						|| preset === Preset.NEXT_MONTH
					) {
						presetForNextStartEndDates = Preset.CURRENT_MONTH;
					} else if (
						preset === Preset.PREVIOUS_WEEK
						|| preset === Preset.NEXT_WEEK
					) {
						presetForNextStartEndDates = Preset.CURRENT_WEEK;
					}

					const nextPeriodDates = getPeriodDatesByPreset({
						preset: presetForNextStartEndDates,
						presetPeriodShift: DEFAULT_PRESET_PERIOD_SHIFT,
					});

					nextPeriodStartDateString = nextPeriodDates.periodStartDate;

					nextPeriodEndDateString = nextPeriodDates.periodEndDate;

					setPresetPeriodShift(DEFAULT_PRESET_PERIOD_SHIFT);
				}
			// Custom period.
			} else {
				const periodStartDate = parseISO(periodStartDateString);
				const periodEndDate = parseISO(periodEndDateString);
				const durationInDays = differenceInDays(
					periodStartDate,
					periodEndDate,
				);
				const durationInDaysAbsoluteValue = Math.abs(durationInDays);

				const nextPeriodStartDate = new Date();
				const nextPeriodEndDate = add(
					nextPeriodStartDate,
					{
						days: durationInDaysAbsoluteValue,
					},
				);

				nextPeriodStartDateString = toDateString(nextPeriodStartDate);

				nextPeriodEndDateString = toDateString(nextPeriodEndDate);
			}

			onDatesChange({
				periodStartDate: nextPeriodStartDateString,
				periodEndDate: nextPeriodEndDateString,
			});
		}
	};

	const handlePeriodDatesChange = (nextPeriodDates: PeriodDatesValue): void => {
		const {
			from: nextPeriodStartDate,
			to: nextPeriodEndDate,
		} = nextPeriodDates;
		const {
			from: currentPeriodStartDate,
			to: currentPeriodEndDate,
		} = periodDates;

		const isEmptyNextPeriodStartDate = isNull(nextPeriodStartDate);
		const isEmptyNextPeriodEndDate = isNull(nextPeriodEndDate);

		if (
			!isEmptyNextPeriodStartDate
			&& !isEmptyNextPeriodEndDate
			&& !isNull(currentPeriodStartDate)
			&& !isNull(currentPeriodEndDate)
		) {
			const nextPeriodInDays = differenceInDays(
				parseISO(nextPeriodStartDate),
				parseISO(nextPeriodEndDate),
			);
			const nextPeriodInDaysAbsoluteValue = Math.abs(nextPeriodInDays);
			const normalizedPeriodInDays = nextPeriodInDaysAbsoluteValue + 1;

			if (normalizedPeriodInDays > MAX_AMOUNT_OF_DAYS_IN_TWO_MONTHS) {
				if (
					!isEqual(
						parseISO(nextPeriodStartDate),
						parseISO(currentPeriodStartDate),
					)
					&& isEqual(
						parseISO(nextPeriodEndDate),
						parseISO(currentPeriodEndDate),
					)
				) {
					/*
						Start date has been changed only.
						It is necessary to remove the end date.
					*/
					setPeriodDates({
						from: nextPeriodStartDate,
						to: null,
					});

					return;
				}

				if (
					!isEqual(
						parseISO(nextPeriodEndDate),
						parseISO(currentPeriodEndDate),
					)
					&& isEqual(
						parseISO(nextPeriodStartDate),
						parseISO(currentPeriodStartDate),
					)
				) {
					/*
						End date has been changed only.
						It is necessary to remove the start date.
					*/
					setPeriodDates({
						from: null,
						to: nextPeriodEndDate,
					});

					return;
				}
			}
		}

		if (
			!isEmptyNextPeriodStartDate
			&& !isEmptyNextPeriodEndDate
		) {
			const preset = getPresetByPeriodDates({
				periodStartDate: nextPeriodStartDate,
				periodEndDate: nextPeriodEndDate,
			});

			if (!isUndefined(preset)) {
				setPagePeriodNavigatorPreset({
					localStorageKey,
					preset,
				});

				setPresetPeriodShift(DEFAULT_PRESET_PERIOD_SHIFT);
			}

			/*
				To close the dropdown menu. Otherwise it is visible through the spinner
				(and even displayed over it), which is undesirable.
			*/
			setKey(nanoid());

			onDatesChange({
				periodStartDate: nextPeriodStartDate,
				periodEndDate: nextPeriodEndDate,
			});

			return;
		}

		setPeriodDates({
			from: nextPeriodStartDate,
			to: nextPeriodEndDate,
		});
	};

	const handleNavigationButtonClick = (
		navigationButtonType: NavigationButtonType,
	): () => void => {
		return (): void => {
			const {
				from: periodStartDateString,
				to: periodEndDateString,
			} = periodDates;

			if (
				!isNull(periodStartDateString)
				&& !isNull(periodEndDateString)
			) {
				let nextPeriodStartDateString: DateString;
				let nextPeriodEndDateString: DateString;

				const isPreviousPeriodButtonClicked = (
					navigationButtonType === NavigationButtonType.PREVIOUS
				);

				const preset = getPresetByPeriodDates({
					periodStartDate: periodStartDateString,
					periodEndDate: periodEndDateString,
					presetPeriodShift,
				});

				if (!isUndefined(preset)) {
					const nextPresetPeriodShift = (
						isPreviousPeriodButtonClicked
							? presetPeriodShift - 1
							: presetPeriodShift + 1
					);

					const presetsForDatePicker = getPresetsForPeriodPicker({
						presetPeriodShift: nextPresetPeriodShift,
					});
					const {
						getRange,
					} = presetsForDatePicker[preset];

					const {
						from,
						to,
					} = getRange();

					nextPeriodStartDateString = from;

					nextPeriodEndDateString = to;

					setPresetPeriodShift(nextPresetPeriodShift);
				// Custom period.
				} else {
					const periodStartDate = parseISO(periodStartDateString);
					const periodEndDate = parseISO(periodEndDateString);
					const durationInDays = differenceInDays(
						periodStartDate,
						periodEndDate,
					);
					const durationInDaysAbsoluteValue = Math.abs(durationInDays);
					const adjustedDurationInDaysAbsoluteValue = (
						durationInDaysAbsoluteValue + 1
					);
					const operation = (
						isPreviousPeriodButtonClicked
							? sub
							: add
					);
					const nextPeriodStartDate = operation(
						periodStartDate,
						{
							days: adjustedDurationInDaysAbsoluteValue,
						},
					);
					const nextPeriodEndDate = operation(
						periodEndDate,
						{
							days: adjustedDurationInDaysAbsoluteValue,
						},
					);

					nextPeriodStartDateString = toDateString(nextPeriodStartDate);

					nextPeriodEndDateString = toDateString(nextPeriodEndDate);
				}

				onDatesChange({
					periodStartDate: nextPeriodStartDateString,
					periodEndDate: nextPeriodEndDateString,
				});
			}
		};
	};

	return (
		<FlexRow
			spacing={null}
			columnGap={9}
		>
			<FlexRow
				spacing={null}
			>
				<Button
					caption="Today"
					icon={TodayIcon}
					color="gray"
					fill="light"
					cx={styles.todayButton}
					rawProps={{
						"data-name": toDataAttribute("Page period navigator today button"),
					}}
					onClick={handleTodayButtonClick}
				/>
				<Button
					icon={PreviousPeriodIcon}
					color="gray"
					fill="light"
					cx={styles.navigationButton}
					rawProps={{
						"data-name": toDataAttribute("Page period navigator previous period button"),
					}}
					onClick={handleNavigationButtonClick(NavigationButtonType.PREVIOUS)}
				/>
				<Button
					icon={NextPeriodIcon}
					color="gray"
					fill="light"
					cx={styles.navigationButton}
					rawProps={{
						"data-name": toDataAttribute("Page period navigator next period button"),
					}}
					onClick={handleNavigationButtonClick(NavigationButtonType.NEXT)}
				/>
			</FlexRow>

			<div className={styles.pagePeriodNavigatorContainer}>
				<RangeDatePicker
					key={key}
					value={periodDates}
					onValueChange={handlePeriodDatesChange}
					presets={PRESETS_FOR_PERIOD_PICKER}
					disableClear={true}
					format={UUI_FULL_MONTH_NAME_DAY_FULL_YEAR_FORMAT}
				/>
			</div>
		</FlexRow>
	);
};

export {
	PagePeriodNavigator,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as TMSLogIcon,
} from "@epam/assets/icons/common/action-history-24.svg";
import {
	type FC,
} from "react";

import {
	type ProjectId,
} from "models/projects/types";
import {
	generateProjectTMSLogPageUrl,
} from "pages/utils/generate-project-tms-log-page-url";

import {
	ExternalLinkHint,
} from "../external-link-hint/external-link-hint";
import {
	ExternalLink,
} from "../external-link/external-link";

interface ExternalLinkTMSLogProps {
	projectId: ProjectId;
}

const ExternalLinkTMSLog: FC<ExternalLinkTMSLogProps> = ({
	projectId,
}) => {
	return (
		<ExternalLinkHint content="TMS Log">
			<ExternalLink
				color="night600"
				icon={TMSLogIcon}
				href={
					generateProjectTMSLogPageUrl({
						projectId,
					})
				}
				target="_blank"
			/>
		</ExternalLinkHint>
	);
};

export {
	ExternalLinkTMSLog,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isNull from "lodash/isNull";

import {
	PeriodPickerField,
	type PeriodPickerProps,
	type PeriodPickerValidationErrors,
} from "pages/components/period-picker/period-picker";

type PeriodToUnlock = PeriodPickerProps["value"];

const getReportPeriodErrors = (
	periodToUnlock: PeriodToUnlock,
): PeriodPickerValidationErrors => {
	const {
		from: reportFromDate,
		to: reportToDate,
	} = periodToUnlock;
	const errors: PeriodPickerValidationErrors = [];

	if (isNull(reportFromDate)) {
		errors.push({
			field: PeriodPickerField.FROM,
			message: "Date is required",
		});
	}

	if (isNull(reportToDate)) {
		errors.push({
			field: PeriodPickerField.TO,
			message: "Date is required",
		});
	}

	return errors;
};

export {
	getReportPeriodErrors,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Button,
	FlexRow,
	FlexSpacer,
	ModalBlocker,
	ModalFooter,
	ModalHeader,
	ModalWindow,
	Text,
} from "@epam/loveship";
import {
	type IModal,
} from "@epam/uui-core";
import {
	type FC,
} from "react";

import {
	toDataAttribute,
} from "utils/to-data-attribute";

import styles from "./discard-changes-modal-window.module.css";

type DiscardChangesModalWindowProps = IModal<undefined>;

const DiscardChangesModalWindow: FC<
	DiscardChangesModalWindowProps
> = (modalWindowProps) => {
	const {
		abort,
		success,
	} = modalWindowProps;

	return (
		<ModalBlocker
			{...modalWindowProps}
			disableCloseByEsc={true}
			disallowClickOutside={true}
		>
			<ModalWindow width="420">
				<ModalHeader
					title="Discard changes?"
					rawProps={{
						"data-name": toDataAttribute("Modal window header"),
					}}
					onClose={abort}
				/>
				<FlexRow
					cx={styles.content}
					padding="24"
				>
					<Text
						color="night800"
						size="24"
						fontSize="14"
						lineHeight="24"
					>
						You haven’t saved changes you made.
					</Text>
				</FlexRow>
				<ModalFooter
					rawProps={{
						"data-name": toDataAttribute("Modal window footer"),
					}}
				>
					<Button
						caption="Discard changes"
						color="fire"
						fill="none"
						onClick={success}
					/>
					<FlexSpacer/>
					<Button
						caption="Back to editing"
						color="grass"
						onClick={abort}
					/>
				</ModalFooter>
			</ModalWindow>
		</ModalBlocker>
	);
};

export {
	DiscardChangesModalWindow,
};

/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type FC,
} from "react";

import {
	ReactComponent as TeamsIcon,
} from "icons/external-link-teams-24.svg";
import {
	type EmployeeEmail,
} from "models/employees/types";

import {
	ExternalLinkHint,
} from "../external-link-hint/external-link-hint";
import {
	ExternalLink,
} from "../external-link/external-link";

interface ExternalLinkTeamsProps {
	employeeEmail: EmployeeEmail;
}

const ExternalLinkTeams: FC<ExternalLinkTeamsProps> = ({
	employeeEmail,
}) => {
	return (
		<ExternalLinkHint content="Chat in MS Teams">
			<ExternalLink
				icon={TeamsIcon}
				href={`https://teams.microsoft.com/l/chat/0/0?users=${employeeEmail}`}
				target="_blank"
			/>
		</ExternalLinkHint>
	);
};

export {
	ExternalLinkTeams,
};

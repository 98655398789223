/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Text,
} from "@epam/loveship";
import classNames from "classnames";
import {
	type FC,
} from "react";

import {
	type WithChildren,
} from "types/common";

import commonStyles from "./common.module.css";
import styles from "./level-name.module.css";

const LevelName: FC<WithChildren> = ({
	children,
}) => {
	return (
		<Text
			lineHeight="24"
			cx={
				classNames(
					commonStyles.text,
					styles.levelName,
				)
			}
		>
			{children}
		</Text>
	);
};

export {
	LevelName,
};

/*
* Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
* property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
* property law. Dissemination of this information or reproduction of this material is strictly forbidden,
* unless prior written permission is obtained from EPAM Systems, Inc
*/
import {
	type TimePickerValue,
} from "@epam/uui";

const MAX_DIGITS_TO_FILL = 2;

const getPresenceTimeString = (
	timePickerValue: TimePickerValue,
): string => {
	const {
		hours,
		minutes,
	} = timePickerValue;

	const stringifiedHours = String(hours).padStart(MAX_DIGITS_TO_FILL, "0");
	const stringifiedMinutes = String(minutes).padStart(MAX_DIGITS_TO_FILL, "0");

	return `${stringifiedHours}:${stringifiedMinutes}:00`;
};

export {
	getPresenceTimeString,
};

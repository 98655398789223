/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import {
	type ComponentProps,
	type FC,
} from "react";

import {
	DropdownOpenTrigger,
} from "./constants";
import {
	Menu,
} from "./menu";

import styles from "./sub-menu.module.css";

type SubMenuProps = ComponentProps<typeof Menu>;

const SubMenu: FC<SubMenuProps> = ({
	placement = "right-start",
	openTrigger = DropdownOpenTrigger.HOVER,
	cx: className,
	...otherProps
}) => {
	return (
		<Menu
			openTrigger={openTrigger}
			placement={placement}
			cx={
				classNames(
					styles.subMenu,
					/*
						The padding compensation described below is necessary to display
						options on the same level/height as the sub-menu option itself.
						Otherwise, the options would be displayed lower/higher by the
						padding value.
					*/
					{
						[styles.compensateTopPadding]: (
							placement === "right-start"
							|| placement === "left-start"
							|| placement === "auto-start"
						),
						[styles.compensateBottomPadding]: (
							placement === "right-end"
							|| placement === "left-end"
							|| placement === "auto-end"
						),
					},
					className,
				)
			}
			{...otherProps}
		/>
	);
};

export {
	SubMenu,
};

/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as moreMarkupsIcon,
} from "@epam/assets/icons/common/navigation-more_horiz-12.svg";
import {
	IconContainer,
	Panel,
} from "@epam/loveship";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import take from "lodash/take";
import {
	type FC,
} from "react";

import {
	type Duration,
} from "models/dates-and-time/types";
import {
	type StatusColor,
} from "models/project-position-markups/types";
import {
	type MarkupsStatistics,
} from "pages/types/common";
import {
	type WithClassName,
	type WithStyle,
} from "types/common";

import styles from "./markups-by-periods.module.css";

const DEFAULT_MAX_SIZE_MARKUPS = 7;

interface MarkupsPartType {
	color: StatusColor;
	width: number;
	isNonMarked?: boolean;
}

const MarkupsPart: FC<MarkupsPartType> = ({
	color,
	width,
	isNonMarked = false,
}) => {
	return (
		<div
			className={styles.markupWrapper}
		>
			<div
				style={{
					backgroundColor: color,
					width: `${width}%`,
				}}
				className={
					classNames(
						styles.markup,
						{
							[styles.nonMarkedMarkup]: isNonMarked,
						},
					)
				}
			/>
		</div>
	);
};

interface MarkupsByPeriodsProps extends
	WithStyle,
	WithClassName,
	Pick<
		MarkupsStatistics,
		| "worklogsTotalDuration"
		| "nonMarkedWorklogsDuration"
		| "markupsWithDuration"
	> {
	maxSizeMarkups?: number;
}

const MarkupsByPeriods: FC<MarkupsByPeriodsProps> = ({
	worklogsTotalDuration,
	nonMarkedWorklogsDuration,
	markupsWithDuration,
	maxSizeMarkups = DEFAULT_MAX_SIZE_MARKUPS,
	className,
	style,
}) => {
	const hasNonMarkedWorklogs = nonMarkedWorklogsDuration > 0;
	let resizedMarkups = markupsWithDuration;
	let hasTooMuchMarkups = false;

	if (!isEmpty(markupsWithDuration)) {
		const markupsAmount = markupsWithDuration.length;

		if (
			(
				hasNonMarkedWorklogs
				&& markupsAmount > maxSizeMarkups - 1
			)
			|| (
				!hasNonMarkedWorklogs
				&& markupsAmount > maxSizeMarkups
			)
		) {
			hasTooMuchMarkups = true;
		}
	}

	if (hasTooMuchMarkups) {
		const newSize = (
			hasNonMarkedWorklogs
				// Reserve space for non-marked worklogs and "more markups" rows.
				// eslint-disable-next-line @typescript-eslint/no-magic-numbers
				? maxSizeMarkups - 2
				// Reserve space for "more markups" row.
				: maxSizeMarkups - 1
		);

		resizedMarkups = take(markupsWithDuration, newSize);
	}

	// Returns width in percents.
	const calculateWidth = (markupDuration: Duration): number => {
		const percentMultiplier = 100;

		return (markupDuration / worklogsTotalDuration) * percentMultiplier;
	};

	return (
		<Panel
			cx={classNames(
				styles.markupsWrapper,
				className,
			)}
			style={style}
		>
			{
				hasNonMarkedWorklogs
					? (
						<MarkupsPart
							color="transparent"
							width={calculateWidth(nonMarkedWorklogsDuration)}
							isNonMarked={true}
						/>
					)
					: null
			}
			{
				!isEmpty(resizedMarkups)
					? resizedMarkups.map(
						(markup) => {
							return (
								<MarkupsPart
									key={markup.id}
									color={markup.color}
									width={calculateWidth(markup.duration)}
								/>
							);
						},
					)
					: null
			}
			{
				hasTooMuchMarkups
					? (
						<div
							className={styles.moreMarkupsWrapper}
						>
							<IconContainer
								icon={moreMarkupsIcon}
								cx={styles.moreMarkups}
							/>
						</div>
					)
					: null
			}
		</Panel>
	);
};

export {
	MarkupsByPeriods,
};

/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	FlexCell,
	FlexRow,
	RangeDatePicker,
	Text,
} from "@epam/loveship";
import {
	type RangeDatePickerProps,
} from "@epam/uui";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import {
	type FC,
} from "react";

import {
	UUI_FULL_MONTH_NAME_DAY_FULL_YEAR_FORMAT,
} from "models/dates-and-time/constants";

import styles from "./period-picker.module.css";

enum PeriodPickerField {
	FROM = "from",
	TO = "to",
}

interface PeriodPickerValidationError {
	field: PeriodPickerField;
	message: string;
}

type PeriodPickerValidationErrors = PeriodPickerValidationError[];

const getFieldValidationErrorMessageElements = (
	errors: PeriodPickerValidationErrors,
): JSX.Element[] => {
	return errors.map((error) => {
		const {
			field,
			message,
		} = error;

		return (
			<Text
				key={`${field}: ${message}`}
				fontSize="12"
				lineHeight="18"
				cx={styles.errorMessage}
			>
				{message}
			</Text>
		);
	});
};

interface PeriodPickerCustomProps extends
	Pick<
		RangeDatePickerProps,
		| "value"
		| "onValueChange"
		| "getPlaceholder"
		| "disableClear"
	> {
	label?: string;
	filterDates?: RangeDatePickerProps["filter"];
	errors?: PeriodPickerValidationErrors;
}

const ERRORS_FALLBACK: PeriodPickerCustomProps["errors"] = [];

const PeriodPickerCustom: FC<PeriodPickerCustomProps> = ({
	label,
	value,
	onValueChange,
	getPlaceholder,
	disableClear,
	filterDates,
	errors = ERRORS_FALLBACK,
}) => {
	const hasErrors = !isEmpty(errors);

	const fromFieldErrors = errors.filter((error) => {
		return error.field === PeriodPickerField.FROM;
	});
	const toFieldErrors = errors.filter((error) => {
		return error.field === PeriodPickerField.TO;
	});

	return (
		<>
			{
				!isEmpty(label)
					? (
						<div className={styles.label}>
							{label}
						</div>
					)
					: null
			}

			<div
				className={
					classNames(
						styles.periodPicker,
						{
							[styles.hasErrors]: hasErrors,
							[styles.hasFromFieldError]: !isEmpty(fromFieldErrors),
							[styles.hasToFieldError]: !isEmpty(toFieldErrors),
						},
					)
				}
			>
				<RangeDatePicker
					value={value}
					onValueChange={onValueChange}
					isInvalid={hasErrors}
					format={UUI_FULL_MONTH_NAME_DAY_FULL_YEAR_FORMAT}
					getPlaceholder={getPlaceholder}
					filter={filterDates}
					disableClear={disableClear}
				/>
			</div>

			{
				hasErrors
					? (
						<FlexRow
							alignItems="top"
							cx={styles.errorMessagesSection}
						>
							<FlexCell grow={1}>
								{getFieldValidationErrorMessageElements(fromFieldErrors)}
							</FlexCell>
							<FlexCell grow={1}>
								{getFieldValidationErrorMessageElements(toFieldErrors)}
							</FlexCell>
						</FlexRow>
					)
					: null
			}
		</>
	);
};

export {
	PeriodPickerCustom as PeriodPicker,
	PeriodPickerField,
	// TODO: Replace usage with `ComponentProps<Component>`.
	type PeriodPickerCustomProps as PeriodPickerProps,
	type PeriodPickerValidationErrors,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Button,
	FlexRow,
	FlexSpacer,
	LinkButton,
	ModalBlocker,
	ModalFooter,
	ModalHeader,
	ModalWindow,
	Panel,
	ScrollBars,
	Text,
} from "@epam/loveship";
import {
	type IModal,
} from "@epam/uui-core";
import classNames from "classnames";
import {
	parseISO,
} from "date-fns";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import {
	type FC,
	useCallback,
	useEffect,
	useState,
} from "react";

import {
	displayErrorNotification,
	displayWarningNotification,
} from "components/common-components/notification/Notifications";
import {
	toFancyDate,
} from "models/dates-and-time/utils/to-fancy-date";
import {
	type EmployeeId,
} from "models/employees/types";
import {
	presencesApi,
} from "models/presences/api";
import {
	PresenceActivityType,
	PresenceStatus,
} from "models/presences/constants";
import {
	type Presence,
	type PresenceAvailableActivity,
	type PresenceSettingForDate,
	type PresenceToUpdate,
	type Presences,
	type PresencesToUpdate,
} from "models/presences/types";
import {
	hideSpinner,
	showSpinner,
} from "pages/components/spinner/store/reducer";
import {
	useDispatch,
} from "store";
import {
	useUserId,
} from "store/slices/application-info/selectors";
import {
	type ApiError,
} from "types/api";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import {
	ActivitiesListHeader,
} from "./components/activities-list-header/activities-list-header";
import {
	AddAvailableActivity,
} from "./components/add-available-activity/add-available-activity";
import {
	PresenceActivityRow,
} from "./components/presence-activity-row/presence-activity-row";
import {
	PresenceStatusBadge,
} from "./components/presence-status-badge/presence-status-badge";
import {
	TotalDurationRow,
} from "./components/total-duration-row/total-duration-row";
import {
	PRESENCE_DEFAULT_VALUE,
} from "./constants";
import {
	type EditablePresenceActivities,
	type EditablePresenceActivity,
	type EditablePresenceActivityId,
	type EditablePresenceActivityToChange,
} from "./types";
import {
	getEditablePresenceActivity,
} from "./utils/get-editable-presence-activity/get-editable-presence-activity";
import {
	getOnDutyActivitiesTotalDuration,
} from "./utils/get-on-duty-activities-total-duration/get-on-duty-activities-total-duration";
import {
	getPresenceToUpdate,
} from "./utils/get-presence-to-update/get-presence-to-update";
import {
	getOnDutyActivitiesValidationErrors,
	getWorkingActivitiesValidationErrors,
} from "./utils/get-validation-errors/get-validation-errors";
import {
	getWorkingActivitiesTotalDuration,
} from "./utils/get-working-activities-total-duration/get-working-activities-total-duration";
import {
	sortPresenceActivities,
} from "./utils/sort-presence-activities/sort-presence-activities";

import styles from "./presence-activities-modal-window.module.css";

const GA_EVENT_LOCATION = "modal";

type PresenceActivitiesModalWindowResult = undefined;

interface PresenceActivitiesModalWindowProps extends IModal<
	PresenceActivitiesModalWindowResult
> {
	date: Date;
	employeeId: EmployeeId;
	// Reference presence. Either clicked one or the one the bulk edit selection started with.
	presence: Presence | undefined;
	presenceSetting: PresenceSettingForDate;
	// Presences for bulk edit.
	selectedPresences?: Presences;
	// Set to `false` in case `onSuccess` shows the spinner itself before the hiding is performed in this component.
	shouldHideSpinnerOnSuccess?: boolean;
	onSuccess: () => void;
}

const SELECTED_PRESENCES_FALLBACK: PresenceActivitiesModalWindowProps["selectedPresences"] = [];

const PresenceActivitiesModalWindow: FC<PresenceActivitiesModalWindowProps> = ({
	date: presenceDate,
	employeeId,
	presence = PRESENCE_DEFAULT_VALUE,
	presenceSetting,
	onSuccess,
	shouldHideSpinnerOnSuccess = true,
	selectedPresences = SELECTED_PRESENCES_FALLBACK,
	...modalProps
}) => {
	const {
		status,
		details: presenceActivities,
	} = presence;
	const {
		presenceType,
		availableActivities,
	} = presenceSetting;
	const gaEventType = (
		!isEmpty(selectedPresences)
			? "bulk"
			: "single"
	);

	const isPresenceApproved = status === PresenceStatus.APPROVED;

	const [
		editablePresenceActivities,
		setEditablePresenceActivities,
	] = useState<EditablePresenceActivities>(() => {
		const sortedPresenceActivities = sortPresenceActivities(presenceActivities);

		return sortedPresenceActivities.map<EditablePresenceActivity>((presenceActivity) => {
			return getEditablePresenceActivity(presenceActivity);
		});
	});

	useEffect(
		() => {
			window.dataLayer.push({
				event_name: "presences",
				event_action: "start",
				event_type: gaEventType,
				event_location: GA_EVENT_LOCATION,
				event: "autoevent",
			});
		},
		[
			gaEventType,
		],
	);

	const addActivity = useCallback(
		(availableActivity: PresenceAvailableActivity): void => {
			const {
				id: presenceActivityConfigId,
				name,
				activityType,
			} = availableActivity;

			const newEditableActivity = getEditablePresenceActivity({
				id: null,
				name,
				startTime: undefined,
				endTime: undefined,
				type: activityType,
				presenceActivityConfigId,
			});

			setEditablePresenceActivities((currentEditableActivities) => {
				return currentEditableActivities.concat(newEditableActivity);
			});

			let actionName;

			if (activityType === PresenceActivityType.ACTIVITY) {
				actionName = "activity_added";
			}

			if (activityType === PresenceActivityType.REST) {
				actionName = "break_added";
			}

			window.dataLayer.push({
				event_name: "presences",
				event_action: actionName,
				event_type: gaEventType,
				event: "autoevent",
			});
		},
		[
			gaEventType,
		],
	);

	const updateActivity = useCallback(
		({
			id: activityToChangeId,
			startTime,
			endTime,
			name,
		}: EditablePresenceActivityToChange): void => {
			setEditablePresenceActivities((currentEditableActivities) => {
				const editableActivities = cloneDeep(
					currentEditableActivities,
				);
				const activityToChangeIndex = editableActivities.findIndex((editableActivity) => {
					return editableActivity.id === activityToChangeId;
				});

				if (activityToChangeIndex === -1) {
					return currentEditableActivities;
				}

				editableActivities[activityToChangeIndex].startTime = startTime;

				editableActivities[activityToChangeIndex].endTime = endTime;

				editableActivities[activityToChangeIndex].name = name;

				return editableActivities;
			});
		},
		[],
	);

	const deleteActivity = (activityToDeleteId: EditablePresenceActivityId): void => {
		setEditablePresenceActivities((currentEditableActivities) => {
			const activitiesWithoutActivityToDelete = currentEditableActivities.filter(
				(editableActivity) => {
					return editableActivity.id !== activityToDeleteId;
				},
			);

			return activitiesWithoutActivityToDelete;
		});
	};

	const userId = useUserId();

	const isPresenceOwner = userId === employeeId;

	const availableWorkingActivities = availableActivities.filter((availableActivity) => {
		return availableActivity.activityType === PresenceActivityType.ACTIVITY;
	});

	const availableBreakActivities = availableActivities.filter((availableActivity) => {
		return availableActivity.activityType === PresenceActivityType.REST;
	});

	const availableOnDutyActivities = availableActivities.filter((availableActivity) => {
		return availableActivity.activityType === PresenceActivityType.ON_DUTY;
	});

	const workingActivities = editablePresenceActivities.filter((editableActivity) => {
		const {
			activityType,
		} = editableActivity;

		return (
			activityType === PresenceActivityType.ACTIVITY
			|| activityType === PresenceActivityType.REST
		);
	});

	const onDutyActivities = editablePresenceActivities.filter((editableActivity) => {
		return editableActivity.activityType === PresenceActivityType.ON_DUTY;
	});

	const workingActivitiesValidationErrors = getWorkingActivitiesValidationErrors({
		activities: workingActivities,
		presenceType,
		presenceDate,
		currentDate: new Date(),
	});

	const onDutyActivitiesValidationErrors = getOnDutyActivitiesValidationErrors({
		activities: onDutyActivities,
		presenceDate,
		currentDate: new Date(),
	});

	const hasWorkingActivitiesValidationErrors = !isEmpty(workingActivitiesValidationErrors);

	const hasOnDutyActivitiesValidationErrors = !isEmpty(onDutyActivitiesValidationErrors);

	const hasValidationErrors = (
		hasWorkingActivitiesValidationErrors
		|| hasOnDutyActivitiesValidationErrors
	);

	const workingActivitiesTotalDuration = getWorkingActivitiesTotalDuration({
		activities: workingActivities,
		presenceType,
	});

	const onDutyActivitiesTotalDuration = getOnDutyActivitiesTotalDuration(onDutyActivities);

	const dispatch = useDispatch();

	const [
		updatePresences,
	] = presencesApi.useUpdatePresencesMutation();

	const updatePresenceWithStatus = async (newPresenceStatus?: PresenceStatus): Promise<void> => {
		try {
			const isPrefilledPresence = status === PresenceStatus.PREFILLED;

			if (
				!isPrefilledPresence
				|| !isEmpty(editablePresenceActivities)
			) {
				dispatch(showSpinner());

				let presencesToUpdate: PresencesToUpdate = [];

				if (!isEmpty(selectedPresences)) {
					presencesToUpdate = selectedPresences.map<PresenceToUpdate>((selectedPresence) => {
						const {
							timesheetDate: selectedPresenceDateString,
							status: selectedPresenceStatus,
						} = selectedPresence;

						const selectedPresenceDate = parseISO(selectedPresenceDateString);

						return getPresenceToUpdate({
							employeeId,
							currentPresenceStatus: selectedPresenceStatus,
							newPresenceStatus,
							presenceDate: selectedPresenceDate,
							activities: editablePresenceActivities,
						});
					});
				} else {
					const presenceToUpdate = getPresenceToUpdate({
						employeeId,
						currentPresenceStatus: status,
						newPresenceStatus,
						presenceDate,
						activities: editablePresenceActivities,
					});

					presencesToUpdate = [
						presenceToUpdate,
					];
				}

				const {
					validationWarnings,
				} = await updatePresences(presencesToUpdate)
					.unwrap();

				let actionName = "save";

				if (newPresenceStatus === PresenceStatus.APPROVED) {
					actionName = "approve";
				}

				if (newPresenceStatus === PresenceStatus.REJECTED) {
					actionName = "reject";
				}

				window.dataLayer.push({
					event_name: "presences",
					event_action: actionName,
					event_type: gaEventType,
					event_location: GA_EVENT_LOCATION,
					event: "autoevent",
				});

				validationWarnings.forEach((validationWarningMessage) => {
					window.dataLayer.push({
						event_name: "error_message",
						event_action: validationWarningMessage,
						event_type: "warning",
						event: "autoevent",
					});

					displayWarningNotification(validationWarningMessage, validationWarningMessage);
				});

				onSuccess();

				if (shouldHideSpinnerOnSuccess) {
					dispatch(hideSpinner());
				}
			}

			modalProps.success(undefined);
		} catch (error) {
			interface ErrorResponseData {
				message?: string;
			}

			const typedError = error as ApiError<ErrorResponseData>;

			let errorMessage = "Error saving work logs. Please repeat SAVE action.";

			const errorMessageFromServer = typedError.data.response?.data.message;

			if (!isUndefined(errorMessageFromServer)) {
				errorMessage = errorMessageFromServer;
			}

			dispatch(hideSpinner());

			displayErrorNotification(errorMessage);
		}
	};

	const isSaveButtonDisabled = (
		(
			// Disable the button if a new presence doesn't have activities.
			isUndefined(status)
			&& isEmpty(editablePresenceActivities)
		)
		|| hasValidationErrors
	);

	return (
		<ModalBlocker
			{...modalProps}
		>
			<ModalWindow
				width="600"
			>
				<ModalHeader
					borderBottom={true}
					rawProps={{
						"data-name": toDataAttribute("Modal window header"),
					}}
					onClose={() => {
						modalProps.abort();
					}}
				>
					<FlexRow
						alignItems="center"
						columnGap="12"
						rowGap="6"
						spacing={null}
						size="24"
						cx={styles.modalWindowHeaderContent}
					>
						<Text
							color="night800"
							fontSize="18"
							lineHeight="24"
							size="24"
							fontWeight="600"
						>
							Presences for {toFancyDate(presenceDate)}
						</Text>
						{
							isEmpty(selectedPresences)
								? (
									<PresenceStatusBadge
										status={status ?? PresenceStatus.SUBMITTED}
									/>
								)
								: null
						}
					</FlexRow>
				</ModalHeader>
				<Panel
					cx={styles.modalWindowBody}
				>
					<FlexRow
						spacing={null}
						cx={
							classNames(
								styles.infoTextRow,
								styles.noMinHeight,
							)
						}
					>
						<Text
							color="night800"
							fontSize="14"
							lineHeight="18"
							size="18"
						>
							{
								isPresenceOwner
									? (
										<>
											Specify your work schedule by adding activities and breaks.
											To learn more about presence reporting, go to
										</>
									)
									: (
										<>
											Verify the work schedule of your unit member.
											To learn more about presence management, go to
										</>
									)
							}
							{" "}
							<LinkButton
								caption="our KB"
								target="_blank"
								link={{
									pathname: isPresenceOwner
										? "https://kb.epam.com/display/EPMTIME/Employee#Employee-PresencereportingPresence"
										: "https://kb.epam.com/display/EPMTIME/Manager#Manager-Presencereporting",
								}}
								cx={
									classNames(
										styles.linkToInfoAboutPresences,
										styles.noMinHeight,
									)
								}
							/>.
						</Text>
					</FlexRow>

					<FlexRow
						spacing={null}
						columnGap={9}
						cx={
							classNames(
								styles.addActivityButtonsRow,
								styles.noMinHeight,
							)
						}
					>
						{
							!isEmpty(availableWorkingActivities)
								? (
									<AddAvailableActivity
										buttonTitle="Activity"
										existingActivities={
											editablePresenceActivities.filter((editableActivity) => {
												return editableActivity.activityType === PresenceActivityType.ACTIVITY;
											})
										}
										availableActivities={availableWorkingActivities}
										addActivity={addActivity}
										isDisabled={isPresenceApproved}
										dropdownBodyClassName={
											classNames(
												styles.addAvailableActivityDropdownBody,
												styles.addActivityDropdownBody,
											)
										}
									/>
								)
								: null
						}
						{
							!isEmpty(availableBreakActivities)
								? (
									<AddAvailableActivity
										buttonTitle="Break"
										existingActivities={
											editablePresenceActivities.filter((editableActivity) => {
												return editableActivity.activityType === PresenceActivityType.REST;
											})
										}
										availableActivities={availableBreakActivities}
										addActivity={addActivity}
										isDisabled={isPresenceApproved}
										dropdownBodyClassName={
											classNames(
												styles.addAvailableActivityDropdownBody,
												styles.addBreakDropdownBody,
											)
										}
									/>
								)
								: null
						}
						{
							!isEmpty(availableOnDutyActivities)
								? (
									<AddAvailableActivity
										buttonTitle="On-duty activity"
										existingActivities={onDutyActivities}
										availableActivities={availableOnDutyActivities}
										addActivity={addActivity}
										isDisabled={isPresenceApproved}
										dropdownBodyClassName={
											classNames(
												styles.addAvailableActivityDropdownBody,
												styles.addBreakDropdownBody,
											)
										}
									/>
								)
								: null
						}
					</FlexRow>

					{
						!isEmpty(editablePresenceActivities)
							? (
								<>
									<ActivitiesListHeader/>
									<ScrollBars>
										{
											!isEmpty(workingActivities)
												? (
													<div
														className={styles.activitiesListContainer}
													>
														{
															workingActivities.map((presenceActivity) => {
																const {
																	id: activityId,
																} = presenceActivity;

																const validationErrors = workingActivitiesValidationErrors.filter(
																	(validationError) => {
																		return validationError.activityId === activityId;
																	},
																);

																return (
																	<FlexRow
																		key={activityId}
																		spacing={null}
																		cx={styles.presenceActivityRowContainer}
																	>
																		<PresenceActivityRow
																			activity={presenceActivity}
																			presenceSetting={presenceSetting}
																			onChange={updateActivity}
																			onDelete={deleteActivity}
																			isEditDisabled={isPresenceApproved}
																			validationErrors={validationErrors}
																			className={styles.widthFull}
																		/>
																	</FlexRow>
																);
															})
														}

														<TotalDurationRow
															title="Total working hours"
															intoTooltipText="The total duration of all working activities without breaks"
															duration={workingActivitiesTotalDuration}
														/>
													</div>
												)
												: null
										}

										{
											!isEmpty(onDutyActivities)
												? (
													<div
														className={styles.activitiesListContainer}
													>
														{
															onDutyActivities.map((presenceActivity) => {
																const {
																	id: activityId,
																} = presenceActivity;

																const validationErrors = onDutyActivitiesValidationErrors.filter(
																	(validationError) => {
																		return validationError.activityId === activityId;
																	},
																);

																return (
																	<FlexRow
																		key={activityId}
																		spacing={null}
																		cx={styles.presenceActivityRowContainer}
																	>
																		<PresenceActivityRow
																			activity={presenceActivity}
																			presenceSetting={presenceSetting}
																			onChange={updateActivity}
																			onDelete={deleteActivity}
																			isEditDisabled={isPresenceApproved}
																			validationErrors={validationErrors}
																			className={styles.widthFull}
																		/>
																	</FlexRow>
																);
															})
														}

														<TotalDurationRow
															title="Total on-duty hours"
															intoTooltipText="The total duration of all on-duty activities only"
															duration={onDutyActivitiesTotalDuration}
														/>
													</div>
												)
												: null
										}
									</ScrollBars>
								</>
							)
							: null
					}
				</Panel>
				<ModalFooter
					columnGap="12"
					borderTop={true}
					rawProps={{
						"data-name": toDataAttribute("Modal window footer"),
					}}
				>
					<Button
						color="gray"
						fill="white"
						caption="Cancel"
						onClick={() => {
							modalProps.abort();
						}}
					/>

					<FlexSpacer/>

					{
						!isPresenceApproved
							? (
								<Button
									color="gray"
									fill="white"
									caption="Save"
									isDisabled={isSaveButtonDisabled}
									onClick={async () => {
										await updatePresenceWithStatus();
									}}
								/>
							)
							: null
					}
					{
						(
							!isPresenceOwner
							&& status !== PresenceStatus.REJECTED
						)
							? (
								<Button
									color="fire"
									fill="white"
									caption="Reject"
									isDisabled={isSaveButtonDisabled}
									onClick={async () => {
										await updatePresenceWithStatus(PresenceStatus.REJECTED);
									}}
								/>
							)
							: null
					}
					{
						(
							!isPresenceOwner
							&& !isPresenceApproved
						)
							? (
								<Button
									color="grass"
									caption="Approve"
									isDisabled={isSaveButtonDisabled}
									onClick={async () => {
										await updatePresenceWithStatus(PresenceStatus.APPROVED);
									}}
								/>
							)
							: null
					}
				</ModalFooter>
			</ModalWindow>
		</ModalBlocker>
	);
};

export {
	PresenceActivitiesModalWindow,
	type PresenceActivitiesModalWindowResult,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import {
	nanoid,
} from "nanoid";

import {
	type PresenceActivity,
} from "models/presences/types";

import {
	type EditablePresenceActivity,
} from "../../types";
import {
	getTimePickerValue,
} from "../get-time-picker-value/get-time-picker-value";

interface PresenceActivityWithOptionalStartTime extends Omit<PresenceActivity, "startTime"> {
	// Is omitted for new activities.
	startTime?: PresenceActivity["startTime"];
}

const getEditablePresenceActivity = (
	presenceActivity: PresenceActivityWithOptionalStartTime,
): EditablePresenceActivity => {
	const {
		id,
		name,
		startTime,
		endTime,
		type: activityType,
		presenceActivityConfigId,
	} = presenceActivity;

	return {
		id: isNull(id)
			// For prefilled presences and new activities.
			? nanoid()
			: id,
		activityId: id,
		name,
		initialName: name,
		startTime: !isUndefined(startTime)
			? getTimePickerValue(startTime)
			: null,
		endTime: !isUndefined(endTime)
			? getTimePickerValue(endTime)
			: null,
		activityType,
		presenceActivityConfigId,
	};
};

export {
	getEditablePresenceActivity,
};

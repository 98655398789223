/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	FlexRow,
} from "@epam/loveship";
import {
	type FC,
} from "react";

import {
	type WithChildren,
} from "types/common";

import styles from "./actions-panel.module.css";

const ActionsPanel: FC<WithChildren> = ({
	children,
}) => {
	return (
		<FlexRow
			cx={styles.actionsPanel}
			spacing={null}
		>
			{children}
		</FlexRow>
	);
};

export {
	ActionsPanel,
};

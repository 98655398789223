/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	FlexRow,
	Text,
} from "@epam/loveship";
import {
	type CSSProperties,
	type FC,
} from "react";

import {
	type Duration,
} from "models/dates-and-time/types";
import {
	type StatusColor,
} from "models/project-position-markups/types";
import {
	round,
} from "utilities/number";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import styles from "./markups-named-list-item.module.css";

interface MarkupMarkerStylesWithColor extends CSSProperties {
	"--color"?: string;
}

interface MarkupsNamedListItemProps {
	name: string;
	duration: Duration;
	color?: StatusColor;
}

const MarkupsNamedListItem: FC<MarkupsNamedListItemProps> = ({
	name,
	duration,
	color,
}) => {
	const markupMarkerStylesWithColor: MarkupMarkerStylesWithColor = {
		"--color": color,
	};

	return (
		<li
			className={styles.listItem}
			data-name={toDataAttribute("Markup row")}
		>
			<FlexRow
				spacing={null}
				columnGap="6"
				cx={styles.container}
			>
				<div
					className={styles.markupMarker}
					style={markupMarkerStylesWithColor}
					data-name={toDataAttribute("Markup marker")}
				/>
				<Text
					color="night800"
					fontSize="14"
					lineHeight="24"
					size="24"
					rawProps={{
						"data-name": toDataAttribute("Markup name"),
					}}
				>
					{name}
				</Text>
			</FlexRow>
			<Text
				color="night800"
				fontSize="14"
				lineHeight="24"
				size="24"
				fontWeight="600"
				rawProps={{
					"data-name": toDataAttribute("Markup duration"),
				}}
			>
				{round(duration)}
			</Text>
		</li>
	);
};

export {
	MarkupsNamedListItem,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import {
	type FC,
} from "react";

import {
	ReactComponent as StaffingIcon,
} from "icons/external-link-staffing-24.svg";
import {
	type ProjectPositionBillingTypeId,
	type ProjectPositionId,
	type ProjectPositionPrimaryRoleName,
} from "models/project-positions/types";
import {
	getBillingTypeById,
} from "models/project-positions/utils/get-billing-type-by-id";
import {
	Menu,
} from "pages/components/menu/menu";
import {
	MenuOption,
} from "pages/components/menu/option";

import {
	ExternalLinkHint,
} from "../external-link-hint/external-link-hint";
import {
	ExternalLinkStaffingPosition,
} from "../external-link-staffing-position/external-link-staffing-position";
import {
	ExternalLink,
} from "../external-link/external-link";

interface Position {
	id: ProjectPositionId;
	primaryRoleName: ProjectPositionPrimaryRoleName;
	billingTypeId: ProjectPositionBillingTypeId;
}

interface ExternalLinkStaffingPositionsProps {
	positions: Position[];
}

const ExternalLinkStaffingPositions: FC<ExternalLinkStaffingPositionsProps> = ({
	positions,
}) => {
	if (isEmpty(positions)) {
		return null;
	}

	if (positions.length === 1) {
		// If the condition above returns `true`, the first position exists.
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const position = positions.at(0)!;

		return (
			<ExternalLinkStaffingPosition
				positionId={position.id}
			/>
		);
	}

	return (
		<Menu
			renderOptions={() => {
				return positions.map(({
					id: positionId,
					primaryRoleName,
					billingTypeId,
				}) => {
					let caption = primaryRoleName;
					const billingType = getBillingTypeById(billingTypeId);

					if (!isUndefined(billingType)) {
						caption += `, ${billingType.name}`;
					}

					return (
						<MenuOption
							key={positionId}
							caption={caption}
							href={`https://staffing.epam.com/positions/${positionId}`}
							target="_blank"
						/>
					);
				});
			}}
			placement="bottom-end"
		>
			{(props) => {
				return (
					<ExternalLinkHint content="Staffing">
						{/*
							This `div` is necessary for the tooltip to bind its `ref` to,
							while the link has `ref` of the `Menu` component.
						*/}
						<div>
							<ExternalLink
								{...props}
								icon={StaffingIcon}
								target="_blank"
							/>
						</div>
					</ExternalLinkHint>
				);
			}}
		</Menu>
	);
};

export {
	ExternalLinkStaffingPositions,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type Duration,
} from "models/dates-and-time/types";

import {
	type EditablePresenceActivities,
} from "../../types";
import {
	getActivitiesDuration,
} from "../get-activities-duration/get-activities-duration";

const getOnDutyActivitiesTotalDuration = (activities: EditablePresenceActivities): Duration => {
	const onDutyActivitiesTotalDuration = getActivitiesDuration(activities);

	if (onDutyActivitiesTotalDuration < 0) {
		return 0;
	}

	return onDutyActivitiesTotalDuration;
};

export {
	getOnDutyActivitiesTotalDuration,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Panel,
} from "@epam/loveship";
import classNames from "classnames";
import {
	type ComponentProps,
	type FC,
} from "react";

import styles from "./column.module.css";

type ColumnProps = ComponentProps<typeof Panel>;

const Column: FC<ColumnProps> = ({
	cx,
	children,
	...otherProps
}) => {
	return (
		<Panel
			cx={
				classNames(
					styles.column,
					cx,
				)
			}
			{...otherProps}
		>
			{children}
		</Panel>
	);
};

export {
	Column,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as AddIcon,
} from "@epam/assets/icons/common/action-add-18.svg";
import {
	Button,
	Dropdown,
	DropdownMenuBody,
	DropdownMenuButton,
	ScrollBars,
	Text,
} from "@epam/loveship";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import {
	type ComponentProps,
	type FC,
	forwardRef,
	useMemo,
} from "react";

import {
	type PresenceAvailableActivities,
	type PresenceAvailableActivity,
	type PresenceAvailableActivityId,
} from "models/presences/types";

import {
	type EditablePresenceActivities,
} from "../../types";

import styles from "./add-available-activity.module.css";

type AvailableActivityLimit = PresenceAvailableActivity["limit"];

interface GetExistingActivitiesWithAvailableActivityIdParams {
	availableActivityId: PresenceAvailableActivityId;
	existingActivities: EditablePresenceActivities;
}

const getExistingActivitiesWithAvailableActivityIdLength = ({
	availableActivityId,
	existingActivities,
}: GetExistingActivitiesWithAvailableActivityIdParams): number => {
	const existingActivitiesWithAvailableActivityId = existingActivities.filter(({
		presenceActivityConfigId,
	}) => {
		return availableActivityId === presenceActivityConfigId;
	});

	return existingActivitiesWithAvailableActivityId.length;
};

interface GetIsAvailableActivityDisabledParams {
	existingActivitiesLength: number;
	availableActivityLimit: AvailableActivityLimit;
}

const getIsAvailableActivityDisabled = ({
	existingActivitiesLength,
	availableActivityLimit,
}: GetIsAvailableActivityDisabledParams): boolean => {
	if (isUndefined(availableActivityLimit)) {
		return false;
	}

	return existingActivitiesLength >= availableActivityLimit;
};

const AddButton = forwardRef<
	HTMLButtonElement,
	ComponentProps<typeof Button>
>((props, ref) => {
	return (
		<Button
			{...props}
			ref={ref}
			size="30"
			color="sky"
			fill="white"
			icon={AddIcon}
			iconPosition="left"
			cx={
				classNames(
					styles.button,
					props.cx,
				)
			}
		/>
	);
});

AddButton.displayName = "Button";

interface AddAvailableActivityProps {
	buttonTitle: string;
	existingActivities: EditablePresenceActivities;
	availableActivities: PresenceAvailableActivities;
	addActivity: (availableActivity: PresenceAvailableActivity) => void;
	isDisabled: boolean;
	buttonClassName?: string;
	dropdownBodyClassName?: string;
}

const AddAvailableActivity: FC<AddAvailableActivityProps> = ({
	buttonTitle,
	existingActivities,
	availableActivities,
	addActivity,
	isDisabled,
	buttonClassName,
	dropdownBodyClassName,
}) => {
	const isAddActivityDisabled = useMemo(
		() => {
			if (isDisabled) {
				return true;
			}

			const limitlessActivities = availableActivities.filter(({
				limit,
			}) => {
				return isUndefined(limit);
			});

			const limitedActivities = availableActivities.filter(({
				limit,
			}) => {
				return !isUndefined(limit);
			});

			if (!isEmpty(limitlessActivities)) {
				return false;
			}

			return limitedActivities.every((availableActivity) => {
				const {
					id,
					limit,
				} = availableActivity;

				const existingActivitiesWithAvailableActivityIdLength = getExistingActivitiesWithAvailableActivityIdLength({
					availableActivityId: id,
					existingActivities,
				});

				return getIsAvailableActivityDisabled({
					existingActivitiesLength: existingActivitiesWithAvailableActivityIdLength,
					availableActivityLimit: limit,
				});
			});
		},
		[
			isDisabled,
			existingActivities,
			availableActivities,
		],
	);

	const isOnlyAvailableActivity = availableActivities.length === 1;

	if (isOnlyAvailableActivity) {
		return (
			<AddButton
				caption={buttonTitle}
				isDisabled={isAddActivityDisabled}
				onClick={() => {
					const availableActivity = availableActivities.at(0);

					// `isOnlyAvailableActivity` flag ensures that `availableActivities` has one item.
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					addActivity(availableActivity!);
				}}
				cx={buttonClassName}
			/>
		);
	}

	// TODO: replace with `Menu` component.
	return (
		<Dropdown
			renderTarget={(targetProps) => {
				return (
					<AddButton
						{...targetProps}
						caption={buttonTitle}
						isDisabled={isAddActivityDisabled}
						cx={buttonClassName}
					/>
				);
			}}
			renderBody={(bodyProps) => {
				return (
					<DropdownMenuBody
						{...bodyProps}
						cx={
							classNames(
								styles.activitiesMenu,
								dropdownBodyClassName,
							)
						}
					>
						<ScrollBars cx={styles.scrollBars}>
							{
								availableActivities.map((availableActivity) => {
									const {
										id,
										name,
										limit: availableActivityLimit,
									} = availableActivity;

									const existingActivitiesWithAvailableActivityIdLength = (
										getExistingActivitiesWithAvailableActivityIdLength({
											availableActivityId: id,
											existingActivities,
										})
									);

									const isAvailableActivityDisabled = getIsAvailableActivityDisabled({
										existingActivitiesLength: existingActivitiesWithAvailableActivityIdLength,
										availableActivityLimit,
									});

									return (
										<DropdownMenuButton
											key={id}
											caption={(
												<>
													<Text
														color={
															isAvailableActivityDisabled
																? "night500"
																: "night800"
														}
														cx={
															classNames(
																styles.text,
															)
														}
													>
														{name}
													</Text>

													{
														!isUndefined(availableActivityLimit)
															? (
																<Text
																	color="night500"
																	cx={
																		classNames(
																			styles.text,
																			styles.limit,
																		)
																	}
																>
																	{existingActivitiesWithAvailableActivityIdLength}
																	/
																	{availableActivityLimit}
																</Text>
															)
															: null
													}
												</>
											)}
											isDisabled={isAvailableActivityDisabled}
											onClick={() => {
												addActivity(availableActivity);

												bodyProps.onClose?.();
											}}
											cx={
												classNames(
													styles.activityOption,
												)
											}
										/>
									);
								})
							}
						</ScrollBars>
					</DropdownMenuBody>
				);
			}}
		/>
	);
};

export {
	AddAvailableActivity,
};

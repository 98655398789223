/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type ReportingTypesState,
} from "./types";

enum ReportingType {
	PROJECT = "PROJECT",
	// This changes were made that raw data export will be useful for employees.
	// This will be changed in the future.
	LEAVE = "LEAVE_DETAILS",
	NON_PROJECT_HOURS = "NPA_DETAILS",
	PRESENCE = "PRESENCE_DETAILS",
	CALENDAR_NORM = "CALENDAR_NORM_DETAILS",
}

const DEFAULT_REPORTING_TYPES_STATE: ReportingTypesState = {
	[ReportingType.PROJECT]: true,
	[ReportingType.LEAVE]: true,
	[ReportingType.NON_PROJECT_HOURS]: true,
	[ReportingType.PRESENCE]: true,
	[ReportingType.CALENDAR_NORM]: true,
};

const REPORTING_TYPES_NAMES: Record<ReportingType, string> = {
	[ReportingType.PROJECT]: "Project hours",
	[ReportingType.NON_PROJECT_HOURS]: "Non-project hours",
	[ReportingType.LEAVE]: "Leave",
	[ReportingType.PRESENCE]: "Presence",
	[ReportingType.CALENDAR_NORM]: "Calendar norm",
};

export {
	ReportingType,
	DEFAULT_REPORTING_TYPES_STATE,
	REPORTING_TYPES_NAMES,
};

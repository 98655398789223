/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as HelpIcon,
} from "@epam/assets/icons/common/notification-help-outline-24.svg";
import {
	FlexSpacer,
	LinkButton,
	MainMenu,
	MainMenuAvatar,
	MainMenuButton,
	MainMenuDropdown,
	MainMenuIcon,
} from "@epam/loveship";
import {
	type AdaptiveItemProps,
} from "@epam/uui-components";
import {
	type FC,
	Suspense,
	lazy,
	useMemo,
} from "react";
import {
	useSelector,
} from "react-redux";

import {
	ReactComponent as Logo,
} from "images/header-logo.svg";
import {
	EMPLOYEES_BASE_URL,
	OVERTIME_BASE_URL,
	PROJECTS_BASE_URL,
	TIME_JOURNAL_BASE_URL,
} from "pages/constants/base-routes";
import {
	type RootState,
} from "store";
import {
	AvatarSize,
	getAvatarUrl,
} from "utils/avatar";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import {
	GlobalMenuButton,
} from "./global-menu-button";
import {
	ProblemsNotificationButton,
} from "./problems-notification-button/problems-notification-button";

import styles from "./header.module.css";

const HeaderBanner = lazy(async () => {
	return await import(
		/* webpackChunkName: "banner" */
		"./banner"
	);
});

const Header: FC = () => {
	const {
		pathname,
	} = window.location;

	const {
		userId,
		showTmsTool,
		showCalendarManagementTool,
		shouldShowBanner,
	} = useSelector((state: RootState) => {
		return state.applicationInfo;
	});

	const {
		canSeeProjectDashboard,
		canSeeEmployeesDashboard,
	} = useSelector((state: RootState) => {
		return state.applicationInfo.cpePermission;
	});

	const avatarUrl = useMemo(
		() => {
			return getAvatarUrl({
				employeeId: userId,
				size: AvatarSize.MEDIUM,
			});
		},
		[
			userId,
		],
	);

	const mainMenuItems = useMemo(
		() => {
			const items: AdaptiveItemProps[] = [
				{
					id: "logo",
					priority: 1,
					render: (item) => {
						return (
							<LinkButton
								key={item.id}
								link={{
									pathname: "/",
								}}
								icon={Logo}
								cx={styles.logo}
							/>
						);
					},
				},
				{
					id: "time-journal",
					priority: 1,
					render: (item) => {
						return (
							<MainMenuButton
								key={item.id}
								caption="Time Journal"
								link={{
									pathname: TIME_JOURNAL_BASE_URL,
								}}
								isLinkActive={pathname.includes(TIME_JOURNAL_BASE_URL)}
								rawProps={{
									"data-name": toDataAttribute("Time Journal menu button"),
								}}
							/>
						);
					},
				},
				{
					id: "overtime",
					priority: 1,
					render: (item) => {
						return (
							<MainMenuButton
								key={item.id}
								caption="Overtime"
								link={{
									pathname: OVERTIME_BASE_URL,
								}}
								isLinkActive={pathname.includes(OVERTIME_BASE_URL)}
								rawProps={{
									"data-name": toDataAttribute("Overtime menu button"),
								}}
							/>
						);
					},
				},
			];

			if (canSeeProjectDashboard) {
				items.push({
					id: "projects",
					priority: 1,
					render: (item) => {
						return (
							<MainMenuButton
								key={item.id}
								caption="Projects"
								link={{
									pathname: PROJECTS_BASE_URL,
								}}
								isLinkActive={pathname.includes(PROJECTS_BASE_URL)}
								rawProps={{
									"data-name": toDataAttribute("Projects menu button"),
								}}
							/>
						);
					},
				});
			}

			if (canSeeEmployeesDashboard) {
				items.push({
					id: "employees",
					priority: 1,
					render: (item) => {
						return (
							<MainMenuButton
								key={item.id}
								caption="Employees"
								link={{
									pathname: EMPLOYEES_BASE_URL,
								}}
								isLinkActive={pathname.includes(EMPLOYEES_BASE_URL)}
								rawProps={{
									"data-name": toDataAttribute("Employees menu button"),
								}}
							/>
						);
					},
				});
			}

			if (showTmsTool) {
				items.push({
					id: "tms-tool",
					priority: 1,
					render: (item) => {
						return (
							<MainMenuButton
								key={item.id}
								caption="TMS Tool"
								href="/tms"
								target="_blank"
								rawProps={{
									"data-name": toDataAttribute("TMS Tool menu button"),
								}}
							/>
						);
					},
				});
			}

			if (showCalendarManagementTool) {
				items.push({
					id: "calendars",
					priority: 1,
					render: (item) => {
						return (
							<MainMenuButton
								key={item.id}
								caption="Calendars"
								href="/calendars"
								target="_blank"
								rawProps={{
									"data-name": toDataAttribute("Calendars menu button"),
								}}
							/>
						);
					},
				});
			}

			items.push(
				{
					id: "analytics",
					priority: 1,
					render: (item) => {
						return (
							<MainMenuDropdown
								key={item.id}
								caption="Analytics"
								rawProps={{
									"data-name": toDataAttribute("Analytics menu button"),
								}}
							>
								<MainMenuButton
									caption="Calendar report"
									href="https://epa.ms/Calendar-report"
									target="_blank"
									rawProps={{
										"data-name": toDataAttribute("Calendar report button"),
									}}
								/>
								<MainMenuButton
									caption="Time gaps"
									href="https://epa.ms/Time-Gaps"
									target="_blank"
									rawProps={{
										"data-name": toDataAttribute("Time gaps button"),
									}}
								/>
								<MainMenuButton
									caption="Time compliance"
									href="https://epa.ms/time-compliance-report"
									target="_blank"
									rawProps={{
										"data-name": toDataAttribute("Time compliance button"),
									}}
								/>
							</MainMenuDropdown>
						);
					},
				},
				{
					id: "problems-notification",
					priority: 1,
					render: (item) => {
						return (
							<ProblemsNotificationButton
								key={item.id}
							/>
						);
					},
				},
				{
					id: "flex-spacer",
					priority: 1,
					render: (item) => {
						return (
							<FlexSpacer
								key={item.id}
							/>
						);
					},
				},
				{
					id: "banner",
					priority: 1,
					render: (item) => {
						return (
							<Suspense
								key={item.id}
								fallback={null}
							>
								{
									shouldShowBanner
										? <HeaderBanner className={styles.banner}/>
										: null
								}
							</Suspense>
						);
					},
				},
				{
					id: "help",
					priority: 1,
					render: (item) => {
						return (
							<MainMenuIcon
								key={item.id}
								icon={HelpIcon}
								onClick={() => {
									window.EpamServices?.ModalFramePublicApi.openInAppHelp();
								}}
								rawProps={{
									"data-name": toDataAttribute("Help button"),
								}}
							/>
						);
					},
				},
				{
					id: "user-avatar",
					priority: 1,
					render: (item) => {
						return (
							<MainMenuAvatar
								key={item.id}
								avatarUrl={avatarUrl}
							/>
						);
					},
				},
				{
					id: "global-menu",
					priority: 1,
					render: (item) => {
						return (
							<GlobalMenuButton
								key={item.id}
							/>
						);
					},
				},
			);

			return items;
		},
		[
			pathname,
			canSeeProjectDashboard,
			canSeeEmployeesDashboard,
			showTmsTool,
			showCalendarManagementTool,
			shouldShowBanner,
			avatarUrl,
		],
	);

	return (
		<MainMenu
			items={mainMenuItems}
			cx={styles.header}
		/>
	);
};

export {
	Header,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isNull from "lodash/isNull";

import {
	type Duration,
} from "models/dates-and-time/types";

const getOvertimeDurationValidationErrorMessage = (
	overtimeDuration: Duration | null,
): string | undefined => {
	if (isNull(overtimeDuration)) {
		return "The duration must be specified";
	}

	return undefined;
};

export {
	getOvertimeDurationValidationErrorMessage,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as NotificationInfoIcon,
} from "@epam/assets/icons/common/notification-info-fill-24.svg";
import {
	Alert,
	ErrorAlert,
	Text,
	TextInput,
} from "@epam/loveship";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import toNumber from "lodash/toNumber";
import {
	type FC,
	useState,
} from "react";
import {
	useSelector,
} from "react-redux";

import {
	type Duration,
} from "models/dates-and-time/types";
import {
	OvertimeStatus,
} from "models/overtime/constants";
import {
	type OvertimeRequest,
} from "models/overtime/types";
import {
	setOvertimeDuration,
} from "pages/components/overtime-request-details/store/reducer";
import {
	type GAEventLocation,
} from "pages/components/overtime-request-details/types";
import {
	getOvertimeDurationValidationErrorMessage,
	// TODO: make the name shorter.
	// eslint-disable-next-line max-len
} from "pages/components/overtime-request-details/utils/get-overtime-duration-validation-error-message/get-overtime-duration-validation-error-message";
import {
	type RootState,
	useDispatch,
} from "store";
import {
	NUMBER_REG_EXPS,
} from "utilities/number";
import {
	pluralizeWord,
} from "utils/formatting";

import {
	DataCellValueText,
} from "../../data-cell-value-text/data-cell-value-text";
import {
	DataRowWithNotificationContainer,
} from "../../data-row-with-notification-container/data-row-with-notification-container";
import {
	DataRow,
} from "../../data-row/data-row";

import styles from "./duration-data-row.module.css";

const getDurationString = (duration: Duration): string => {
	return String(duration);
};

// In hours.
const DURATION_MINIMUM_VALUE: Duration = 0;

// In hours.
const DURATION_MAXIMUM_VALUE: Duration = 24;

type DurationString = string;

interface DurationDataRowProps {
	overtimeRequest: OvertimeRequest;
	eventLocation: GAEventLocation;
}

const DurationDataRow: FC<DurationDataRowProps> = ({
	overtimeRequest,
	eventLocation,
}) => {
	const {
		duration: overtimeDurationInitial,
		status,
		canEdit,
	} = overtimeRequest;

	const isEditable = (
		canEdit
		&& status === OvertimeStatus.SUBMITTED
	);

	const isUserOvertimeRequestOwner = useSelector((state: RootState) => {
		return state.overtimeRequestDetails.isUserOvertimeRequestOwner;
	});

	const overtimeDuration = useSelector((state: RootState) => {
		return state.overtimeRequestDetails.overtimeDuration;
	});

	const dispatch = useDispatch();

	const [
		durationString,
		setDurationString,
	] = useState<DurationString>(getDurationString(overtimeDurationInitial));

	const [
		isOvertimeDurationChangeNotificationClosed,
		setIsOvertimeDurationChangeNotificationClosed,
	] = useState<boolean>(false);

	const isOvertimeDurationChanged = overtimeDuration !== overtimeDurationInitial;

	const overtimeDurationValidationErrorMessage = getOvertimeDurationValidationErrorMessage(
		overtimeDuration,
	);

	const hasErrors = !isUndefined(overtimeDurationValidationErrorMessage);

	const onOvertimeDurationStringChange = (
		overtimeDurationStringNext: DurationString | undefined,
	): void => {
		if (!isUndefined(overtimeDurationStringNext)) {
			const matchResult = NUMBER_REG_EXPS.POSSIBLE_FLOAT.exec(
				overtimeDurationStringNext,
			);

			if (isNull(matchResult)) {
				return;
			}

			const overtimeDurationNextWithDot = overtimeDurationStringNext.replace(
				/,/,
				".",
			);

			const overtimeDurationNumberNext = (
				isEmpty(overtimeDurationNextWithDot)
					? null
					: toNumber(overtimeDurationNextWithDot)
			);

			if (
				!isNull(overtimeDurationNumberNext)
				&& (
					overtimeDurationNumberNext < DURATION_MINIMUM_VALUE
					|| overtimeDurationNumberNext > DURATION_MAXIMUM_VALUE
				)
			) {
				return;
			}

			setDurationString(overtimeDurationNextWithDot);

			dispatch(setOvertimeDuration(overtimeDurationNumberNext));

			window.dataLayer.push({
				event_name: eventLocation,
				event_action: "duration_changed",
				event: "autoevent",
			});
		}
	};

	return (
		<DataRowWithNotificationContainer>
			<DataRow
				title="Duration"
				dataNamePrefix="Duration"
				valueCellClassName={
					classNames(
						styles.valueCell,
						{
							[styles.noVerticalPadding]: isEditable,
						},
					)
				}
			>
				{
					isEditable
						? (
							<>
								<TextInput
									value={durationString}
									onValueChange={onOvertimeDurationStringChange}
									onBlur={() => {
										if (durationString.endsWith(".")) {
											const durationStringWithoutDot = durationString.replace(
												".",
												"",
											);

											setDurationString(durationStringWithoutDot);
										}
									}}
									cx={styles.input}
								/>
								<DataCellValueText>
									hour(s)
								</DataCellValueText>
							</>
						)
						: (
							<DataCellValueText>
								{overtimeDuration}
								{" "}
								{
									pluralizeWord({
										count: overtimeDuration ?? 0,
										singularForm: "hour",
										pluralForm: "hours",
									})
								}
							</DataCellValueText>
						)
				}
			</DataRow>

			{
				hasErrors
					? (
						<ErrorAlert
							cx={styles.notification}
						>
							<Text
								color="night800"
								fontSize="14"
								lineHeight="18"
								size="30"
							>
								{overtimeDurationValidationErrorMessage}
							</Text>
						</ErrorAlert>
					)
					: null
			}

			{
				(
					isOvertimeDurationChanged
					&& !hasErrors
					&& !isOvertimeDurationChangeNotificationClosed
					&& !isUserOvertimeRequestOwner
				)
					? (
						<Alert
							icon={NotificationInfoIcon}
							color="sky"
							cx={styles.notification}
							onClose={() => {
								setIsOvertimeDurationChangeNotificationClosed(true);
							}}
						>
							<Text
								color="night800"
								fontSize="14"
								lineHeight="18"
								size="30"
							>
								Please inform the employee about the changed overtime request duration.
							</Text>
						</Alert>
					)
					: null
			}
		</DataRowWithNotificationContainer>
	);
};

export {
	DurationDataRow,
};

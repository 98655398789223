/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type ComponentProps,
	type FC,
} from "react";

import {
	InfoText,
} from "../info-text/info-text";

type InfoTextPrimaryProps = ComponentProps<typeof InfoText>;

const InfoTextPrimary: FC<InfoTextPrimaryProps> = ({
	children,
	...otherProps
}) => {
	return (
		<InfoText
			fontSize="16"
			lineHeight="24"
			{...otherProps}
		>
			{children}
		</InfoText>
	);
};

export {
	InfoTextPrimary as ExternalLinksTooltipInfoTextPrimary,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	GlobalMenu,
} from "@epam/loveship";
import {
	type FC,
	useEffect,
} from "react";

const GlobalMenuButton: FC = () => {
	useEffect(
		() => {
			const script = document.createElement("script");

			script.type = "text/javascript";

			script.src = window.location.host === "time.epam.com"
				? "https://menu.epam.com/scripts/menu.js?app=time"
				: "https://globalmenu-stage.epm-ppa.projects.epam.com/scripts/menu.js?app=time";

			script.async = true;

			document.body.appendChild(script);
		},
		[],
	);

	return (
		<GlobalMenu/>
	);
};

export {
	GlobalMenuButton,
};

/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Tooltip,
} from "@epam/loveship";
import {
	type ComponentProps,
	type FC,
} from "react";

type ExternalLinkHintProps = ComponentProps<typeof Tooltip>;

const ExternalLinkHint: FC<ExternalLinkHintProps> = ({
	children,
	...otherProps
}) => {
	return (
		<Tooltip
			color="white"
			{...otherProps}
		>
			{children}
		</Tooltip>
	);
};

export {
	ExternalLinkHint,
};

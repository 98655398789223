/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as CloseDropdownButtonIcon,
} from "@epam/assets/icons/common/navigation-close-18.svg";
import {
	ReactComponent as DropdownBodyIcon,
} from "@epam/assets/icons/common/notification-error-fill-18.svg";
import {
	Dropdown,
	DropdownContainer,
	IconButton,
	IconContainer,
	MainMenuButton,
	Text,
} from "@epam/loveship";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import {
	type ComponentProps,
	type FC,
	type MouseEventHandler,
	useState,
} from "react";

import {
	ReactComponent as ProblemsNotificationIcon,
} from "icons/problems-notification-24.svg";
import {
	problemsNotificationApi,
} from "models/problems-notification/api";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import {
	formatMessage,
} from "./utils/format-message/format-message";
import {
	getShouldProblemsNotificationBeDisplayedOnPage,
	// TODO: make the name shorter.
	// eslint-disable-next-line max-len
} from "./utils/get-should-problems-notification-be-displayed-on-page/get-should-problems-notification-be-displayed-on-page";

import styles from "./problems-notification-button.module.css";

const DROPDOWN_MODIFIERS: ComponentProps<typeof Dropdown>["modifiers"] = [
	{
		name: "offset",
		options: {
			offset: [
				0,
				// Arrow height.
				// eslint-disable-next-line @typescript-eslint/no-magic-numbers
				12,
			],
		},
	},
];

const onDropdownBodyClick: MouseEventHandler<HTMLDivElement> = (event) => {
	event.stopPropagation();
};

const ProblemsNotificationButton: FC = () => {
	const {
		data: problemsNotificationInfo,
	} = problemsNotificationApi.useGetProblemsNotificationInfoQuery(undefined);

	const [
		isDropdownOpened,
		setIsDropdownOpened,
	] = useState<boolean>(true);

	if (isUndefined(problemsNotificationInfo)) {
		return null;
	}

	const {
		message,
		pages,
	} = problemsNotificationInfo;

	const shouldProblemsNotificationBeDisplayedOnPage = (
		getShouldProblemsNotificationBeDisplayedOnPage(pages)
	);

	if (
		!shouldProblemsNotificationBeDisplayedOnPage
		|| isEmpty(message)
	) {
		return null;
	}

	return (
		<Dropdown
			value={isDropdownOpened}
			onValueChange={setIsDropdownOpened}
			openOnClick={true}
			openOnHover={false}
			placement="bottom"
			modifiers={DROPDOWN_MODIFIERS}
			renderTarget={(targetProps) => {
				return (
					<MainMenuButton
						{...targetProps}
						isDropdown={false}
						icon={ProblemsNotificationIcon}
						rawProps={{
							"data-name": toDataAttribute("Toggle problems notification button"),
						}}
						cx={styles.button}
					/>
				);
			}}
			renderBody={(bodyProps) => {
				return (
					/*
						For some reason, event of clicking inside `DropdownContainer` is
						propagated to the outer scope. The component doesn't provide `onClick`
						hander, so we need to wrap it in a `div` element to listen to the event
						and stop its propagation.
					*/
					<div onClick={onDropdownBodyClick}>
						<DropdownContainer
							{...bodyProps}
							showArrow={true}
							rawProps={{
								"data-name": toDataAttribute("Problems notification dropdown body"),
							}}
							cx={styles.dropdownBody}
						>
							<IconContainer
								icon={DropdownBodyIcon}
								cx={styles.dropdownBodyIcon}
							/>
							<Text
								color="night50"
								fontSize="12"
								lineHeight="18"
								size="18"
								cx={styles.message}
							>
								{formatMessage(message)}
							</Text>
							<IconButton
								icon={CloseDropdownButtonIcon}
								color="white"
								rawProps={{
									"data-name": toDataAttribute("Close button"),
								}}
								onClick={bodyProps.onClose}
								cx={styles.closeButton}
							/>
						</DropdownContainer>
					</div>
				);
			}}
		/>
	);
};

export {
	ProblemsNotificationButton,
};

/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import {
	type ComponentProps,
	type FC,
} from "react";

import {
	InfoText,
} from "../info-text/info-text";

import styles from "./info-text-secondary.module.css";

type InfoTextSecondaryProps = ComponentProps<typeof InfoText>;

const InfoTextSecondary: FC<InfoTextSecondaryProps> = ({
	cx,
	children,
	...otherProps
}) => {
	return (
		<InfoText
			fontSize="12"
			color="night500"
			cx={
				classNames(
					styles.text,
					cx,
				)
			}
			{...otherProps}
		>
			{children}
		</InfoText>
	);
};

export {
	InfoTextSecondary as ExternalLinksTooltipInfoTextSecondary,
};

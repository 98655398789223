/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import {
	type DetailedHTMLProps,
	type FC,
	type HTMLAttributes,
} from "react";

import {
	toDataAttribute,
} from "utils/to-data-attribute";

import styles from "./bulk-actions-panel.module.css";

type BulkActionsPanelProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

const BulkActionsPanel: FC<BulkActionsPanelProps> = ({
	children,
	className,
	...otherProps
}) => {
	return (
		<aside
			className={
				classNames(
					styles.panel,
					className,
				)
			}
			data-name={toDataAttribute("Bulk actions panel")}
			{...otherProps}
		>
			{children}
		</aside>
	);
};

export {
	BulkActionsPanel,
};

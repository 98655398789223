/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	add,
	areIntervalsOverlapping,
	isSameDay,
	isWithinInterval,
} from "date-fns";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import reject from "lodash/reject";

import {
	type Duration,
} from "models/dates-and-time/types";
import {
	PresenceActivityType,
	PresenceType,
} from "models/presences/constants";

import {
	ValidationErrorLevel,
} from "../../constants";
import {
	type EditablePresenceActivities,
	type EditablePresenceActivity,
	type ValidationErrors,
} from "../../types";
import {
	getTimePickerDuration,
} from "../get-time-picker-duration/get-time-picker-duration";

const getDateFromTimePickerDuration = (durationInMinutes: Duration): Date => {
	return add(
		new Date(),
		{
			minutes: durationInMinutes,
		},
	);
};

interface DefaultValidationParams {
	validationErrors: ValidationErrors;
	activity: EditablePresenceActivity;
}

const validateStartTime = ({
	validationErrors,
	activity,
}: DefaultValidationParams): void => {
	const {
		id: activityId,
		startTime,
	} = activity;

	if (!isNull(startTime)) {
		return;
	}

	validationErrors.push({
		activityId,
		level: ValidationErrorLevel.START_TIME,
		message: "The start time is required.",
	});
};

interface ValidateEndDateParams extends DefaultValidationParams {
	presenceDate: Date;
	currentDate: Date;
}

const validateEndTime = ({
	validationErrors,
	activity,
	presenceDate,
	currentDate,
}: ValidateEndDateParams): void => {
	const {
		id: activityId,
		endTime,
		activityType,
	} = activity;

	if (!isNull(endTime)) {
		return;
	}

	if (
		activityType === PresenceActivityType.ACTIVITY
		&& isSameDay(presenceDate, currentDate)
	) {
		return;
	}

	validationErrors.push({
		activityId,
		level: ValidationErrorLevel.END_TIME,
		message: "The end time is required.",
	});
};

const validateStartTimeMoreThanEndTime = ({
	validationErrors,
	activity,
}: DefaultValidationParams): void => {
	const {
		id: activityId,
		startTime,
		endTime,
	} = activity;
	const startTimeDurationInMinutes = getTimePickerDuration(startTime);
	const endTimeDurationInMinutes = getTimePickerDuration(endTime);

	if (
		isNull(startTime)
		|| isNull(endTime)
		|| startTimeDurationInMinutes <= endTimeDurationInMinutes
	) {
		return;
	}

	validationErrors.push({
		activityId,
		level: ValidationErrorLevel.START_TIME,
		message: "A start time must be earlier than an end time.",
	});

	validationErrors.push({
		activityId,
		level: ValidationErrorLevel.END_TIME,
	});
};

const validateStartTimeEqualsEndTime = ({
	validationErrors,
	activity,
}: DefaultValidationParams): void => {
	const {
		id: activityId,
		startTime,
		endTime,
	} = activity;
	const startTimeDurationInMinutes = getTimePickerDuration(startTime);
	const endTimeDurationInMinutes = getTimePickerDuration(endTime);

	if (
		isNull(startTime)
		|| isNull(endTime)
		|| startTimeDurationInMinutes !== endTimeDurationInMinutes
	) {
		return;
	}

	validationErrors.push({
		activityId,
		level: ValidationErrorLevel.START_TIME,
		message: "A start time must be earlier than an end time.",
	});

	validationErrors.push({
		activityId,
		level: ValidationErrorLevel.END_TIME,
	});
};

interface GetOtherActivitiesForOverlapValidationParams {
	activities: EditablePresenceActivities;
	activityToExclude: EditablePresenceActivity;
}

const getOtherActivitiesForOverlapValidation = ({
	activities,
	activityToExclude,
}: GetOtherActivitiesForOverlapValidationParams): EditablePresenceActivities => {
	const {
		id: activityId,
	} = activityToExclude;

	return reject(
		activities,
		(currentActivity) => {
			return currentActivity.id === activityId;
		},
	);
};

interface ValidateActivitiesOverlapParams extends DefaultValidationParams {
	otherActivities: EditablePresenceActivities;
}

const validateActivitiesOverlap = ({
	validationErrors,
	activity,
	otherActivities,
}: ValidateActivitiesOverlapParams): void => {
	const {
		id: activityId,
		startTime,
		endTime,
	} = activity;
	const startTimeDurationInMinutes = getTimePickerDuration(startTime);
	const endTimeDurationInMinutes = getTimePickerDuration(endTime);

	const hasOverlapWithOtherActivities = otherActivities.some(({
		startTime: otherActivityStartTime,
		endTime: otherActivityEndTime,
	}) => {
		const otherActivityStartTimeDurationInMinutes = getTimePickerDuration(
			otherActivityStartTime,
		);
		const otherActivityEndTimeDurationInMinutes = getTimePickerDuration(
			otherActivityEndTime,
		);

		if (
			startTimeDurationInMinutes > endTimeDurationInMinutes
			|| otherActivityStartTimeDurationInMinutes > otherActivityEndTimeDurationInMinutes
		) {
			return false;
		}

		if (
			!isNull(startTime)
			&& !isNull(otherActivityStartTime)
			&& !isNull(endTime)
			&& !isNull(otherActivityEndTime)
		) {
			return areIntervalsOverlapping(
				{
					start: getDateFromTimePickerDuration(
						startTimeDurationInMinutes,
					),
					end: getDateFromTimePickerDuration(
						endTimeDurationInMinutes,
					),
				},
				{
					start: getDateFromTimePickerDuration(
						otherActivityStartTimeDurationInMinutes,
					),
					end: getDateFromTimePickerDuration(
						otherActivityEndTimeDurationInMinutes,
					),
				},
			);
		}

		return false;
	});

	if (!hasOverlapWithOtherActivities) {
		return;
	}

	validationErrors.push({
		activityId,
		level: ValidationErrorLevel.START_TIME,
		message: "Activities cannot overlap.",
	});

	validationErrors.push({
		activityId,
		level: ValidationErrorLevel.END_TIME,
	});
};

interface ValidateRestWithinWorkingHoursParams extends DefaultValidationParams {
	activities: EditablePresenceActivities;
	presenceType: PresenceType;
}

const validateRestHoursWithinWorkingHours = ({
	validationErrors,
	activity,
	activities,
	presenceType,
}: ValidateRestWithinWorkingHoursParams): void => {
	const {
		id: activityId,
		startTime,
		endTime,
		activityType,
	} = activity;

	if (
		presenceType !== PresenceType.SINGLE
		|| activityType !== PresenceActivityType.REST
		|| isNull(startTime)
		|| isNull(endTime)
	) {
		return;
	}

	const workingActivity = activities.find(({
		activityType: nextActivityType,
	}) => {
		return nextActivityType === PresenceActivityType.ACTIVITY;
	});

	if (isUndefined(workingActivity)) {
		return;
	}

	const {
		startTime: workingActivityStartTime,
		endTime: workingActivityEndTime,
	} = workingActivity;

	if (
		isNull(workingActivityStartTime)
		|| isNull(workingActivityEndTime)
	) {
		return;
	}

	const restActivityStartTimeDurationInMinutes = getTimePickerDuration(
		startTime,
	);
	const restActivityEndTimeDurationInMinutes = getTimePickerDuration(
		endTime,
	);
	const workingActivityStartTimeDurationInMinutes = getTimePickerDuration(
		workingActivityStartTime,
	);
	const workingActivityEndTimeDurationInMinutes = getTimePickerDuration(
		workingActivityEndTime,
	);

	if (
		workingActivityStartTimeDurationInMinutes > workingActivityEndTimeDurationInMinutes
	) {
		return;
	}

	const isRestActivityStartTimeWithinWorkingHours = isWithinInterval(
		getDateFromTimePickerDuration(
			restActivityStartTimeDurationInMinutes,
		),
		{
			start: getDateFromTimePickerDuration(
				workingActivityStartTimeDurationInMinutes,
			),
			end: getDateFromTimePickerDuration(
				workingActivityEndTimeDurationInMinutes,
			),
		},
	);
	const isRestActivityEndTimeWithinWorkingHours = isWithinInterval(
		getDateFromTimePickerDuration(
			restActivityEndTimeDurationInMinutes,
		),
		{
			start: getDateFromTimePickerDuration(
				workingActivityStartTimeDurationInMinutes,
			),
			end: getDateFromTimePickerDuration(
				workingActivityEndTimeDurationInMinutes,
			),
		},
	);

	if (
		isRestActivityStartTimeWithinWorkingHours
		&& isRestActivityEndTimeWithinWorkingHours
	) {
		return;
	}

	validationErrors.push({
		activityId,
		level: ValidationErrorLevel.START_TIME,
		message: "A break must be within the working hours.",
	});

	validationErrors.push({
		activityId,
		level: ValidationErrorLevel.END_TIME,
	});
};

interface GetWorkingActivitiesValidationErrorsParams {
	activities: EditablePresenceActivities;
	presenceType: PresenceType;
	presenceDate: Date;
	currentDate: Date;
}

const getWorkingActivitiesValidationErrors = ({
	activities,
	presenceType,
	presenceDate,
	currentDate,
}: GetWorkingActivitiesValidationErrorsParams): ValidationErrors => {
	return activities.reduce<ValidationErrors>(
		(currentValidationErrors, activity) => {
			validateStartTime({
				validationErrors: currentValidationErrors,
				activity,
			});

			validateEndTime({
				validationErrors: currentValidationErrors,
				activity,
				presenceDate,
				currentDate,
			});

			validateStartTimeMoreThanEndTime({
				validationErrors: currentValidationErrors,
				activity,
			});

			validateStartTimeEqualsEndTime({
				validationErrors: currentValidationErrors,
				activity,
			});

			let otherActivities: EditablePresenceActivities = getOtherActivitiesForOverlapValidation({
				activities,
				activityToExclude: activity,
			});

			/*
				For single presence type, time overlap should be prohibited among the
				rest activities.
				For multiple presence type, time overlap should be prohibited among any
				activities (no additional filtering necessary in this case).
			*/
			if (presenceType === PresenceType.SINGLE) {
				otherActivities = otherActivities.filter((otherActivity) => {
					return otherActivity.activityType === PresenceActivityType.REST;
				});
			}

			if (
				presenceType !== PresenceType.SINGLE
				|| activity.activityType === PresenceActivityType.REST
			) {
				validateActivitiesOverlap({
					validationErrors: currentValidationErrors,
					activity,
					otherActivities,
				});
			}

			validateRestHoursWithinWorkingHours({
				validationErrors: currentValidationErrors,
				activity,
				activities,
				presenceType,
			});

			return currentValidationErrors;
		},
		[],
	);
};

interface GetOnDutyActivitiesValidationErrorsParams {
	activities: EditablePresenceActivities;
	presenceDate: Date;
	currentDate: Date;
}

const getOnDutyActivitiesValidationErrors = ({
	activities,
	presenceDate,
	currentDate,
}: GetOnDutyActivitiesValidationErrorsParams): ValidationErrors => {
	return activities.reduce<ValidationErrors>(
		(currentValidationErrors, activity) => {
			validateStartTime({
				validationErrors: currentValidationErrors,
				activity,
			});

			validateEndTime({
				validationErrors: currentValidationErrors,
				activity,
				presenceDate,
				currentDate,
			});

			validateStartTimeMoreThanEndTime({
				validationErrors: currentValidationErrors,
				activity,
			});

			validateStartTimeEqualsEndTime({
				validationErrors: currentValidationErrors,
				activity,
			});

			const otherActivities = getOtherActivitiesForOverlapValidation({
				activities,
				activityToExclude: activity,
			});

			validateActivitiesOverlap({
				validationErrors: currentValidationErrors,
				activity,
				otherActivities,
			});

			return currentValidationErrors;
		},
		[],
	);
};

export {
	getWorkingActivitiesValidationErrors,
	getOnDutyActivitiesValidationErrors,
};

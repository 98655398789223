/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import {
	type DetailedHTMLProps,
	type FC,
	type HTMLAttributes,
} from "react";

import tableComponentsStyles from "../../../table-components.module.css";
import styles from "./info-cell-title.module.css";

const InfoHeaderCellTitle: FC<
	DetailedHTMLProps<
		HTMLAttributes<HTMLSpanElement>,
		HTMLSpanElement
	>
> = ({
	children,
	className,
	...otherProps
}) => {
	return (
		<span
			className={
				classNames(
					tableComponentsStyles.text,
					styles.infoHeaderCellTitle,
					className,
				)
			}
			{...otherProps}
		>
			{children}
		</span>
	);
};

export {
	InfoHeaderCellTitle,
};

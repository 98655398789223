/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isNull from "lodash/isNull";
import {
	type FC,
} from "react";

import {
	type OvertimeRequest,
} from "models/overtime/types";

import {
	DataCellValueText,
} from "../../data-cell-value-text/data-cell-value-text";
import {
	DataRow,
} from "../../data-row/data-row";

import styles from "./package-data-row.module.css";

interface PackageDataRowProps {
	overtimeRequest: OvertimeRequest;
}

const PackageDataRow: FC<PackageDataRowProps> = ({
	overtimeRequest,
}) => {
	const {
		packageName,
		isBillablePackage,
		isOnDutyPackage,
	} = overtimeRequest;

	if (
		isNull(packageName)
		|| isNull(isBillablePackage)
		|| isNull(isOnDutyPackage)
	) {
		return null;
	}

	return (
		<DataRow
			title="Package"
			dataNamePrefix="Package"
			valueCellClassName={styles.valueCell}
		>
			<DataCellValueText>
				{packageName}
			</DataCellValueText>
			<DataCellValueText
				color="night600"
			>
				{
					isBillablePackage
						? "Billable"
						: "Non-billable"
				}
				{
					isOnDutyPackage
						? " • On-duty"
						: null
				}
			</DataCellValueText>
		</DataRow>
	);
};

export {
	PackageDataRow,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as InfoIcon,
} from "@epam/assets/icons/common/notification-info-outline-18.svg";
import {
	FlexRow,
	IconContainer,
	Text,
	Tooltip,
} from "@epam/loveship";
import {
	type FC,
} from "react";

import {
	type Duration,
} from "models/dates-and-time/types";
import {
	formatDuration,
} from "models/dates-and-time/utils/format-duration";

import styles from "./total-duration-row.module.css";

interface TotalDurationRowProps {
	title: string;
	intoTooltipText: string;
	duration: Duration;
}

const TotalDurationRow: FC<TotalDurationRowProps> = ({
	title,
	intoTooltipText,
	duration,
}) => {
	return (
		<FlexRow
			spacing={null}
			size="24"
			cx={styles.totalDurationRow}
		>
			<FlexRow
				spacing={null}
				alignItems="center"
				columnGap="6"
				size="24"
			>
				<Text
					color="night800"
					fontSize="14"
					lineHeight="18"
					size="18"
					fontWeight="600"
				>
					{title}
				</Text>
				<Tooltip
					content={intoTooltipText}
					color="white"
				>
					<IconContainer
						icon={InfoIcon}
						cx={styles.infoIcon}
					/>
				</Tooltip>
			</FlexRow>
			<Text
				color="night800"
				fontSize="14"
				lineHeight="18"
				size="18"
				fontWeight="600"
			>
				{formatDuration(duration)}h
			</Text>
		</FlexRow>
	);
};

export {
	TotalDurationRow,
};

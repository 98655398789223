/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	PickerInput,
} from "@epam/loveship";
import {
	useArrayDataSource,
} from "@epam/uui-core";
import {
	type FC,
} from "react";

import {
	DataPickerNotFoundPlaceholder,
} from "../data-picker-not-found-placeholder/data-picker-not-found-placeholder";

type ComboboxItemId = string;

interface ComboboxItem {
	id: ComboboxItemId;
	value: string;
}

type ComboboxItems = ComboboxItem[];

interface ComboboxProps {
	items: ComboboxItems;
	value: ComboboxItems;
	onValueChange: (items: ComboboxItems) => void;
	entityName: string;
}

const Combobox: FC<ComboboxProps> = ({
	items,
	value,
	onValueChange,
	entityName,
}) => {
	const dataSource = useArrayDataSource<
		ComboboxItem,
		ComboboxItemId,
		unknown
	>(
		{
			items,
		},
		[],
	);

	return (
		<PickerInput
			dataSource={dataSource}
			value={value}
			onValueChange={onValueChange}
			getName={(item) => {
				return item.value;
			}}
			entityName={entityName}
			selectionMode="multi"
			valueType="entity"
			maxItems={5}
			emptyValue={[]}
			// It allows to do dropdown width equal to input width.
			minBodyWidth={340}
			renderNotFound={DataPickerNotFoundPlaceholder}
		/>
	);
};

export {
	Combobox,
};

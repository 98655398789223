/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as ProjectLineSeparatorIcon,
} from "@epam/assets/icons/common/navigation-chevron-right-12.svg";
import {
	IconContainer,
} from "@epam/loveship";
import isNull from "lodash/isNull";
import {
	type FC,
	Fragment,
} from "react";

import {
	type OvertimeRequest,
} from "models/overtime/types";

import {
	DataCellValueText,
} from "../../data-cell-value-text/data-cell-value-text";
import {
	DataRow,
} from "../../data-row/data-row";

import styles from "./project-line-data-row.module.css";

const getProjectLineElements = (
	overtimeRequest: OvertimeRequest,
): JSX.Element[] => {
	const {
		customerName,
		projectName,
	} = overtimeRequest;

	const projectLine: string[] = [];

	if (!isNull(customerName)) {
		projectLine.push(customerName);
	}

	projectLine.push(projectName);

	return projectLine.map((projectLineItem, index) => {
		return (
			<Fragment key={projectLineItem}>
				<DataCellValueText>
					{projectLineItem}
				</DataCellValueText>
				{
					index < projectLine.length - 1
						? (
							<IconContainer
								icon={ProjectLineSeparatorIcon}
							/>
						)
						: null
				}
			</Fragment>
		);
	});
};

interface ProjectLineDataRowProps {
	overtimeRequest: OvertimeRequest;
}

const ProjectLineDataRow: FC<ProjectLineDataRowProps> = ({
	overtimeRequest,
}) => {
	return (
		<DataRow
			title="Project line"
			dataNamePrefix="Project line"
			valueCellClassName={styles.projectLineValueCell}
		>
			{getProjectLineElements(overtimeRequest)}
		</DataRow>
	);
};

export {
	ProjectLineDataRow,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import {
	isSunday,
} from "date-fns";
import {
	type DetailedHTMLProps,
	type HTMLAttributes,
	forwardRef,
} from "react";

import cellStyles from "../../cell.module.css";
import dataCellStyles from "../data-cell.module.css";
import styles from "./date-cell.module.css";

interface DateDataCellProps extends DetailedHTMLProps<
	HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
> {
	date: Date;
	isLastCell: boolean;
}

const DateDataCell = forwardRef<HTMLDivElement, DateDataCellProps>((
	{
		date,
		isLastCell,
		children,
		className,
		...otherProps
	},
	ref,
) => {
	return (
		<div
			ref={ref}
			className={
				classNames(
					cellStyles.cell,
					dataCellStyles.dataCell,
					styles.dateDataCell,
					{
						[cellStyles.withTransparentRightBorder]: isLastCell,
						[cellStyles.lastDayOfWeek]: isSunday(date),
					},
					className,
				)
			}
			{...otherProps}
		>
			{children}
		</div>
	);
});

DateDataCell.displayName = "DateDataCell";

export {
	DateDataCell,
};

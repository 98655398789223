/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	add,
	endOfMonth,
	endOfWeek,
	startOfMonth,
	startOfWeek,
	sub,
} from "date-fns";

import {
	type DateString,
} from "models/dates-and-time/types";
import {
	toDateString,
} from "models/dates-and-time/utils/to-date-string";

import {
	Preset,
} from "../constants";
import {
	type PresetPeriodShift,
} from "../types";

type StartOfWeekOptions = NonNullable<Parameters<typeof startOfWeek>[1]>;

type EndOfWeekOptions = NonNullable<Parameters<typeof endOfWeek>[1]>;

// Making sure that the config suits both functions.
interface WeekSettings extends
	StartOfWeekOptions,
	EndOfWeekOptions {}

const weekSettings: WeekSettings = {
	// Monday.
	weekStartsOn: 1,
};

const getStartOfWeek = (date: Date): Date => {
	return startOfWeek(
		date,
		weekSettings,
	);
};

const getEndOfWeek = (date: Date): Date => {
	return endOfWeek(
		date,
		weekSettings,
	);
};

interface AddSubtractWeeksMonthsParams {
	date: Date;
	amount: number;
}

const subtractWeeks = ({
	date,
	amount: weeks,
}: AddSubtractWeeksMonthsParams): Date => {
	return sub(
		date,
		{
			weeks,
		},
	);
};

const addWeeks = ({
	date,
	amount: weeks,
}: AddSubtractWeeksMonthsParams): Date => {
	return add(
		date,
		{
			weeks,
		},
	);
};

const subtractMonths = ({
	date,
	amount: months,
}: AddSubtractWeeksMonthsParams): Date => {
	return sub(
		date,
		{
			months,
		},
	);
};

const addMonths = ({
	date,
	amount: months,
}: AddSubtractWeeksMonthsParams): Date => {
	return add(
		date,
		{
			months,
		},
	);
};

interface AddSubtractOneWeekMonthWithShiftParams {
	date: Date;
	presetPeriodShift: number;
	shiftMultiplier?: number;
}

const subtractOneWeekWithShift = ({
	date,
	presetPeriodShift,
	shiftMultiplier = 1,
}: AddSubtractOneWeekMonthWithShiftParams): Date => {
	return subtractWeeks({
		date,
		amount: 1 - (shiftMultiplier * presetPeriodShift),
	});
};

const addOneWeekWithShift = ({
	date,
	presetPeriodShift,
	shiftMultiplier = 1,
}: AddSubtractOneWeekMonthWithShiftParams): Date => {
	return addWeeks({
		date,
		amount: 1 + (shiftMultiplier * presetPeriodShift),
	});
};

const subtractOneMonthWithShift = ({
	date,
	presetPeriodShift,
}: AddSubtractOneWeekMonthWithShiftParams): Date => {
	return subtractMonths({
		date,
		amount: 1 - presetPeriodShift,
	});
};

const addOneMonthWithShift = ({
	date,
	presetPeriodShift,
}: AddSubtractOneWeekMonthWithShiftParams): Date => {
	return addMonths({
		date,
		amount: 1 + presetPeriodShift,
	});
};

interface PresetForPickerValue {
	from: DateString;
	to: DateString;
	order?: number;
}

interface PresetForPicker {
	name: string;
	getRange: () => PresetForPickerValue;
}

interface GetPresetsForPeriodPicker {
	presetPeriodShift?: PresetPeriodShift;
}

const getPresetsForPeriodPicker = ({
	presetPeriodShift = 0,
}: GetPresetsForPeriodPicker = {}): Readonly<
	Record<Preset, PresetForPicker>
> => {
	const referenceDate = new Date();

	return {
		[Preset.PREVIOUS_MONTH]: {
			name: "Previous month",
			getRange: () => {
				let startDate = referenceDate;

				startDate = subtractOneMonthWithShift({
					date: startDate,
					presetPeriodShift,
				});

				startDate = startOfMonth(startDate);

				let endDate = referenceDate;

				endDate = subtractOneMonthWithShift({
					date: endDate,
					presetPeriodShift,
				});

				endDate = endOfMonth(endDate);

				return {
					from: toDateString(startDate),
					to: toDateString(endDate),
					order: 0,
				};
			},
		},
		[Preset.PREVIOUS_WEEK]: {
			name: "Previous week",
			getRange: () => {
				let startDate = referenceDate;

				startDate = subtractOneWeekWithShift({
					date: startDate,
					presetPeriodShift,
				});

				startDate = getStartOfWeek(startDate);

				let endDate = referenceDate;

				endDate = subtractOneWeekWithShift({
					date: endDate,
					presetPeriodShift,
				});

				endDate = getEndOfWeek(endDate);

				return {
					from: toDateString(startDate),
					to: toDateString(endDate),
					order: 1,
				};
			},
		},
		[Preset.PREVIOUS_WEEK_AND_CURRENT_WEEK]: {
			name: "Previous & current week",
			getRange: () => {
				const shiftMultiplier = 2;

				let startDate = referenceDate;

				startDate = subtractOneWeekWithShift({
					date: startDate,
					presetPeriodShift,
					shiftMultiplier,
				});

				startDate = getStartOfWeek(startDate);

				let endDate = referenceDate;

				endDate = addWeeks({
					date: endDate,
					amount: shiftMultiplier * presetPeriodShift,
				});

				endDate = getEndOfWeek(endDate);

				return {
					from: toDateString(startDate),
					to: toDateString(endDate),
					order: 2,
				};
			},
		},
		[Preset.CURRENT_WEEK]: {
			name: "Current week",
			getRange: () => {
				let startDate = referenceDate;

				startDate = addWeeks({
					date: startDate,
					amount: presetPeriodShift,
				});

				startDate = getStartOfWeek(startDate);

				let endDate = referenceDate;

				endDate = addWeeks({
					date: endDate,
					amount: presetPeriodShift,
				});

				endDate = getEndOfWeek(endDate);

				return {
					from: toDateString(startDate),
					to: toDateString(endDate),
					order: 3,
				};
			},
		},
		[Preset.CURRENT_WEEK_AND_NEXT_WEEK]: {
			name: "Current & next week",
			getRange: () => {
				const shiftMultiplier = 2;

				let startDate = referenceDate;

				startDate = addWeeks({
					date: startDate,
					amount: shiftMultiplier * presetPeriodShift,
				});

				startDate = getStartOfWeek(startDate);

				let endDate = referenceDate;

				endDate = addOneWeekWithShift({
					date: endDate,
					presetPeriodShift,
					shiftMultiplier,
				});

				endDate = getEndOfWeek(endDate);

				return {
					from: toDateString(startDate),
					to: toDateString(endDate),
					order: 4,
				};
			},
		},
		[Preset.CURRENT_MONTH]: {
			name: "Current month",
			getRange: () => {
				let startDate = referenceDate;

				startDate = addMonths({
					date: startDate,
					amount: presetPeriodShift,
				});

				startDate = startOfMonth(startDate);

				let endDate = referenceDate;

				endDate = addMonths({
					date: endDate,
					amount: presetPeriodShift,
				});

				endDate = endOfMonth(endDate);

				return {
					from: toDateString(startDate),
					to: toDateString(endDate),
					order: 5,
				};
			},
		},
		[Preset.NEXT_WEEK]: {
			name: "Next week",
			getRange: () => {
				let startDate = referenceDate;

				startDate = addOneWeekWithShift({
					date: startDate,
					presetPeriodShift,
				});

				startDate = getStartOfWeek(startDate);

				let endDate = referenceDate;

				endDate = addOneWeekWithShift({
					date: endDate,
					presetPeriodShift,
				});

				endDate = getEndOfWeek(endDate);

				return {
					from: toDateString(startDate),
					to: toDateString(endDate),
					order: 6,
				};
			},
		},
		[Preset.NEXT_MONTH]: {
			name: "Next month",
			getRange: () => {
				let startDate = referenceDate;

				startDate = addOneMonthWithShift({
					date: startDate,
					presetPeriodShift,
				});

				startDate = startOfMonth(startDate);

				let endDate = referenceDate;

				endDate = addOneMonthWithShift({
					date: endDate,
					presetPeriodShift,
				});

				endDate = endOfMonth(endDate);

				return {
					from: toDateString(startDate),
					to: toDateString(endDate),
					order: 7,
				};
			},
		},
	};
};

export {
	getPresetsForPeriodPicker,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as FilterIcon,
} from "@epam/assets/icons/common/content-filter_list-18.svg";
import {
	type ComponentProps,
	type FC,
} from "react";

import {
	PageActionsButton,
} from "../page-actions-button/page-actions-button";

type FiltersButtonProps = ComponentProps<typeof PageActionsButton>;

const FiltersButton: FC<FiltersButtonProps> = (props) => {
	return (
		<PageActionsButton
			icon={FilterIcon}
			caption="Filters"
			{...props}
		/>
	);
};

export {
	FiltersButton,
};

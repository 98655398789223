/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isEmpty from "lodash/isEmpty";

type FileType =
	| "image"
	| "word"
	| "excel"
	| "text"
	| "pdf"
	| "";

interface SupportedFileInfo {
	mimeType: string;
	type: FileType;
	extension: string;
}

type SupportedFilesInfo = SupportedFileInfo[];

const SUPPORTED_FILES_INFO: SupportedFilesInfo = [
	{
		mimeType: "image/png",
		type: "image",
		extension: "png",
	},
	{
		mimeType: "image/jpeg",
		type: "image",
		extension: "jpg",
	},
	{
		mimeType: "image/pjpeg",
		type: "image",
		extension: "jpg",
	},
	{
		mimeType: "image/bmp",
		type: "image",
		extension: "bmp",
	},
	{
		mimeType: "image/x-windows-bmp",
		type: "image",
		extension: "bmp",
	},
	{
		mimeType: "image/tiff",
		type: "image",
		extension: "tiff",
	},
	{
		mimeType: "image/x-tiff",
		type: "image",
		extension: "tiff",
	},
	{
		mimeType: "application/msword",
		type: "word",
		extension: "doc",
	},
	{
		mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		type: "word",
		extension: "doc",
	},
	{
		mimeType: "application/excel",
		type: "excel",
		extension: "xls",
	},
	{
		mimeType: "application/vnd.ms-excel",
		type: "excel",
		extension: "xls",
	},
	{
		mimeType: "application/x-excel",
		type: "excel",
		extension: "xls",
	},
	{
		mimeType: "application/x-msexcel",
		type: "excel",
		extension: "xls",
	},
	{
		mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		type: "excel",
		extension: "xls",
	},
	{
		mimeType: "text/plain",
		type: "text",
		extension: "txt",
	},
	{
		mimeType: "application/pdf",
		type: "pdf",
		extension: "pdf",
	},
	{
		mimeType: "message/rfc822",
		type: "",
		extension: "eml",
	},
	{
		mimeType: "",
		type: "",
		extension: "msg",
	},
];

const SUPPORTED_FILES_STRING = SUPPORTED_FILES_INFO
	.map((fileInfo) => {
		const {
			mimeType,
			extension,
		} = fileInfo;

		if (!isEmpty(mimeType)) {
			return mimeType;
		}

		return `.${extension}`;
	})
	.join(", ");

export {
	SUPPORTED_FILES_INFO,
	SUPPORTED_FILES_STRING,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as RemoveAttachmentIcon,
} from "@epam/assets/icons/common/navigation-close-18.svg";
import {
	FlexRow,
	IconButton,
	LinkButton,
	Text,
	Tooltip,
} from "@epam/loveship";
import {
	type FC,
} from "react";

import {
	OvertimeRequestAttachmentStatus,
} from "models/overtime/constants";
import {
	type OvertimeRequestAttachment,
	type OvertimeRequestAttachmentId,
} from "models/overtime/types";

import {
	SecondaryInfoForStatus,
} from "./components/secondary-info-for-status/secondary-info-for-status";
import {
	getAttachmentInfo,
} from "./utils/get-attachment-info/get-attachment-info";
import {
	getStatusTooltipText,
} from "./utils/get-status-tooltip-text/get-status-tooltip-text";

import styles from "./attachment.module.css";

interface AttachmentProps {
	attachment: OvertimeRequestAttachment;
	canRemoveAttachment: boolean;
	removeAttachment: (attachmentId: OvertimeRequestAttachmentId) => void;
	downloadAttachment: (attachmentId: OvertimeRequestAttachmentId) => void;
}

const Attachment: FC<AttachmentProps> = ({
	attachment,
	canRemoveAttachment,
	removeAttachment,
	downloadAttachment,
}) => {
	const {
		attachmentUid,
		fileName,
		contentType,
		status,
	} = attachment;

	const canDownloadAttachment = (
		status === OvertimeRequestAttachmentStatus.SANITIZED
		|| status === OvertimeRequestAttachmentStatus.METADEFENDER
	);

	const {
		icon,
		extension,
	} = getAttachmentInfo({
		mimeType: contentType,
		isIconDimmed: !canDownloadAttachment,
	});

	return (
		<Tooltip
			content={getStatusTooltipText(status)}
			placement="top"
			color="white"
		>
			<FlexRow
				spacing={null}
				cx={styles.container}
			>
				<LinkButton
					caption={(
						<FlexRow
							spacing={null}
							columnGap="6"
							cx={styles.content}
						>
							{icon}
							<div
								className={styles.textContainer}
							>
								<Text
									color={
										canDownloadAttachment
											? "night800"
											: "night600"
									}
									size="18"
									fontSize="14"
									lineHeight="18"
									cx={styles.fileName}
								>
									{fileName}
								</Text>
								<SecondaryInfoForStatus
									attachment={attachment}
									fileExtension={extension}
								/>
							</div>
						</FlexRow>
					)}
					onClick={() => {
						downloadAttachment(attachmentUid);
					}}
					isDisabled={!canDownloadAttachment}
					cx={styles.downloadButton}
				/>
				{
					canRemoveAttachment
						? (
							<IconButton
								icon={RemoveAttachmentIcon}
								color="night600"
								cx={styles.removeAttachmentButton}
								onClick={() => {
									removeAttachment(attachmentUid);
								}}
							/>
						)
						: null
				}
			</FlexRow>
		</Tooltip>
	);
};

export {
	Attachment,
};

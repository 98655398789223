/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import classNames from "classnames";
import isUndefined from "lodash/isUndefined";
import {
	type FC,
} from "react";

import {
	type Duration,
} from "models/dates-and-time/types";
import {
	formatDuration,
} from "models/dates-and-time/utils/format-duration";
import {
	type WithClassName,
} from "types/common";

import tableComponentsStyles from "../../../table-components.module.css";
import styles from "./date-cell-duration.module.css";

interface DateDataCellDurationProps extends WithClassName {
	duration: Duration | undefined;
	shouldFormatSign?: boolean;
}

const DateDataCellDuration: FC<DateDataCellDurationProps> = ({
	duration,
	shouldFormatSign,
	className,
}) => {
	if (
		isUndefined(duration)
		|| duration === 0
	) {
		return null;
	}

	return (
		<span
			className={
				classNames(
					tableComponentsStyles.text,
					styles.dataDateCellDuration,
					className,
				)
			}
		>
			{
				formatDuration(
					duration,
					{
						shouldFormatSign,
					},
				)
			}
		</span>
	);
};

export {
	DateDataCellDuration,
};

/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import sumBy from "lodash/sumBy";

import {
	type Duration,
} from "models/dates-and-time/types";

import {
	type EditablePresenceActivities,
} from "../../types";
import {
	getActivityDuration,
} from "../get-activity-duration/get-activity-duration";

const getActivitiesDuration = (
	activities: EditablePresenceActivities,
): Duration => {
	const activitiesDurations = activities.map(({
		startTime,
		endTime,
	}) => {
		return getActivityDuration({
			startTime,
			endTime,
		});
	});

	return sumBy(activitiesDurations);
};

export {
	getActivitiesDuration,
};
